import {
  BalanceEventType,
  IEthBalanceEvent,
  IEthSubscription,
} from "@tepui/eth-sdk";
import { bnAdd, bnSub } from "../../utils/bigNumberHelpers";
import { EthereumAction } from "../actions/ethereumActions";
import { PlanAction } from "../actions/planActions";
import { SubscriptionAction } from "../actions/subscriptionActions";
import { deleteMap, updateMap } from "./map";
import { Status, SubscriptionStateMap } from "./TepuiState";

const addBalance = (
  prev: IEthSubscription,
  amount: string
): IEthSubscription => {
  const balance = bnAdd(prev.balance!, amount);
  return { ...prev, balance };
};

const subBalance = (
  prev: IEthSubscription,
  amount: string
): IEthSubscription => {
  const balance = bnSub(prev.balance!, amount);
  return { ...prev, balance };
};

type EventHandler = (
  prev: IEthSubscription,
  amount: string
) => IEthSubscription;

const handler: { [type in BalanceEventType]: EventHandler } = {
  Allocate: addBalance,
  Consume: subBalance,
  Fund: addBalance,
  Withdraw: subBalance,
};

const processEvents = (
  state: SubscriptionStateMap,
  events: IEthBalanceEvent[]
): SubscriptionStateMap => {
  return events.reduce((state, x) => {
    const { contract: address, account, type, amount } = x;
    const initialMap = state[address];
    if (!initialMap) return state;
    const prev = initialMap[account]?.subscription;
    if (!prev) return state;
    const subscription = handler[type](prev, amount);
    const map = updateMap(initialMap, account, { subscription });
    return updateMap(state!, address, map);
  }, state);
};

const updateSubscriptionState = (
  state: SubscriptionStateMap,
  actionSubscription: IEthSubscription
) => {
  const { address, subscriber } = actionSubscription;
  const status: Status = "ready";
  const initialMap = state![address] ?? {};
  const prev = initialMap[subscriber]?.subscription;
  const balance = prev?.balance ?? actionSubscription.balance ?? "0";
  const subscription = { ...actionSubscription, balance };
  const map = updateMap(initialMap, subscriber, { subscription, status });
  return updateMap(state!, address, map);
};

const updateStatus = (
  state: SubscriptionStateMap,
  subscription: IEthSubscription,
  status: Status
) => {
  const { address, subscriber } = subscription;
  const initialMap = state![address];
  const prev = initialMap?.[subscriber];
  if (!prev) return state;
  const map = updateMap(initialMap, subscriber, { status });
  return updateMap(state, address, map);
};

const subscriptionStateMapReducer = (
  state: SubscriptionStateMap | null = null,
  action: SubscriptionAction | PlanAction | EthereumAction
): SubscriptionStateMap | null => {
  switch (action.type) {
    case "LOAD_SUBSCRIPTION_SUBMIT":
    case "LOAD_SUBSCRIPTION_ERROR": {
      const { address, subscriber } = action;
      const status: Status =
        action.type === "LOAD_SUBSCRIPTION_SUBMIT" ? "loading" : "failed";
      const initialMap = state?.[address!] ?? {};
      const map = updateMap(initialMap, subscriber!, { status });
      return updateMap(state ?? {}, address!, map);
    }
    case "LOAD_SUBSCRIPTION_SUCCESS": {
      const { subscription } = action;
      return updateSubscriptionState(state!, subscription!);
    }
    case "LOAD_SUBSCRIPTIONS_SUCCESS":
      const { subscriptions } = action;
      return subscriptions!.reduce(
        (prev, subscription) => updateSubscriptionState(prev, subscription),
        state ?? {}
      );
    case "SUBSCRIPTION_SUBMIT":
    case "SUBSCRIBE_ERROR":
    case "CONSUME_SUBSCRIPTION_ERROR":
    case "CANCEL_SUBSCRIPTION_ERROR": {
      const status =
        action.type === "SUBSCRIPTION_SUBMIT"
          ? "loading"
          : action.type === "SUBSCRIBE_ERROR"
          ? "failed"
          : "ready";
      return updateStatus(state ?? {}, action.subscription!, status);
    }
    case "SUBSCRIBE_SUCCESS": {
      const { events, subscription } = action;
      const result = updateSubscriptionState(state!, subscription!);
      return processEvents(result, events!);
    }
    case "CONSUME_SUBSCRIPTION_SUCCESS": {
      const { events, subscription } = action;
      const result = updateStatus(state!, subscription!, "ready");
      return processEvents(result, events!);
    }
    case "CANCEL_SUBSCRIPTION_SUCCESS": {
      const { address, subscriber } = action.subscription!;
      const initialMap = state![address] ?? {};
      const map = deleteMap(initialMap, subscriber);
      const result = deleteMap(state!, address);
      if (Object.keys(map).length === 0) return result;
      return updateMap(result, address, map);
    }
    case "WITHDRAW_PLAN_SUCCESS": {
      const { events } = action;
      return processEvents(state!, events!);
    }
    case "LOAD_ETHEREUM_SUCCESS": {
      return null;
    }
    default:
      return state;
  }
};

export default subscriptionStateMapReducer;
