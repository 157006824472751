import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { toFeature, IPlan } from "@tepui/core-sdk";
import { IEthSubscription } from "@tepui/eth-sdk";

const dateFormatter = Intl.DateTimeFormat("default", {
  day: "numeric",
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

interface IProps {
  subscription: IEthSubscription;
  plan: IPlan;
}

const SubscriptionDates = ({ subscription, plan }: IProps) => {
  const start = dateFormatter.format(subscription.startDate);
  const { feature } = toFeature(subscription) ?? {};
  const cycleFeature = plan.features.find((x) => x.code === feature);
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography variant="caption">Start</Typography>
      </Grid>
      <Grid item>
        <Typography style={{ color: "#7397d1" }}>{start}</Typography>
      </Grid>
      {cycleFeature && (
        <>
          <Grid item>
            <Typography variant="caption">Cycle</Typography>
          </Grid>
          <Grid item>
            <Typography style={{ color: "#f07365" }}>
              {cycleFeature.name}
            </Typography>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default SubscriptionDates;
