import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Portis from "@portis/web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import Fortmatic from "fortmatic";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import Web3Modal, { getProviderInfo, IProviderInfo } from "web3modal";
import * as ethereumActions from "../../redux/actions/ethereumActions";
import { IEthereum, TepuiState } from "../../redux/reducers/TepuiState";
import AddressBlockie from "./AddressBlockie";
import ProviderChip from "./ProviderChip";

const networks: { [network: number]: string } = {
  1: "Ethereum",
  3: "Ropsten",
  4: "Rinkeby",
  5: "Goerli",
  42: "Kovan",
};

interface IProps {
  session: CognitoUserSession | null;
  ethereum: IEthereum | null;
  ethereumActions: typeof ethereumActions;
}

const ProviderButton = ({ session, ethereum, ethereumActions }: IProps) => {
  const [networkId, setNetworkId] = useState<number | undefined>();
  const [providerInfo, setProviderInfo] = useState<IProviderInfo | undefined>();
  const { status, tepuiEthereumClient } = ethereum ?? {};

  useEffect(() => {
    if (!tepuiEthereumClient) return;
    const init = async () => {
      const networkId = await tepuiEthereumClient.getNetworkId();
      setNetworkId(networkId);
    };
    init();
  }, [tepuiEthereumClient]);

  if (!session) return null;

  const providerOptions = {
    fortmatic: {
      package: Fortmatic,
      options: { key: "pk_test_887C88605E7D0D0B" },
    },
    portis: {
      package: Portis,
      options: { id: "1ba2c8c2-d1fd-4b75-b431-7f426109d368" },
    },
    walletconnect: {
      package: WalletConnectProvider,
      options: { infuraId: "c8642b2d15184236aa94b850cea3f7b0" },
    },
  };

  const handleChainChanged = async (chainId: string) => {
    const networkId = Number(chainId);
    setNetworkId(networkId);
    ethereumActions.loadEthereum();
  };

  const handleAccountChanged = (accounts: string[]) => {
    const address = accounts[0];
    ethereumActions.changeAddress(address);
  };

  const web3Modal = new Web3Modal({
    network: "rinkeby",
    cacheProvider: true,
    providerOptions,
  });

  const handleClick = async () => {
    if (status && ["loading", "busy"].includes(status)) return;
    if (status) web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    provider.on?.("chainChanged", handleChainChanged);
    provider.on?.("accountsChanged", handleAccountChanged);
    const providerInfo = getProviderInfo(provider);
    setProviderInfo(providerInfo);
    ethereumActions.loadEthereum(provider);
  };

  const network = networkId
    ? networks[networkId] ?? `Unknown: ${networkId}`
    : status ?? "disconnected";

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item style={{ height: 15 }}></Grid>
      <Grid item xs={12}>
        {status ? (
          <ProviderChip
            avatarLeft={<Avatar src={providerInfo?.logo} />}
            avatarRight={<AddressBlockie />}
            clickable
            onClick={handleClick}
            label={providerInfo?.name}
            color="secondary"
            size="small"
          />
        ) : (
          <Chip
            label="Connect Wallet"
            color="secondary"
            clickable
            onClick={handleClick}
            size="small"
          />
        )}
      </Grid>
      <Grid item>
        <Typography
          style={{ fontSize: 10, fontWeight: 600, textTransform: "capitalize" }}
        >
          {network}
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
  ethereum: state.ethereum,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ethereumActions: bindActionCreators(ethereumActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderButton);
