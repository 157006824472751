import { PlanAction } from "../actions/planActions";
import { EthereumAction } from "../actions/ethereumActions";

const availablePlansReducer = (
  state: string[] | null = null,
  action: PlanAction | EthereumAction
): string[] | null => {
  switch (action.type) {
    case "LOAD_AVAILABLEPLANS_SUCCESS":
      return Object.keys(action.planStateMap!);
    case "SAVE_PLAN_SUCCESS":
      const { id } = action.plan!;
      return state ? [...state, id!] : state;
    case "LOAD_ETHEREUM_SUCCESS": {
      return null;
    }
    default:
      return state;
  }
};

export default availablePlansReducer;
