import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import React from "react";
import { connect } from "react-redux";
import { TepuiState } from "../redux/reducers/TepuiState";
import AddressChip from "./common/AddressChip";
import PageTitle from "./common/PageTitle";

interface IProps {
  session: CognitoUserSession | null;
}

const ProfilePage = ({ session }: IProps) => {
  if (!session) return null;
  const profile = session.getIdToken().payload;
  const refreshToken = session.getRefreshToken().getToken();
  const accessToken = session.getAccessToken().getJwtToken();

  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item>
          <PageTitle title="Profile" />
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography>Name: {profile.name}</Typography>
        </Grid>
        <Grid item>
          <Typography>Email: {profile.email}</Typography>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography>Refresh Token:</Typography>
          </Grid>
          <Grid item>
            <AddressChip address={refreshToken} />
          </Grid>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item>
            <Typography>Access Token:</Typography>
          </Grid>
          <Grid item>
            <AddressChip address={accessToken} />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
});

export default connect(mapStateToProps)(ProfilePage);
