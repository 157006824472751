import React from "react";
import ReactDOM from "react-dom";
import { ConnectedRouter as Router } from "connected-react-router";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import configureStore, { history } from "./redux/configureStore";
import { configureAuth } from "./redux/actions/sessionActions";
import { Provider as ReduxProvider } from "react-redux";

const store = configureStore();
store.dispatch(configureAuth);

const { ethereum } = window;
if (ethereum?.autoRefreshOnNetworkChange)
  ethereum.autoRefreshOnNetworkChange = false;

ReactDOM.render(
  <ReduxProvider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </ReduxProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
