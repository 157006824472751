import React from "react";
import { ResponsiveLine, Serie } from "@nivo/line";
import { LegendProps } from "@nivo/legends";

const legendProps: LegendProps = {
  anchor: "bottom-right",
  direction: "column",
  effects: [
    {
      on: "hover",
      style: {
        itemBackground: "rgba(0, 0, 0, .03)",
        itemOpacity: 1,
      },
    },
  ],
  itemDirection: "left-to-right",
  itemHeight: 20,
  itemOpacity: 0.75,
  itemsSpacing: 0,
  itemWidth: 80,
  justify: false,
  symbolBorderColor: "rgba(0, 0, 0, .5)",
  symbolShape: "circle",
  symbolSize: 12,
  translateX: 100,
  translateY: 0,
};

interface ILegend {
  x: string;
  y: string;
}

interface IProps {
  data: Serie[];
  legend: ILegend;
}

const LineChart = ({ data, legend }: IProps) => (
  <ResponsiveLine
    data={data}
    margin={{
      top: 50,
      right: data.length > 1 ? 90 : 20,
      bottom: 50,
      left: 60,
    }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: "auto",
      max: "auto",
      stacked: true,
      reverse: false,
    }}
    curve="cardinal"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legend.x,
      legendOffset: 36,
      legendPosition: "middle",
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legend.y,
      legendOffset: -40,
      legendPosition: "middle",
    }}
    colors={{ scheme: "nivo" }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor={{ from: "serieColor" }}
    pointLabel="y"
    pointLabelYOffset={-12}
    useMesh={true}
    legends={data.length > 1 ? [legendProps] : []}
  />
);

export default LineChart;
