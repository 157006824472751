import { Auth } from "aws-amplify";
import { TepuiAction, TepuiThunk, ConfigActionType } from "./actionTypes";
import { IConfig } from "../reducers/TepuiState";
import { Dispatch } from "redux";

interface ConfigAction extends TepuiAction {
  type: ConfigActionType;
  config?: IConfig;
}

const loadConfigSuccess = (config: IConfig): ConfigAction => ({
  type: "LOAD_CONFIG_SUCCESS",
  config,
});

const loadConfigWarning = (message: string): ConfigAction => ({
  type: "LOAD_CONFIG_WARNING",
  message,
});

const loadConfigError = (message: string): ConfigAction => ({
  type: "LOAD_CONFIG_ERROR",
  message,
});

const fetchConfig = async (
  tokens: string[],
  authToken: string,
  dispatch: Dispatch<ConfigAction>
): Promise<IConfig> => {
  const defaultConfig = { currency: "USD" };
  const tokenString = tokens.join(",");
  const response = await fetch(
    `https://api.tepui.io/config?tokens=${tokenString}`,
    { headers: { Authorization: authToken } }
  );
  if (response.ok) return await response.json();
  const message = `Unable to fetch config: ${response.status} - ${response.statusText}`;
  dispatch(loadConfigWarning(message));
  return Promise.resolve(defaultConfig);
};

const loadConfig = (): TepuiThunk => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const session = await Auth.currentSession()
        .then((x) => x)
        .catch(() => {});
      if (!session) return;
      const accessToken = session.getAccessToken();
      const authToken = accessToken.getJwtToken();
      const tokenMap = state.ethereum?.tokenMap;
      if (!tokenMap) return;
      const tokens = Object.values(tokenMap).map((x) => x.symbol);
      const config = await fetchConfig(tokens, authToken, dispatch);
      dispatch(loadConfigSuccess(config));
    } catch (error) {
      const message = `Config Initialization Error: ${error}`;
      dispatch(loadConfigError(message));
    }
  };
};

export { loadConfig };
export type { ConfigAction };
