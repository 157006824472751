import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { IEthPlan } from "@tepui/eth-sdk";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as planActions from "../../redux/actions/planActions";
import * as routerActions from "../../redux/actions/routerActions";
import {
  ContractStateMap,
  IEthereum,
  PlanStateMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { bnAdd } from "../../utils/bigNumberHelpers";
import PageTitle from "../common/PageTitle";
import SummarySkeleton from "../common/SummarySkeleton";
import PlanSummary, { IDisplayPeriod } from "./PlanSummary";
import TokenTotal from "./TokenTotal";

const days = (value: number) => value * 60 * 60 * 24;

const getPeriod = (): IDisplayPeriod => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const periodEnd = today.getSeconds();
  const periodStart = periodEnd - days(7);
  const display = (timestamp: number) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleString("default", { weekday: "short" });
  };
  const weekDays = [...Array(7).keys()].map((x) =>
    display(periodStart + days(x))
  );
  const seedData = weekDays.map((x) => ({ x, y: 0 }));
  return { periodStart, periodEnd, seedData, display };
};

const sumByToken = (
  contracts: IEthPlan[] | undefined,
  fn: (contract: IEthPlan) => string
) => {
  if (!contracts) return {};
  const initial: { [token: string]: string } = {};
  return contracts.reduce((map, x) => {
    if (!map[x.token]) map[x.token] = "0";
    const value = fn(x);
    map[x.token] = bnAdd(map[x.token], value);
    return map;
  }, initial);
};

interface IProps {
  ethereum: IEthereum | null;
  ownedPlans: string[] | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  planActions: typeof planActions;
  routerActions: typeof routerActions;
}

const MerchantPage = ({
  ethereum,
  ownedPlans,
  planStateMap,
  contractStateMap,
  planActions,
  routerActions,
}: IProps) => {
  useEffect(() => {
    if (ownedPlans) return;
    const { status } = ethereum ?? {};
    if (status !== "ready") return;
    const init = async () => {
      planActions.loadOwnedPlans();
    };
    init();
  }, [ethereum, ownedPlans, planActions]);

  if (ethereum?.status !== "ready")
    return <h2>Connect to Ethereum to retrieve data</h2>;

  const period = getPeriod();
  const planStates = planStateMap
    ? ownedPlans?.map((x) => planStateMap[x])
    : undefined;
  const addresses = planStates?.flatMap((x) => x.addresses);
  const contracts = contractStateMap
    ? addresses?.reduce((prev, x) => {
        const { contract } = contractStateMap[x] ?? {};
        if (contract) prev.push(contract);
        return prev;
      }, [] as IEthPlan[])
    : undefined;
  const totalConsumed = sumByToken(contracts, (x) => x.consumed);
  const totalBalance = sumByToken(contracts, (x) => x.balance);
  return (
    <Container maxWidth="xl" component="main">
      <PageTitle title="Merchant Dashboard" />
      <Grid container direction="row" justify="space-evenly" spacing={1}>
        <Grid item>
          <TokenTotal header="Consumed" tokenTotals={totalConsumed} />
        </Grid>
        <Grid item>
          <TokenTotal header="Balance" tokenTotals={totalBalance} />
        </Grid>
      </Grid>
      <Box my={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => routerActions.pushRoute("/merchant/0/edit")}
        >
          Add Plan
        </Button>
        {/*<Button
          variant="contained"
          color="secondary"
          onClick={toastActions.testToast}
        >
          Test Toast
        </Button>*/}
      </Box>
      {!ownedPlans && (
        <Grid container spacing={3}>
          {Array.from(new Array(6)).map((_, i) => (
            <Grid item key={i}>
              <SummarySkeleton />
            </Grid>
          ))}
        </Grid>
      )}
      {ownedPlans && ownedPlans.length === 0 && <h2>No plans found.</h2>}
      {ownedPlans && ownedPlans.length > 0 && (
        <Grid container spacing={1} justify="space-evenly">
          {ownedPlans &&
            ownedPlans.map((x) => (
              <Grid key={x} item sm={12} md={6}>
                <Button
                  style={{
                    display: "block",
                    width: "100%",
                    textAlign: "inherit",
                  }}
                  onClick={() => routerActions.pushRoute(`/merchant/${x}`)}
                >
                  <PlanSummary planState={planStateMap?.[x]!} period={period} />
                </Button>
              </Grid>
            ))}
        </Grid>
      )}
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
  ethereum: state.ethereum,
  ownedPlans: state.ownedPlans,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  planActions: bindActionCreators(planActions, dispatch),
  routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MerchantPage);
