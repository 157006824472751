import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as planActions from "../../redux/actions/planActions";
import * as routerActions from "../../redux/actions/routerActions";
import { IPlanState } from "../../redux/reducers/TepuiState";

interface IProps {
  planState?: IPlanState;
  routerActions: typeof routerActions;
  planActions: typeof planActions;
}

const PlanActions = ({ planState, planActions, routerActions }: IProps) => {
  const { plan, status } = planState ?? {};
  const color =
    status === "ready" ? undefined : status === "failed" ? "error" : "disabled";
  return (
    <>
      <Tooltip title="Edit Plan">
        <span>
          <IconButton
            disabled={status !== "ready"}
            onClick={() =>
              routerActions.pushRoute(`/merchant/${plan!.id}/edit`)
            }
          >
            <CreateIcon color={color ?? "secondary"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Deploy Contract">
        <span>
          <IconButton
            disabled={status !== "ready"}
            onClick={() => planActions.deployPlanContract(plan!)}
          >
            <PlaylistAddIcon color={color ?? "primary"} />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  routerActions: bindActionCreators(routerActions, dispatch),
  planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanActions);
