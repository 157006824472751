import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const TableSkeleton = () => (
  <Box
    width="inherit"
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    {Array.from(new Array(6)).map((_, i) => (
      <Skeleton key={i} width="90%" />
    ))}
  </Box>
);

export default TableSkeleton;
