import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select, { SelectProps } from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import InfoIcon from "@material-ui/icons/Info";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  cycleFeatures,
  fromFeature,
  IFeature,
  IPlan,
  toFeature,
} from "@tepui/core-sdk";
import TepuiEthereumClient, { IEthSubscription, IToken } from "@tepui/eth-sdk";
import Markdown from "markdown-to-jsx";
import React, { MouseEventHandler, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as planActions from "../../redux/actions/planActions";
import * as subscriptionActions from "../../redux/actions/subscriptionActions";
import {
  ContractStateMap,
  IContractState,
  IEthereum,
  IPlanState,
  IRegistry,
  ISubscriptionState,
  PlanStateMap,
  ProfileStateMap,
  Status,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { toBN } from "../../utils/bigNumberHelpers";
import { displayAmount } from "../../utils/token";
import AddressChip from "./AddressChip";
import GridAmount from "./GridAmount";

const getCycleFeatures = (planFeatures?: IFeature[]) => {
  if (!planFeatures) return [];
  return planFeatures.filter((x) => cycleFeatures.includes(x.code));
};

const defaultCycle = (plan: IPlan) => {
  const noneCycle = "";
  const cycles = getCycleFeatures(plan.features);
  return cycles.length > 0 ? cycles[0].code : noneCycle;
};

const calcCyclePrice = async (
  tepuiEthereumClient: TepuiEthereumClient,
  address: string,
  feature: string,
  addOns: string[]
) => {
  if (feature === "") return "";
  const priceFeature = (address: string) =>
    tepuiEthereumClient.priceFeature(address, feature, "1", "0");
  const tierAmounts = await addOns.reduce(async (prevPromise, x) => {
    const addOnTierAmounts = await priceFeature(x);
    const prev = await prevPromise;
    return prev.concat(addOnTierAmounts);
  }, priceFeature(address));
  return tierAmounts
    .reduce((prev, { amount }) => prev.add(toBN(amount)), toBN("0"))
    .toString();
};

type State = { status: Status };
const getText = (state?: State, text?: string): string => {
  const status = state?.status ?? "loading";
  switch (status) {
    case "busy":
    case "ready":
      return text ?? "<Unknown>";
    case "loading":
      return "Loading...";
    case "failed":
      return "<Failed>";
  }
};

interface IProps {
  show: boolean;
  onHide: () => void;
  planState?: IPlanState;
  contractState?: IContractState;
  subscriptionState?: ISubscriptionState;
  address?: string;
  registry: IRegistry | null;
  ethereum: IEthereum | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  profileStateMap: ProfileStateMap | null;
  planActions: typeof planActions;
  subscriptionActions: typeof subscriptionActions;
}

const FundModal = (props: IProps) => {
  const { show, onHide, planState, contractState, subscriptionState } = props;
  const { registry, ethereum } = props;
  const { planStateMap, contractStateMap, profileStateMap } = props;
  const { planActions, subscriptionActions } = props;
  const [autoRenew, setAutoRenew] = useState(false);
  const [featureCycle, setFeatureCycle] = useState("");
  const [addOns, setAddOns] = useState([] as string[]);
  const [balance, setBalance] = useState("");
  const [cyclePrice, setCyclePrice] = useState("");
  const [error, setError] = useState("");
  const defaultToken: IToken = { name: "???", symbol: "???", decimals: 6 };
  const { tepuiRegistry } = registry ?? {};
  const { tepuiEthereumClient, tokenMap } = ethereum ?? {};
  const { plan } = planState ?? {};
  const { contract } = contractState ?? {};
  const { subscription } = subscriptionState ?? {};
  const address = contract?.address ?? props.address;
  const { ownerId, imageUrl } = plan ?? {};
  const profileState = ownerId ? profileStateMap?.[ownerId] : undefined;
  const { profile } = profileState ?? {};
  const title = getText(planState, plan?.name);
  const subTitle = getText(profileState, profile?.name);
  const { picture } = profile ?? {};
  const token = (contract && tokenMap?.[contract.token]) ?? defaultToken;
  const subscriber =
    subscription?.subscriber ?? tepuiEthereumClient?.defaultAccount!;
  const addOnPlans = contract?.connectedPlans
    .filter((x) => x.connectionType === "addon")
    .map((x) => x.plan);

  useEffect(() => {
    if (!contract || !plan || !tepuiEthereumClient || !tepuiRegistry) return;
    const tokenAddress = contract.token;
    const autoRenew = subscription?.autoRenew ?? true;
    const cycle = subscription
      ? toFeature(subscription)?.feature || ""
      : defaultCycle(plan);
    setAutoRenew(autoRenew);
    setFeatureCycle(cycle);
    if (subscription) setAddOns([...subscription.addOns]);
    let mounted = true;
    const init = async () => {
      const balance = await tepuiEthereumClient.getBalance(
        tokenAddress,
        subscriber
      );
      if (!mounted) return;
      setBalance(balance);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [
    token,
    contract,
    subscriber,
    plan,
    subscription,
    tepuiRegistry,
    tepuiEthereumClient,
  ]);

  useEffect(() => {
    if (!addOnPlans || !contractStateMap) return;
    for (const address of addOnPlans) {
      const contractState = contractStateMap[address];
      if (contractState) continue;
      planActions.loadContract(address);
    }
  }, [addOnPlans, contractStateMap, planActions]);

  useEffect(() => {
    if (!contract || !tepuiEthereumClient) return;
    let mounted = true;
    const init = async () => {
      const cyclePrice = await calcCyclePrice(
        tepuiEthereumClient,
        contract.address!,
        featureCycle,
        addOns
      );
      if (!mounted) return;
      setCyclePrice(cyclePrice);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [contract, tepuiEthereumClient, featureCycle, addOns]);

  useEffect(() => {
    if (!tepuiEthereumClient) return;
    if (!balance) return setError("");
    if (!contract) return setError("");
    if (subscription) return setError("");
    if (!cyclePrice) return setError("");
    const amount = Number(cyclePrice);
    if (amount <= Number(balance)) return setError("");
    const displayBalance = tepuiEthereumClient.toDisplayAmount(balance, token);
    return setError(
      `Amount exceeds your balance: ${displayAmount(displayBalance, token)}`
    );
  }, [token, balance, cyclePrice, contract, subscription, tepuiEthereumClient]);

  const handleCycleChange: SelectProps["onChange"] = async (event) => {
    const { value } = event.target as { value: string };
    setFeatureCycle(value);
  };

  const handleAddOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    if (checked) {
      setAddOns((x) => [...x, name]);
      return;
    }
    setAddOns((x) => x.filter((y) => y !== name));
  };

  const handleSave: MouseEventHandler = async () => {
    const amount = cyclePrice;
    const oldSubscription = subscription ?? {
      subscriber: tepuiEthereumClient!.defaultAccount!,
      address: contract!.address!,
      autoRenew: true,
      startDate: new Date(),
      balance: amount,
    };
    const newSubscription: IEthSubscription = {
      ...oldSubscription,
      ...fromFeature(featureCycle),
      addOns,
      autoRenew,
    };
    subscriptionActions.subscribe(newSubscription, amount);
    onHide();
  };

  const cycleFeatures = getCycleFeatures(plan?.features);
  return (
    <Dialog open={show} onClose={onHide} scroll="body">
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <div style={{ width: 40, height: 40 }} />
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="center"
            style={{ width: "65%" }}
          >
            <Grid item container spacing={1}>
              <Grid item>
                <div style={{ width: 32, height: 32 }} />
              </Grid>
              <Grid item>
                <Typography component="span" variant="h6" align="center">
                  {title}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  interactive
                  title={<Markdown>{plan?.description ?? ""}</Markdown>}
                >
                  <InfoIcon color="primary" fontSize="small" />
                </Tooltip>
              </Grid>
            </Grid>
            <Typography component="span" variant="body1" align="center">
              {subTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar src={picture} />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <Grid container direction="column" alignItems="center" spacing={5}>
          <Grid item>
            {planState &&
            ["ready", "busy", "failed"].includes(planState.status) ? (
              <div
                style={{
                  backgroundImage: `url("${imageUrl}")`,
                  backgroundSize: "cover",
                  backgroundPosition: "50%",
                  width: 250,
                  height: 140,
                }}
              ></div>
            ) : (
              <Skeleton variant="rect" width={250} height={140} />
            )}
          </Grid>
          <Grid item>
            <AddressChip address={address} actionable />
          </Grid>
          {cycleFeatures.length > 0 && (
            <Grid item container direction="column" alignItems="center">
              <Grid item>
                <Switch
                  checked={autoRenew}
                  onChange={() => setAutoRenew((x) => !x)}
                  name="autoRenew"
                  value={autoRenew}
                />
                <Select
                  labelId="cycle-label"
                  name="cycle"
                  value={featureCycle}
                  onChange={handleCycleChange}
                >
                  {cycleFeatures.map((x) => (
                    <MenuItem key={x.code} value={x.code}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <FormHelperText>
                  {autoRenew
                    ? "This plan includes recurring charges and renews automatically"
                    : "This plan includes a one-time charge and expires after one period"}
                </FormHelperText>
              </Grid>
            </Grid>
          )}
          {addOnPlans &&
            addOnPlans.map((address) => {
              const contractState = contractStateMap?.[address];
              const { contract } = contractState ?? {};
              const { id } = contract ?? {};
              const planState = id ? planStateMap?.[id] : undefined;
              const { plan } = planState ?? {};
              return (
                <Grid
                  key={address}
                  container
                  item
                  justify="center"
                  alignItems="center"
                  style={{
                    padding: 5,
                  }}
                >
                  <Grid item>
                    {plan ? (
                      <Typography variant="body2">{plan.name}</Typography>
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </Grid>
                  {plan?.description && (
                    <Grid item>
                      <Tooltip
                        interactive
                        title={<Markdown>{plan?.description ?? ""}</Markdown>}
                      >
                        <InfoIcon color="primary" fontSize="small" />
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item>
                    <Switch
                      checked={addOns.includes(address)}
                      onChange={handleAddOnChange}
                      name={address}
                      value={addOns.includes(address)}
                    />
                  </Grid>
                </Grid>
              );
            })}
          <Grid item>
            <GridAmount
              header="Total"
              tokenAmount={cyclePrice}
              tokenAddress={contract?.token}
            />
            <Typography color="error">{error}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onHide} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={Boolean(error)}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
  ethereum: state.ethereum,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
  profileStateMap: state.profileStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  planActions: bindActionCreators(planActions, dispatch),
  subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundModal);
