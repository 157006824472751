import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const SimpleLineChartSkeleton = () => (
  <Box
    width={270}
    height={50}
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Skeleton width="100%" />
    <Skeleton width="100%" />
  </Box>
);

export default SimpleLineChartSkeleton;
