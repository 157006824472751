import React from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { TokenMetadataMap } from "eth-contract-metadata";

interface IProps {
  token?: string;
  tokenMap?: TokenMetadataMap;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const SelectToken = ({ token, tokenMap, disabled, onChange }: IProps) => (
  <Select
    name="token"
    value={token || ""}
    onChange={onChange}
    disabled={disabled}
  >
    {tokenMap &&
      Object.values(tokenMap).map((x) => (
        <MenuItem key={x.symbol} value={x.symbol}>
          {x.logo && (
            <ListItemIcon>
              <img
                alt={x.symbol}
                style={{ width: 20, height: 20 }}
                src={x.logo}
              />
            </ListItemIcon>
          )}
          {x.name}
        </MenuItem>
      ))}
  </Select>
);

export default SelectToken;
