import web3Utils from "web3-utils";

const bnFn = (fn: "add" | "sub") => (
  a: string | number,
  b: string | number
) => {
  const bnA = web3Utils.toBN(a);
  const bnB = web3Utils.toBN(b);
  const bnResult = bnA[fn](bnB);
  return bnResult.toString();
};

const bnAdd = (a: string | number, b: string | number) => {
  return bnFn("add")(a, b);
};

const bnSub = (a: string | number, b: string | number) => {
  return bnFn("sub")(a, b);
};

const toBN = web3Utils.toBN;

export { toBN, bnAdd, bnSub };
