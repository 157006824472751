import React from "react";
import { connect } from "react-redux";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { TepuiState } from "../../redux/reducers/TepuiState";
import { IEthereum, IConfig } from "../../redux/reducers/TepuiState";

const displayCurrency = (amount: number, currency: string) => {
  const formatter = new Intl.NumberFormat("default", {
    style: "currency",
    currency,
  });
  return formatter.format(amount);
};

interface IProps extends TypographyProps {
  tokenAmount: string;
  tokenSymbol?: string;
  tokenAddress?: string;
  ethereum: IEthereum | null;
  config: IConfig | null;
}

const FiatAmount = ({
  variant,
  style,
  tokenAmount,
  tokenSymbol,
  tokenAddress,
  ethereum,
  config,
}: IProps) => {
  const { tepuiEthereumClient, tokenMap } = ethereum ?? {};
  if (!tokenMap || !tepuiEthereumClient) return null;
  const { currency, exchangeRates } = config ?? {};
  if (!currency || !exchangeRates) return null;
  const token = tokenAddress
    ? tokenMap[tokenAddress]
    : Object.values(tokenMap).find((x) => x.symbol === tokenSymbol);
  if (!token) return null;
  const amount = tepuiEthereumClient.toDisplayAmount(tokenAmount, token);
  const fiatAmount = Number(amount) / exchangeRates[token.symbol];
  return (
    <Typography variant={variant} style={style}>
      {displayCurrency(fiatAmount, currency)}
    </Typography>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  config: state.config,
});

export default connect(mapStateToProps)(FiatAmount);
