import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ConnectedPlanAccordion from "./ConnectedPlanAccordion";
import { IPlan } from "@tepui/core-sdk";
import { IErrors } from "./planEditTypes";

interface IProps {
  plan: IPlan;
  errors: IErrors;
  setError: (name: string, errors: boolean[]) => void;
  setStateAndValidate: <T extends any>(name: keyof IPlan, value: T) => void;
}

const ConnectedPlanSection = ({
  plan,
  errors,
  setError,
  setStateAndValidate,
}: IProps) => {
  const handleAddOnAdd = () => {
    const connectedPlanErrors = [...errors.connectedPlans, false];
    setError("connectedPlans", connectedPlanErrors);
    const connectedPlans = [
      ...plan.connectedPlans,
      { connectionType: "addon", plan: "" },
    ];
    setStateAndValidate("connectedPlans", connectedPlans);
  };

  return (
    <Grid container direction="column">
      <Grid container spacing={1}>
        {plan.connectedPlans.map((connectedPlan, index) => (
          <Grid item key={index} sm={6} md={3}>
            <ConnectedPlanAccordion
              connectedPlan={connectedPlan}
              index={index}
              plan={plan}
              errors={errors}
              setError={setError}
              setStateAndValidate={setStateAndValidate}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Button color="primary" onClick={handleAddOnAdd}>
          Add Connected Plan
        </Button>
      </Grid>
    </Grid>
  );
};

export default ConnectedPlanSection;
