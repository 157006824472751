import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CheckIcon from "@material-ui/icons/Check";
import { IPlan, IProfile, toFeature } from "@tepui/core-sdk";
import { IEthSubscription, IToken } from "@tepui/eth-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ContractStateMap,
  IConfig,
  IEthereum,
  IPlanState,
  IRegistry,
  ISubscriptionState,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import * as tokenUtils from "../../utils/token";
import AddressBlockie, {
  BlockieMap,
  blockieMapFromArray,
} from "../common/AddressBlockie";
import AddressChip from "../common/AddressChip";
import SubscriptionActions from "../common/SubscriptionActions";
import TableSkeleton from "../common/TableSkeleton";

const dateFormatter = Intl.DateTimeFormat("default", {
  day: "numeric",
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

const displayDate = (date: Date | undefined) => {
  if (!date) return "";
  return dateFormatter.format(date);
};

const displaySubscriber = (profile?: IProfile) => {
  if (!profile) return "[Unknown]";
  return profile.name;
};

const displayCycle = (subscription: IEthSubscription, plan: IPlan) => {
  const { feature } = toFeature(subscription) ?? {};
  const cycleFeature = plan.features.find((x) => x.code === feature);
  return cycleFeature?.name;
};

const flatMapResolve = async <T extends unknown>(
  addresses: string[],
  f: (address: string) => Promise<T[]>
) => {
  const promises = addresses.map(f);
  const events = await Promise.all(promises);
  return events.flat();
};

interface IProps {
  planState: IPlanState | undefined;
  maxHeight?: number;
  registry: IRegistry | null;
  ethereum: IEthereum | null;
  config: IConfig | null;
  contractStateMap: ContractStateMap | null;
}

type SubscriberMap = { [address: string]: IProfile };

const SubscriptionTable = (props: IProps) => {
  const [subscriptions, setSubscriptions] = useState(
    undefined as IEthSubscription[] | undefined
  );
  const [subscriberMap, setSubscriberMap] = useState(
    undefined as SubscriberMap | undefined
  );
  const [blockieMap, setBlockieMap] = useState(
    undefined as BlockieMap | undefined
  );
  const { tepuiRegistry } = props.registry ?? {};
  const { tepuiEthereumClient, tokenMap } = props.ethereum ?? {};
  const { contractStateMap, planState } = props;
  const { plan, addresses } = planState ?? {};

  useEffect(() => {
    if (!tepuiRegistry) return;
    if (!tepuiEthereumClient) return;
    if (!plan || !addresses) return;
    const { id } = plan;
    let mounted = true;

    const init = async () => {
      const getSubscriptions = (x: string) =>
        tepuiEthereumClient.getSubscriptions(x);
      const subscriptions = await flatMapResolve(addresses, getSubscriptions);
      const blockieAddresses = subscriptions
        .map((x) => x.subscriber)
        .concat(addresses);
      const blockieMap = blockieMapFromArray(blockieAddresses);
      const initialSubscriberMap: SubscriberMap = {};
      const subscriberMap = await subscriptions.reduce(async (prev, x) => {
        const { subscriber } = x;
        const map = await prev;
        if (map[subscriber]) return map;
        const subscription = await tepuiRegistry.retrieveSubscription(
          id!,
          subscriber
        );
        if (!subscription) return prev;
        const { profile } = subscription;
        map[subscriber] = profile!;
        return map;
      }, Promise.resolve(initialSubscriberMap));
      if (!mounted) return;
      setSubscriptions(subscriptions);
      setBlockieMap(blockieMap);
      setSubscriberMap(subscriberMap);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [tepuiRegistry, tepuiEthereumClient, plan, addresses, contractStateMap]);

  if (!plan?.id || !tepuiEthereumClient || !tokenMap || !subscriptions)
    return <TableSkeleton />;
  const token: IToken = Object.values(tokenMap).find(
    (x) => x.symbol === plan.token
  ) ?? { name: "???", symbol: "???", decimals: 6 };

  return (
    <TableContainer style={{ maxHeight: props.maxHeight }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Subscription Start</TableCell>
            <TableCell align="center">Contract</TableCell>
            <TableCell>Subscriber</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell>Cycle</TableCell>
            <TableCell align="center">Auto Renew</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((x) => {
            const contractState = contractStateMap?.[x.address];
            const subscriptionState: ISubscriptionState = {
              subscription: x,
              status: "ready",
            };
            return (
              <TableRow key={x.address + "|" + x.subscriber}>
                <TableCell>
                  {contractState && (
                    <SubscriptionActions
                      subscriptionState={subscriptionState}
                      contractState={contractState}
                      planState={planState}
                      allowCancel={true}
                    />
                  )}
                </TableCell>
                <TableCell>{displayDate(x.startDate)}</TableCell>
                <TableCell align="center">
                  <AddressChip address={x.address} actionable />
                </TableCell>
                <TableCell>
                  {displaySubscriber(subscriberMap?.[x.subscriber])}
                </TableCell>
                <TableCell align="center">
                  <AddressBlockie
                    accountAddress={x.subscriber}
                    blockieMap={blockieMap}
                  />
                </TableCell>
                <TableCell>{displayCycle(x, plan)}</TableCell>
                <TableCell align="center">
                  {x.autoRenew && <CheckIcon />}
                </TableCell>
                <TableCell align="right">
                  {tokenUtils.displayToken(
                    x.balance!,
                    token,
                    tepuiEthereumClient
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
  ethereum: state.ethereum,
  config: state.config,
  contractStateMap: state.contractStateMap,
});

export default connect(mapStateToProps)(SubscriptionTable);
