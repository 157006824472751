import { IProfile } from "@tepui/core-sdk";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PlanStateMap, TepuiState } from "../reducers/TepuiState";
import { ProfileActionType, TepuiAction } from "./actionTypes";
import { isLoaded } from "./planActions";

interface ProfileAction extends TepuiAction {
  type: ProfileActionType;
  sub: string;
  profile?: IProfile;
}

const loadProfileSubmit = (sub: string): ProfileAction => ({
  type: "LOAD_PROFILE_SUBMIT",
  sub,
});

const loadProfileSuccess = (sub: string, profile: IProfile): ProfileAction => ({
  type: "LOAD_PROFILE_SUCCESS",
  sub,
  profile,
});

const loadProfileError = (sub: string, message: string): ProfileAction => ({
  type: "LOAD_PROFILE_ERROR",
  sub,
  message,
});

const dispatchProfile = async (
  state: TepuiState,
  dispatch: ThunkDispatch<TepuiState, unknown, AnyAction>,
  sub: string
) => {
  const { registry, profileStateMap } = state;
  if (isLoaded(profileStateMap, sub)) return;
  const { tepuiRegistry } = registry!;
  dispatch(loadProfileSubmit(sub));
  let profile: IProfile | undefined;
  try {
    profile = await tepuiRegistry.retrieveProfile(sub);
  } catch (error) {
    const message = `Loading profile ${sub} failed: ${error.message}`;
    dispatch(loadProfileError(sub, message));
    return;
  }
  if (!profile) {
    const message = `Profile ${sub} not found`;
    dispatch(loadProfileError(sub, message));
    return;
  }
  dispatch(loadProfileSuccess(sub, profile));
  return profile;
};

const dispatchProfiles = async (
  state: TepuiState,
  dispatch: ThunkDispatch<TepuiState, unknown, AnyAction>,
  planStateMap: PlanStateMap
) => {
  const plans = Object.values(planStateMap).map((x) => x.plan);
  const ownerIds = plans.map((x) => x.ownerId);
  const subs = [...new Set(ownerIds)];
  const promises = subs.map((x) => dispatchProfile(state, dispatch, x));
  await Promise.all(promises);
};

export type { ProfileAction };
export { dispatchProfile, dispatchProfiles };
