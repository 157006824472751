import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import {
  IContractState,
  IPlanState,
  ISubscriptionState,
} from "../../redux/reducers/TepuiState";
import { reduceStatus } from "../../utils/stateHelpers";
import AddressChip from "../common/AddressChip";
import GridAmount from "../common/GridAmount";
import SubscriptionDates from "./SubscriptionDates";
import SubscriptionPayment from "./SubscriptionPayment";
import SummaryItemSkeleton from "../common/SummaryItemSkeleton";

interface IProps {
  subscriptionState?: ISubscriptionState;
  contractState?: IContractState;
  planState?: IPlanState;
}

const SubscriptionSummary = ({
  subscriptionState,
  contractState,
  planState,
}: IProps) => {
  const { plan } = planState ?? {};
  const { contract } = contractState ?? {};
  const { subscription } = subscriptionState ?? {};
  const status = reduceStatus(planState, contractState, subscriptionState);
  return (
    <Paper style={{ padding: 5 }}>
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-evenly"
      >
        <Grid item style={{ textAlign: "center" }}>
          {plan ? (
            <Typography variant="subtitle2" noWrap>
              {plan.name}
            </Typography>
          ) : (
            <Skeleton />
          )}
          <AddressChip address={subscription?.address} actionable />
        </Grid>
        <Grid item>
          {subscription && contract ? (
            <GridAmount
              header="Balance"
              tokenAmount={subscription.balance!}
              tokenAddress={contract.token}
            />
          ) : (
            <SummaryItemSkeleton />
          )}
        </Grid>
        <Grid item>
          {subscription && plan ? (
            <SubscriptionPayment subscription={subscription} plan={plan} />
          ) : (
            <SummaryItemSkeleton />
          )}
        </Grid>
        <Grid item>
          {subscription && plan ? (
            <SubscriptionDates subscription={subscription} plan={plan} />
          ) : (
            <SummaryItemSkeleton />
          )}
        </Grid>
      </Grid>
      {["loading", "busy"].includes(status) && <LinearProgress />}
    </Paper>
  );
};

export default SubscriptionSummary;
