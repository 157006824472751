import { IPlan } from "@tepui/core-sdk";
import TepuiEthereumClient from "@tepui/eth-sdk";
import { IPlanState, PlanStateMap } from "../redux/reducers/TepuiState";

const retrieveAddresses = async (
  tepuiEthereumClient: TepuiEthereumClient,
  plan: IPlan
): Promise<string[]> => {
  if (!plan.transactions) return [];
  const initValue: string[] = [];
  return await plan.transactions.reduce(async (prevPromise, hash) => {
    const address = await tepuiEthereumClient.retrieveContractAddress(hash);
    const prev = await prevPromise;
    if (address) prev.push(address);
    return prev;
  }, Promise.resolve(initValue));
};

const retrievePlanMap = async (
  tepuiEthereumClient: TepuiEthereumClient,
  plans: IPlan[]
): Promise<PlanStateMap> => {
  const initialValue: PlanStateMap = {};
  return await plans.reduce(async (prevPromise, plan) => {
    const addresses = await retrieveAddresses(tepuiEthereumClient!, plan);
    const prev = await prevPromise;
    const planState: IPlanState = { plan, addresses, status: "ready" };
    return { ...prev, [plan.id!]: planState };
  }, Promise.resolve(initialValue));
};

export { retrievePlanMap };
