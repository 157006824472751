import { createStore, applyMiddleware, compose, AnyAction } from "redux";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import createRootReducer from "./reducers/index";
import { TepuiState } from "./reducers/TepuiState";

export const history = createBrowserHistory();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const configureStore = () => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools

  return createStore<TepuiState, AnyAction, any, any>(
    createRootReducer(history),
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        reduxImmutableStateInvariant({
          ignore: ["ethereum.tepuiEthereumClient"],
        })
      )
    )
  );
};

export default configureStore;
