import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as planActions from "../../redux/actions/planActions";
import * as subscriptionActions from "../../redux/actions/subscriptionActions";
import {
  ContractStateMap,
  IContractState,
  IEthereum,
  IPlanState,
  PlanStateMap,
  SubscribedMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import CardSkeleton from "../common/CardSkeleton";
import FundModal from "../common/FundModal";
import PageTitle from "../common/PageTitle";
import NoResultsFound from "../NoResultsFound";
import PlanCard from "./PlanCard";

interface IProps {
  ethereum: IEthereum | null;
  availablePlans: string[] | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  subscribedMap: SubscribedMap | null;
  planActions: typeof planActions;
  subscriptionActions: typeof subscriptionActions;
}

const MarketplacePlans = ({
  ethereum,
  availablePlans,
  planStateMap,
  contractStateMap,
  subscribedMap,
  planActions,
  subscriptionActions,
}: IProps) => {
  type PlanContract = {
    planState: IPlanState;
    contractState: IContractState;
  } | null;
  const [fundModalShow, setFundModalShow] = useState(null as PlanContract);
  const { address, status } = ethereum ?? {};

  useEffect(() => {
    if (availablePlans) return;
    if (status !== "ready") return;
    const init = async () => {
      planActions.loadAvailablePlans();
    };
    init();
  }, [status, availablePlans, planActions]);

  useEffect(() => {
    if (subscribedMap) return;
    if (status !== "ready") return;
    const init = async () => {
      subscriptionActions.loadSubscribedSubscriptions();
    };
    init();
  }, [status, subscribedMap, subscriptionActions]);

  if (status !== "ready") return <h2>Connect to Ethereum to retrieve data</h2>;

  if (!availablePlans || !planStateMap)
    return (
      <Grid container spacing={5}>
        {Array.from(new Array(6)).map((_, i) => (
          <Grid item key={i}>
            <CardSkeleton />
          </Grid>
        ))}
      </Grid>
    );
  const subscribedAddresses =
    address && subscribedMap ? subscribedMap[address] : [];
  const addresses = availablePlans.filter(
    (x) => !subscribedAddresses?.includes(x)
  );
  if (addresses.length === 0) return <NoResultsFound />;

  return (
    <>
      <Grid container spacing={5}>
        {Object.values(planStateMap).map((planState) => {
          const { addresses } = planState;
          return addresses.map((x) => {
            if (subscribedAddresses?.includes(x)) return null;
            const contractState = contractStateMap?.[x];
            return (
              <PlanCard
                key={x}
                planState={planState}
                contractState={contractState}
                signUp={setFundModalShow}
              />
            );
          });
        })}
      </Grid>
      <FundModal
        show={fundModalShow != null}
        onHide={() => setFundModalShow(null)}
        planState={fundModalShow?.planState}
        contractState={fundModalShow?.contractState}
      />
    </>
  );
};

const MarketplacePage = (props: IProps) => (
  <Container maxWidth="xl" component="main">
    <PageTitle title="Marketplace" />
    <MarketplacePlans {...props} />
  </Container>
);

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  availablePlans: state.availablePlans,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
  subscribedMap: state.subscribedMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  planActions: bindActionCreators(planActions, dispatch),
  subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketplacePage);
