import React from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TokenAmount from "../common/TokenAmount";
import { Typography } from "@material-ui/core";
import {
  TepuiState,
  IEthereum,
  IConfig,
} from "../../redux/reducers/TepuiState";

const displayCurrency = (amount: number, currency: string) => {
  const formatter = new Intl.NumberFormat("default", {
    style: "currency",
    currency,
  });
  return formatter.format(amount);
};

const calculateFiat = (
  tokenTotals: { [token: string]: string },
  ethereum: IEthereum | null,
  config: IConfig | null
) => {
  const { currency, exchangeRates } = config ?? {};
  if (!currency || !exchangeRates) return "";
  const { tepuiEthereumClient, tokenMap } = ethereum ?? {};
  if (!tepuiEthereumClient || !tokenMap) return "";
  const fiatTotals = Object.entries(tokenTotals).map(
    ([tokenAddress, tokenAmount]) => {
      const token = tokenMap[tokenAddress];
      if (!token) return 0;
      const amount = tepuiEthereumClient.toDisplayAmount(tokenAmount, token);
      return Number(amount) / exchangeRates[token.symbol];
    }
  );
  const fiatAmount = fiatTotals.reduce((prev, x) => prev + x, 0);
  return displayCurrency(fiatAmount, currency);
};

interface IProps {
  header: string;
  tokenTotals: { [token: string]: string };
  ethereum: IEthereum | null;
  config: IConfig | null;
}
const TokenTotal = ({ header, tokenTotals, ethereum, config }: IProps) => (
  <Paper style={{ padding: 20 }}>
    <Grid container spacing={1} alignItems="center" direction="column">
      <Grid item>
        <Typography variant="h6">{header}</Typography>
      </Grid>
      <Grid
        container
        item
        spacing={1}
        alignItems="center"
        justify="space-evenly"
      >
        <Grid item>
          {Object.entries(tokenTotals).map(([tokenAddress, tokenAmount]) => (
            <TokenAmount
              key={tokenAddress}
              variant="subtitle2"
              style={{ color: "#f07365" }}
              tokenAddress={tokenAddress}
              tokenAmount={tokenAmount}
            />
          ))}
        </Grid>
        <Grid item style={{ textAlign: "center" }}>
          <Typography variant="h6" style={{ color: "#7397d1" }}>
            {calculateFiat(tokenTotals, ethereum, config)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Paper>
);

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  config: state.config,
});

export default connect(mapStateToProps)(TokenTotal);
