type FeatureErrors = { [feature: string]: boolean };

interface IPriceTierErrors {
  priceTiers: FeatureErrors[];
}

interface IErrors {
  merchant: boolean;
  imageUrl: boolean;
  features: IPriceTierErrors[];
  connectedPlans: boolean[];
  operators: boolean[];
}

const clone = <T extends any>(value: T): T => {
  const json = JSON.stringify(value);
  return JSON.parse(json) as T;
};

export { clone };
export type { IErrors, IPriceTierErrors };
