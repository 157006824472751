import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import * as sessionActions from "../redux/actions/sessionActions";
import { TepuiState } from "../redux/reducers/TepuiState";
import "./App.css";
import TepuiBar from "./common/TepuiBar";
import Toasts from "./common/Toasts";
import HomePage from "./HomePage";
import MerchantPage from "./merchant/MerchantPage";
import PlanEditPage from "./merchant/PlanEditPage";
import PlanPage from "./merchant/PlanPage";
import PageNotFound from "./PageNotFound";
import ProfilePage from "./ProfilePage";
import FundPage from "./subscriber/FundPage";
import MarketplacePage from "./subscriber/MarketplacePage";
import SubscriberPage from "./subscriber/SubscriberPage";
import SubscriptionPage from "./subscriber/SubscriptionPage";

const tepuiTheme = createMuiTheme({
  // logo colors: #fbc283 #e3936c #535353
  palette: {
    primary: {
      main: "#fbc283",
    },
    secondary: {
      main: "#e3936c",
    },
    background: {
      default: "#f5f5f5",
    },
  },
});

interface IProps {
  session: CognitoUserSession | null;
  sessionActions: typeof sessionActions;
}

const App = ({ session, sessionActions }: IProps) => {
  useEffect(() => {
    const init = async () => {
      await sessionActions.loadSession();
    };
    init();
  }, [sessionActions]);

  return (
    <ThemeProvider theme={tepuiTheme}>
      <CssBaseline />
      <div className="container-fluid">
        <Toasts />
        <TepuiBar />
        {!session && <HomePage />}
        {session && (
          <Switch>
            <Route exact path="/">
              <HomePage />
            </Route>
            <Route path="/merchant/:planId/edit">
              <PlanEditPage />
            </Route>
            <Route path="/merchant/:planId">
              <PlanPage />
            </Route>
            <Route path="/merchant">
              <MerchantPage />
            </Route>
            <Route path="/subscriber/:subscriberAddress/:contractAddress">
              <SubscriptionPage />
            </Route>
            <Route path="/subscriber">
              <SubscriberPage />
            </Route>
            <Route path="/marketplace/:contractAddress">
              <FundPage />
            </Route>
            <Route path="/marketplace">
              <MarketplacePage />
            </Route>
            <Route path="/profile">
              <ProfilePage />
            </Route>
            <Route>
              <PageNotFound />
            </Route>
          </Switch>
        )}
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
