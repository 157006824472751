import { EthereumAction } from "../actions/ethereumActions";
import { PlanAction } from "../actions/planActions";
import { SubscriptionAction } from "../actions/subscriptionActions";
import { updateMap } from "./map";
import { PlanStateMap, Status } from "./TepuiState";

const planStateMapReducer = (
  state: PlanStateMap | null = null,
  action: PlanAction | SubscriptionAction | EthereumAction
): PlanStateMap | null => {
  switch (action.type) {
    case "LOAD_PLAN_SUBMIT":
    case "LOAD_PLAN_ERROR": {
      const { planIds } = action;
      const status: Status =
        action.type === "LOAD_PLAN_SUBMIT" ? "loading" : "failed";
      return planIds!.reduce(
        (prev, x) => updateMap(prev, x, { status }),
        state ?? {}
      );
    }
    case "LOAD_PLAN_SUCCESS":
    case "LOAD_OWNEDPLANS_SUCCESS":
    case "LOAD_AVAILABLEPLANS_SUCCESS":
    case "LOAD_SUBSCRIPTIONS_SUCCESS": {
      const { planStateMap } = action;
      return Object.entries(planStateMap!).reduce((prev, [id, planState]) => {
        return updateMap(prev, id, planState);
      }, state ?? {});
    }
    case "PLAN_SUBMIT":
    case "SAVE_PLAN_ERROR":
    case "SAVE_PLAN_SUCCESS":
    case "DEPLOY_PLANCONTRACT_ERROR":
    case "SAVE_PLANCONTRACT_ERROR":
    case "SAVE_PLANCONTRACT_SUCCESS": {
      const { plan } = action;
      if (!plan?.id) return state;
      const status: Status = action.type === "PLAN_SUBMIT" ? "busy" : "ready";
      return updateMap(state!, plan!.id, { plan, status });
    }
    case "DEPLOY_PLANCONTRACT_SUCCESS": {
      const { plan, contract } = action;
      const addresses = [...state![plan!.id!].addresses, contract!.address!];
      const status: Status = "ready";
      return updateMap(state!, plan!.id!, { addresses, status });
    }
    case "LOAD_ETHEREUM_SUCCESS": {
      return null;
    }
    default:
      return state;
  }
};

export default planStateMapReducer;
