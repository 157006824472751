import React from "react";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

const CardSkeleton = () => (
  <Box width={210} marginRight={0.5} my={5}>
    <Skeleton variant="rect" width={210} height={118} />
    <Box pt={0.5}>
      <Skeleton />
      <Skeleton width="60%" />
    </Box>
  </Box>
);

export default CardSkeleton;
