import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputBase from "@material-ui/core/InputBase";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { IPlan, IFeature, IPriceTier } from "@tepui/core-sdk";
import { TokenMetadataMap, ITokenMetadata } from "eth-contract-metadata";
import { IErrors, IPriceTierErrors, clone } from "./planEditTypes";
import { OutlinedInputProps } from "@material-ui/core/OutlinedInput";

const MAX = 0;

const fieldValidators: { [k in keyof IPriceTier]: (x: string) => boolean } = {
  quantity: (x: string) => !isNaN(Number(x)),
  price: (x: string) => !isNaN(Number(x)),
};

export const newPriceTier = (): IPriceTier => ({ quantity: MAX, price: 0 });

interface IProps {
  feature: IFeature;
  index: number;
  plan: IPlan;
  errors: IErrors;
  setError: (name: string, errors: IPriceTierErrors[]) => void;
  setStateAndValidate: <T extends any>(name: keyof IPlan, value: T) => void;
  tokenMap?: TokenMetadataMap;
}

const FeatureAccordion = ({
  feature,
  index,
  plan,
  errors,
  setError,
  setStateAndValidate,
  tokenMap,
}: IProps) => {
  const [expanded, setExpanded] = useState(false);

  const setPriceTierError = (
    featureIndex: number,
    tierIndex: number,
    name: string,
    value: boolean
  ) => {
    const features = clone(errors.features);
    features[featureIndex].priceTiers[tierIndex][name] = value;
    setError("features", features);
  };

  const handleFeaturesChange = (index: number) => <T extends any>(event: {
    target: {
      name: string;
      value: T;
    };
  }) => {
    const { name, value } = event.target;
    const features = [...plan.features];
    const key = name as keyof IFeature;
    features[index][key] = value as never;
    setStateAndValidate("features", features);
  };

  const handleFeaturesRemove = (index: number) => () => {
    const featuresErrors = errors.features.filter((_, i) => i !== index);
    setError("features", featuresErrors);
    const features = plan.features.filter((_, i) => i !== index);
    setStateAndValidate("features", features);
  };

  const handlePriceTiersChange = (
    index: number,
    tier: number
  ): OutlinedInputProps["onChange"] => (event) => {
    const { name, value } = event.target;
    const priceTiers = [...plan.features[index].priceTiers];
    const priceTier = priceTiers[tier];
    const key = name as keyof IPriceTier;
    priceTier[key] = key === "quantity" && !value ? MAX : Number(value);

    const fieldValidator = fieldValidators[key];
    if (fieldValidator) {
      if (!value) {
        setPriceTierError(index, tier, key, false); // don't validate empty fields
      } else {
        const valid = fieldValidator(value);
        setPriceTierError(index, tier, key, !valid);
      }
    }
    const handler = handleFeaturesChange(index);
    const priceTierEvent = {
      target: { name: "priceTiers", value: priceTiers },
    };
    handler(priceTierEvent);
  };

  const handlePriceTiersAdd = (featureIndex: number) => () => {
    const featuresErrors = [...errors.features];
    featuresErrors[featureIndex].priceTiers.push({});
    setError("features", featuresErrors);
    const features = [...plan.features];
    features[featureIndex].priceTiers.push(newPriceTier());
    setStateAndValidate("features", features);
  };

  const handlePriceTiersRemove = (
    featureIndex: number,
    priceTierIndex: number
  ) => () => {
    const featuresErrors = [...errors.features];
    featuresErrors[featureIndex].priceTiers.splice(priceTierIndex, 1);
    setError("features", featuresErrors);
    const features = [...plan.features];
    features[featureIndex].priceTiers.splice(priceTierIndex, 1);
    setStateAndValidate("features", features);
  };

  const formatter = new Intl.NumberFormat();

  const displayFeatureHelperText = (
    token: ITokenMetadata | undefined,
    priceTiers: IPriceTier[]
  ) => {
    if (!priceTiers || priceTiers.length === 0) return "";
    const price = priceTiers[0].price;
    const tokenSymbol = token?.symbol ?? "";
    const priceNumber = Number(price);
    const displayPrice = isNaN(priceNumber)
      ? "0"
      : formatter.format(priceNumber);
    const tiers = priceTiers.length > 1 ? ` (${priceTiers.length} Tiers)` : "";
    return `${tokenSymbol} ${displayPrice}${tiers}`;
  };

  const token =
    tokenMap && Object.values(tokenMap).find((x) => x.symbol === plan.token);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setExpanded((x) => !x);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={8}>
            <InputBase
              name="name"
              placeholder="✎ New Feature"
              value={feature.name}
              onChange={handleFeaturesChange(index)}
            />
            <FormHelperText>
              {displayFeatureHelperText(token, feature.priceTiers)}
            </FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <Button color="secondary" onClick={handleFeaturesRemove(index)}>
              Remove
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" spacing={2}>
          <Grid container direction="column" item>
            <Grid item>
              <TextField
                required
                fullWidth
                margin="dense"
                size="small"
                multiline
                name="description"
                value={feature.description}
                onChange={handleFeaturesChange(index)}
                label="Description"
              />
            </Grid>
            <Grid item>
              <TextField
                required
                fullWidth
                margin="dense"
                size="small"
                name="code"
                value={feature.code || ""}
                onChange={handleFeaturesChange(index)}
                label="Code"
              />
            </Grid>
          </Grid>
          <Grid container direction="column" item justify="space-between">
            {feature.priceTiers.map((tier, tierIndex) => (
              <Grid
                container
                item
                key={index + "-" + tierIndex}
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={2}>
                  <IconButton
                    size="small"
                    onClick={handlePriceTiersRemove(index, tierIndex)}
                  >
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    margin="dense"
                    size="small"
                    name="quantity"
                    value={tier.quantity === MAX ? "" : tier.quantity}
                    onChange={handlePriceTiersChange(index, tierIndex)}
                    label="Quantity"
                    error={
                      errors.features[index].priceTiers[tierIndex].quantity
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    margin="dense"
                    size="small"
                    name="price"
                    defaultValue={tier.price}
                    onChange={handlePriceTiersChange(index, tierIndex)}
                    label="Price"
                    error={errors.features[index].priceTiers[tierIndex].price}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {token?.symbol || ""}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            ))}
            <Grid item>
              <Tooltip title="Add">
                <Button color="primary" onClick={handlePriceTiersAdd(index)}>
                  Add Tier
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FeatureAccordion;
