import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as routerActions from "../../redux/actions/routerActions";
import { ProfileStateMap, TepuiState } from "../../redux/reducers/TepuiState";

interface IProps {
  style?: React.CSSProperties;
  session: CognitoUserSession | null;
  profileStateMap: ProfileStateMap | null;
  routerActions: typeof routerActions;
}

const UserAvatar = (props: IProps) => {
  const { style, session, profileStateMap, routerActions } = props;
  if (!session) return null;
  const { payload } = session!.getIdToken();
  const { sub } = payload;
  const profileState = profileStateMap?.[sub];
  const { profile } = profileState ?? {};
  const { picture } = profile ?? {};
  return (
    <IconButton
      style={style}
      onClick={() => routerActions.pushRoute("/profile")}
      color="inherit"
    >
      <Avatar src={picture} />
    </IconButton>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
  profileStateMap: state.profileStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserAvatar);
