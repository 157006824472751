import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { Datum, Serie } from "@nivo/line";
import TepuiEthereumClient, { EventData, IEthPlan } from "@tepui/eth-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ContractStateMap,
  IEthereum,
  IPlanState,
  TepuiState,
  IContractState,
} from "../../redux/reducers/TepuiState";
import { bnAdd } from "../../utils/bigNumberHelpers";
import AddressChip from "../common/AddressChip";
import GridAmount from "../common/GridAmount";
import SimpleLineChartSkeleton from "../common/SimpleLineChartSkeleton";
import SimpleLineChart from "../common/TinyLineChart";
import { reduceStatus } from "../../utils/stateHelpers";

const contractSum = (
  contracts: IEthPlan[] | undefined,
  fn: (contract: IEthPlan) => string
) => {
  if (!contracts) return "0";
  const values = contracts.map(fn);
  return values.reduce((prev, x) => bnAdd(prev, x), "0");
};

const combineEvents = async (
  addresses: string[],
  f: (address: string) => Promise<EventData[]>
) => {
  const promises = addresses.map(f);
  const events = await Promise.all(promises);
  return events.flat();
};

const RetrieveLastWeekSubscriptions = async (
  tepuiEthereumClient: TepuiEthereumClient,
  period: IDisplayPeriod,
  addresses?: string[]
): Promise<Datum[]> => {
  const { periodStart, periodEnd, seedData, display } = period;
  if (!addresses || addresses.length === 0) return seedData;
  const subscriptions = await combineEvents(addresses, (x) =>
    tepuiEthereumClient.getPastEvents(x, "Subscribe")
  );
  const blockTimestamps = await tepuiEthereumClient.getBlockTimestamps(
    subscriptions
  );
  return subscriptions.reduce((prev, x) => {
    const timestamp = blockTimestamps[x.blockHash];
    if (timestamp < periodStart || timestamp > periodEnd) return prev;
    const displayTimestamp = display(timestamp);
    const prevDisplayTimestamp = prev.find((x) => x.x === displayTimestamp);
    if (!prevDisplayTimestamp) return [...prev, { x: displayTimestamp, y: 1 }];
    prevDisplayTimestamp.y++;
    return prev;
  }, seedData);
};

interface IDisplayPeriod {
  periodStart: number;
  periodEnd: number;
  seedData: { x: string; y: number }[];
  display: (timestamp: number) => string;
}

interface IProps {
  planState: IPlanState;
  period: IDisplayPeriod;
  ethereum: IEthereum | null;
  contractStateMap: ContractStateMap | null;
}

const PlanSummary = ({
  planState,
  period,
  ethereum,
  contractStateMap,
}: IProps) => {
  const [data, setData] = useState(undefined as Serie[] | undefined);
  const { plan, addresses } = planState;
  const contractStates = addresses?.reduce((prev, x) => {
    const contractState = contractStateMap?.[x];
    if (contractState) prev.push(contractState);
    return prev;
  }, [] as IContractState[]) ?? [];
  const contracts = contractStates?.reduce((prev, x) => {
    const { contract } = x;
    if (contract) prev.push(contract);
    return prev;
  }, [] as IEthPlan[]);
  const status = reduceStatus(...contractStates, planState);

  useEffect(() => {
    let mounted = true;
    if (status !== "ready") return;
    if (!ethereum?.tepuiEthereumClient) return;
    const { tepuiEthereumClient } = ethereum;
    const init = async () => {
      const points = await RetrieveLastWeekSubscriptions(
        tepuiEthereumClient,
        period,
        addresses
      );
      if (!mounted) return;
      const data = [{ id: "Subscribers", data: points }];
      setData(data);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [ethereum, status, addresses, period]);

  return (
    <Paper style={{ padding: 5 }}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={2} style={{ textAlign: "center" }}>
          <Typography variant="subtitle2" noWrap>
            {plan.name}
          </Typography>
          {addresses &&
            addresses.map((x) => (
              <AddressChip key={x} address={x} actionable />
            ))}
        </Grid>
        <Grid item xs={6} sm={3}>
          <GridAmount
            header="Consumed"
            tokenAmount={contractSum(contracts, (x) => x.consumed)}
            tokenSymbol={plan.token}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <GridAmount
            header="Balance"
            tokenAmount={contractSum(contracts, (x) => x.balance)}
            tokenSymbol={plan.token}
          />
        </Grid>
        <Grid item xs={12} sm={4} style={{ height: 50 }}>
          {data ? <SimpleLineChart data={data} /> : <SimpleLineChartSkeleton />}
        </Grid>
      </Grid>
      {["loading", "busy"].includes(status) && <LinearProgress />}
    </Paper>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  contractStateMap: state.contractStateMap,
});

export default connect(mapStateToProps)(PlanSummary);
export type { IDisplayPeriod };
