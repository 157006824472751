import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { IProfile } from "@tepui/core-sdk";
import { EventData, IToken } from "@tepui/eth-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import web3Utils from "web3-utils";
import {
  ContractStateMap,
  IConfig,
  IEthereum,
  IPlanState,
  IRegistry,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { bnAdd } from "../../utils/bigNumberHelpers";
import * as tokenUtils from "../../utils/token";
import AddressBlockie, {
  BlockieMap,
  blockieMapFromArray,
} from "../common/AddressBlockie";
import AddressChip from "../common/AddressChip";
import TableSkeleton from "../common/TableSkeleton";

const dateFormatter = Intl.DateTimeFormat("default", {
  day: "numeric",
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

const displayDate = (timestamp?: number) => {
  if (!timestamp) return "";
  const date = new Date(timestamp * 1000);
  return dateFormatter.format(date) + " " + date.toLocaleTimeString();
};

const displaySubscriber = (profile?: IProfile) => {
  if (!profile) return "[Unknown]";
  return profile.name;
};

const flatMapResolve = async <T extends unknown>(
  addresses: string[],
  f: (address: string) => Promise<T[]>
) => {
  const promises = addresses.map(f);
  const events = await Promise.all(promises);
  return events.flat();
};

interface IProps {
  planState?: IPlanState;
  maxHeight?: number;
  registry: IRegistry | null;
  ethereum: IEthereum | null;
  config: IConfig | null;
  contractStateMap: ContractStateMap | null;
}

type BlockTimestamps = { [index: string]: number };
type SubscriberMap = { [address: string]: IProfile };
type FeatureMap = { [hash: string]: string };

const ConsumptionTable = (props: IProps) => {
  const [consumptions, setConsumptions] = useState(
    undefined as EventData[] | undefined
  );
  const [blockTimestamps, setBlockTimestamps] = useState(
    undefined as BlockTimestamps | undefined
  );
  const [subscriberMap, setSubscriberMap] = useState(
    undefined as SubscriberMap | undefined
  );
  const [featureMap, setFeatureMap] = useState(
    undefined as FeatureMap | undefined
  );
  const [blockieMap, setBlockieMap] = useState(
    undefined as BlockieMap | undefined
  );
  const [total, setTotal] = useState("0");
  const { tepuiRegistry } = props.registry ?? {};
  const { tepuiEthereumClient, tokenMap } = props.ethereum ?? {};
  const { plan } = props.planState ?? {};

  useEffect(() => {
    if (!plan) return;
    const featureMap = plan.features.reduce((map, x) => {
      const codeHash = web3Utils.soliditySha3Raw(x.code);
      return { ...map, [codeHash!]: x.name };
    }, {} as FeatureMap);
    setFeatureMap(featureMap);
  }, [plan]);

  useEffect(() => {
    if (!tepuiRegistry) return;
    if (!tepuiEthereumClient) return;
    if (!props.contractStateMap) return;
    if (!props.planState) return;
    const { plan, addresses } = props.planState;
    const { id } = plan;
    let mounted = true;
    const init = async () => {
      const getPastEvents = (x: string) =>
        tepuiEthereumClient.getPastEvents(x, "Consume");
      const consumptions = await flatMapResolve(addresses, getPastEvents);
      const blockieAddresses: string[] = consumptions
        .map((x) => x.returnValues["account"])
        .concat(addresses);
      const blockieMap = blockieMapFromArray(blockieAddresses);
      const initialSubscriberMap: SubscriberMap = {};
      const subscriberMap = await consumptions.reduce(async (prev, x) => {
        const { account } = x.returnValues;
        const map = await prev;
        if (map[account]) return map;
        const subscription = await tepuiRegistry.retrieveSubscription(
          id!,
          account
        );
        if (!subscription) return prev;
        const { profile } = subscription;
        map[account] = profile!;
        return map;
      }, Promise.resolve(initialSubscriberMap));
      const total = consumptions.reduce(
        (prev, x) => bnAdd(prev, x.returnValues.amount),
        "0"
      );
      const blockTimestamps = await tepuiEthereumClient.getBlockTimestamps(
        consumptions
      );
      if (!mounted) return;
      setConsumptions(consumptions);
      setBlockieMap(blockieMap);
      setSubscriberMap(subscriberMap);
      setTotal(total);
      setBlockTimestamps(blockTimestamps);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [
    tepuiRegistry,
    tepuiEthereumClient,
    props.planState,
    props.contractStateMap,
  ]);

  if (!plan?.id || !tepuiEthereumClient || !tokenMap || !consumptions)
    return <TableSkeleton />;
  const token: IToken = Object.values(tokenMap).find(
    (x) => x.symbol === plan.token
  ) ?? { name: "???", symbol: "???", decimals: 6 };

  return (
    <TableContainer style={{ maxHeight: props.maxHeight }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date and Time</TableCell>
            <TableCell align="center">Contract</TableCell>
            <TableCell>Subscriber</TableCell>
            <TableCell align="center">Address</TableCell>
            <TableCell>Feature</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consumptions.map((x) => (
            <TableRow key={x.transactionHash}>
              <TableCell>
                {displayDate(blockTimestamps?.[x.blockHash])}
              </TableCell>
              <TableCell align="center">
                <AddressChip address={x.address} actionable />
              </TableCell>
              <TableCell>
                {displaySubscriber(subscriberMap?.[x.returnValues.account])}
              </TableCell>
              <TableCell align="center">
                <AddressBlockie
                  accountAddress={x.returnValues.account}
                  blockieMap={blockieMap}
                />
              </TableCell>
              <TableCell>{featureMap?.[x.returnValues.feature]}</TableCell>
              <TableCell align="right">{x.returnValues.quantity}</TableCell>
              <TableCell align="right">
                {tokenUtils.displayToken(
                  x.returnValues.amount,
                  token,
                  tepuiEthereumClient
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              <Typography variant="h6">Total</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">
                {tokenUtils.displayToken(total, token, tepuiEthereumClient)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
  ethereum: state.ethereum,
  config: state.config,
  contractStateMap: state.contractStateMap,
});

export default connect(mapStateToProps)(ConsumptionTable);
