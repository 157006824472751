import TepuiEthereumClient from "@tepui/eth-sdk";
import tokenMetadataMap, {
  ITokenMetadata,
  TokenMetadataMap,
} from "eth-contract-metadata";
import Web3 from "web3";
import { setMap } from "../reducers/map";
import { IEthereum } from "../reducers/TepuiState";
import { EthereumActionType, TepuiAction, TepuiThunk } from "./actionTypes";
import * as configActions from "./configActions";
import * as subscriptionActions from "./subscriptionActions";

const displayAddress = (addr: string) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

interface EthereumAction extends TepuiAction {
  type: EthereumActionType;
  ethereum?: IEthereum | null;
  address?: string;
}

const loadEthereumSubmit = (): EthereumAction => ({
  type: "LOAD_ETHEREUM_SUBMIT",
  ethereum: { status: "loading" },
});

const loadEthereumSuccess = (ethereum: IEthereum): EthereumAction => ({
  type: "LOAD_ETHEREUM_SUCCESS",
  ethereum,
});

const loadEthereumError = (message: string): EthereumAction => ({
  type: "LOAD_ETHEREUM_ERROR",
  message,
});

const addressChangeSuccess = (
  address: string,
  message: string
): EthereumAction => ({
  type: "ADDRESS_CHANGE_SUCCESS",
  address,
  message,
});

const verifyAddressSuccess = (
  address: string,
  message: string
): EthereumAction => ({
  type: "VERIFY_ADDRESS_SUCCESS",
  address,
  message,
});

const verifyAddressError = (message: string): EthereumAction => ({
  type: "VERIFY_ADDRESS_ERROR",
  message,
});

const setLogo = (
  metadata: ITokenMetadata | undefined
): ITokenMetadata | undefined => {
  const fileName = metadata?.logo;
  if (!fileName || fileName.startsWith("data:image")) return metadata;
  metadata!.logo = require(`eth-contract-metadata/images/${fileName}`);
  return metadata;
};

const loadEthereum = (provider?: any): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry ?? {};
    dispatch(loadEthereumSubmit());
    let { tepuiEthereumClient } = state.ethereum ?? {};
    if (provider) {
      try {
        const web3 = new Web3(provider);
        tepuiEthereumClient = await TepuiEthereumClient.init(web3);
      } catch (error) {
        const message = `Unable to initialize ethereum: ${error.message}`;
        dispatch(loadEthereumError(message));
        return;
      }
    }
    const address = tepuiEthereumClient!.defaultAccount;
    let tokenAddresses;
    try {
      tokenAddresses = await tepuiRegistry!.retrieveTokens();
    } catch (error) {
      const message = `Unable to retrieve tokens from registry: ${error.message}`;
      dispatch(loadEthereumError(message));
      return;
    }
    const findByAddress = (address: string) => tokenMetadataMap[address];
    const findBySymbol = (symbol: string) =>
      Object.values(tokenMetadataMap).find((x) => x.symbol === symbol);
    const initialMap: TokenMetadataMap = {};
    const tokenMap = await tokenAddresses.reduce(
      async (
        prev: Promise<TokenMetadataMap>,
        x: string
      ): Promise<TokenMetadataMap> => {
        const tokenMetadata = setLogo(findByAddress(x));
        if (tokenMetadata) return setMap(await prev, x, tokenMetadata);
        const token = await tepuiEthereumClient!
          .retrieveToken(x)
          .catch(() => {}); // Returned values aren't valid, did it run Out of Gas?
        if (!token) return prev;
        const { symbol } = token;
        const testTokenMetadata = setLogo(findBySymbol(symbol));
        if (testTokenMetadata) return setMap(await prev, x, testTokenMetadata);
        const defaultTokenMetadata = { ...token, erc20: true };
        return setMap(await prev, x, defaultTokenMetadata);
      },
      Promise.resolve(initialMap)
    );
    const ethereum: IEthereum = {
      status: "ready",
      tepuiEthereumClient,
      tokenMap,
      address,
    };
    dispatch(loadEthereumSuccess(ethereum));
    dispatch(configActions.loadConfig());
  };
};

const changeAddress = (address: string) => {
  const message = address
    ? `Address changed to [${displayAddress(address)}]`
    : "Logged out from wallet";
  return addressChangeSuccess(address, message);
};

const verifyAddress = (): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry ?? {};
    const { tepuiEthereumClient } = state.ethereum ?? {};
    if (!tepuiRegistry || !tepuiEthereumClient) return;
    const address = tepuiEthereumClient.defaultAccount!;
    const payload = new Date().toISOString();
    const signature = await tepuiEthereumClient.signPayload(payload);
    try {
      await tepuiRegistry.verifyUserAddress(address, payload, signature);
    } catch (error) {
      dispatch(verifyAddressError(error.message));
      return;
    }
    const message = `Address [${displayAddress(
      address
    )}] successfully verified. Reloading subscriptions...`;
    dispatch(verifyAddressSuccess(address, message));
    dispatch(subscriptionActions.loadSubscribedSubscriptions());
  };
};

export type { EthereumAction };
export { loadEthereum, changeAddress, verifyAddress };
