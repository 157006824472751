import { FormHelperText, InputProps, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IOperator, IPlan } from "@tepui/core-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRegistry, TepuiState } from "../../redux/reducers/TepuiState";
import { IErrors } from "./planEditTypes";

const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

const displayUuid = (id: string) => {
  if (!id || !isUuid(id)) return "";
  return `${id.substring(0, 6)}...${id.substring(id.length - 4)}`;
};

const isUuid = (id: string) => !id || uuidRegex.test(id);

const fieldValidators: { [index: string]: (value: string) => boolean } = {
  operatorId: isUuid,
};

interface IProps {
  operator: IOperator;
  index: number;
  plan: IPlan;
  errors: IErrors;
  setError: (name: string, errors: boolean[]) => void;
  setStateAndValidate: <T extends any>(name: keyof IPlan, value: T) => void;
  registry: IRegistry | null;
}

const OperatorsAccordion = ({
  operator,
  index,
  plan,
  errors,
  setError,
  setStateAndValidate,
  registry,
}: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState("");

  const getPlanName = async (id: string): Promise<boolean> => {
    if (!id) return false;
    const { tepuiRegistry } = registry ?? {};
    if (!tepuiRegistry) return true;
    let planName: string | undefined;
    try {
      const plan = await tepuiRegistry.retrievePlan(id);
      planName = plan?.name;
    } catch (error) {}
    const error = !Boolean(planName);
    if (!planName) planName = "Invalid Plan!";
    setName(planName);
    return error;
  };

  useEffect(() => {
    getPlanName(operator.id);
  });

  const setOptionalAddOnError = (
    optionalAddOnIndex: number,
    value: boolean
  ) => {
    const operators = [...errors.operators];
    operators[optionalAddOnIndex] = value;
    setError("operators", operators);
  };

  const handleAddOnChange = (index: number) => {
    const x: InputProps["onChange"] = async (event) => {
      const { name, value } = event.target;
      const operators = [...plan.operators];
      operators[index] = { operatorType: "plan", id: value };
      const fieldValidator = fieldValidators[name];
      if (fieldValidator) {
        let operatorError = value ? !fieldValidator(value) : false;
        if (!operatorError && value && name === "operatorId") {
          operatorError = await getPlanName(value);
        }
        setOptionalAddOnError(index, operatorError);
      }
      setStateAndValidate("operators", operators);
    };
    return x;
  };
  const handleAddOnRemove = (index: number) => () => {
    const operatorErrors = errors.operators.filter((_, i) => i !== index);
    setError("operators", operatorErrors);
    const operators = plan.operators.filter((_, i) => i !== index);
    setStateAndValidate("operators", operators);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setExpanded((x) => !x);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={8}>
            <Typography style={name ? undefined : { opacity: 0.38 }}>
              {name || "✎ New Operator"}
            </Typography>
            <FormHelperText>{displayUuid(operator.id)}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <Button color="secondary" onClick={handleAddOnRemove(index)}>
              Remove
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          required
          fullWidth
          margin="dense"
          name="operatorId"
          placeholder="ffffffff-ffff-ffff-ffff-ffffffffffff"
          value={operator.id}
          onChange={handleAddOnChange(index)}
          label={"Operator Id"}
          helperText="The operator identifier"
          error={errors.operators[index]}
        />
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
});

export default connect(mapStateToProps)(OperatorsAccordion);
