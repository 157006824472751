import Button from "@material-ui/core/Button";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as ethereumActions from "../../redux/actions/ethereumActions";
import {
  IEthereum,
  IRegistry,
  TepuiState,
} from "../../redux/reducers/TepuiState";

interface IProps {
  registry: IRegistry | null;
  ethereum: IEthereum | null;
  ethereumActions: typeof ethereumActions;
}

const VerifyButton = ({ registry, ethereum, ethereumActions }: IProps) => {
  const { addresses } = registry ?? {};
  const { address } = ethereum ?? {};
  if (!address) return null;
  if (addresses?.includes(address)) return null;
  return <Button onClick={ethereumActions.verifyAddress}>Verify</Button>;
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
  ethereum: state.ethereum,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  ethereumActions: bindActionCreators(ethereumActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyButton);
