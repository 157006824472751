import { Status } from "../redux/reducers/TepuiState";

const severity: { [status in Status]: number } = {
  ready: 0,
  busy: 1,
  loading: 2,
  failed: 3,
};

const reduceStatus = (...states: ({ status: Status } | undefined)[]) => {
  return states.reduce((prev, x) => {
    const status = x?.status ?? "loading";
    return severity[status] > severity[prev] ? status : prev;
  }, "ready" as Status);
};

export { reduceStatus };
