import { SessionAction } from "../actions/sessionActions";
import { CognitoUserSession } from "amazon-cognito-identity-js";

const sessionReducer = (
  state: CognitoUserSession | null = null,
  action: SessionAction
): CognitoUserSession | null => {
  switch (action.type) {
    case "SESSION_LOAD_SUCCESS":
    case "SESSION_SIGNIN_SUCCESS":
      return action.session!;
    case "SESSION_SIGNOUT_SUCCESS":
      return null;
    default:
      return state;
  }
};

export default sessionReducer;
