import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import SimpleLineChartSkeleton from "./SimpleLineChartSkeleton";

const SummarySkeleton = () => (
  <Paper style={{ padding: 5 }}>
    <Grid container direction="row" alignItems="center" justify="space-evenly">
      <Box width={190} my={3}>
        <Skeleton width="60%" />
        <Skeleton width="60%" />
      </Box>
      <Box width={190} my={3}>
        <Skeleton width="60%" />
        <Skeleton width="50%" />
        <Skeleton width="30%" />
      </Box>
      <Box width={190} my={3}>
        <Skeleton width="60%" />
        <Skeleton width="50%" />
        <Skeleton width="30%" />
      </Box>
      <SimpleLineChartSkeleton />
    </Grid>
  </Paper>
);

export default SummarySkeleton;
