import { PlanAction } from "../actions/planActions";
import { EthereumAction } from "../actions/ethereumActions";

const ownedPlansReducer = (
  state: string[] | null = null,
  action: PlanAction | EthereumAction
): string[] | null => {
  switch (action.type) {
    case "LOAD_OWNEDPLANS_SUCCESS":
      const { planStateMap } = action;
      return Object.keys(planStateMap!);
    case "SAVE_PLAN_SUCCESS": {
      const { plan } = action;
      const { id } = plan!;
      if (state?.includes(id!)) return state;
      const prev = state ?? [];
      return [...prev, id!];
    }
    case "LOAD_ETHEREUM_SUCCESS": {
      return null;
    }
    default:
      return state;
  }
};

export default ownedPlansReducer;
