import { IEthereum } from "./TepuiState";
import { EthereumAction } from "../actions/ethereumActions";

const ethereumReducer = (
  state: IEthereum | null = null,
  action: EthereumAction
): IEthereum | null => {
  switch (action.type) {
    case "LOAD_ETHEREUM_SUBMIT":
    case "LOAD_ETHEREUM_ERROR": {
      const { type } = action;
      const status = type === "LOAD_ETHEREUM_ERROR" ? "failed" : "loading";
      return { ...state!, status };
    }
    case "LOAD_ETHEREUM_SUCCESS":
      return action.ethereum!;
    case "ADDRESS_CHANGE_SUCCESS":
      const tepuiEthereumClient = state!.tepuiEthereumClient;
      if (!tepuiEthereumClient) return state;
      tepuiEthereumClient.accountChanged(action.address!);
      const address = tepuiEthereumClient.defaultAccount;
      return { ...state!, address };
    default:
      return state;
  }
};

export default ethereumReducer;
