import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as routerActions from "../redux/actions/routerActions";
import * as sessionActions from "../redux/actions/sessionActions";
import { TepuiState } from "../redux/reducers/TepuiState";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {"Copyright © "}
    <Link color="inherit" href="https://tepui.io/">
      Tepui Inc.
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
  </Typography>
);

interface IProps {
  session: CognitoUserSession | null;
  routerActions: typeof routerActions;
  sessionActions: typeof sessionActions;
}

const HomePage = ({ session, routerActions, sessionActions }: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <img
          src={process.env.PUBLIC_URL + "/logo192.png"}
          alt="tepui logo"
          style={{
            height: 96,
            width: 96,
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          Welcome to Tepui
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Revenue Management for the Blockchain
        </Typography>
        <Grid
          container
          className={classes.heroButtons}
          spacing={2}
          justify="center"
        >
          {!session && (
            <Grid item>
              <Button
                onClick={sessionActions.signIn}
                variant="contained"
                color="primary"
              >
                Sign In
              </Button>
            </Grid>
          )}
          {session && (
            <>
              <Grid item>
                <Button
                  onClick={() => routerActions.pushRoute("/merchant")}
                  variant="contained"
                  color="primary"
                >
                  I'm a Merchant
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => routerActions.pushRoute("/subscriber")}
                  variant="contained"
                  color="secondary"
                >
                  I'm a Subscriber
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => routerActions.pushRoute("/marketplace")}
                  variant="contained"
                  color="primary"
                >
                  I'm Buying
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
    </div>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  routerActions: bindActionCreators(routerActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
