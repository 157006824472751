import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import StorefrontIcon from "@material-ui/icons/Storefront";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as routerActions from "../../redux/actions/routerActions";
import * as sessionActions from "../../redux/actions/sessionActions";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { TepuiState } from "../../redux/reducers/TepuiState";

interface IProps {
  session: CognitoUserSession | null;
  routerActions: typeof routerActions;
  sessionActions: typeof sessionActions;
}

const TepuiDrawer = ({ session, routerActions, sessionActions }: IProps) => {
  const [showDrawer, setShowDrawer] = useState(false);
  const toggleDrawer = (show: boolean) => () => {
    setShowDrawer(show);
  };
  if (!session) return null;
  return (
    <>
      <IconButton edge="start" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={showDrawer} onClose={toggleDrawer(false)}>
        <List style={{ width: 250 }} onClick={toggleDrawer(false)}>
          <ListItem button onClick={() => routerActions.pushRoute("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <Divider />
          <ListItem button onClick={() => routerActions.pushRoute("/merchant")}>
            <ListItemIcon>
              <StorefrontIcon />
            </ListItemIcon>
            <ListItemText primary="Merchant" />
          </ListItem>
          <ListItem
            button
            onClick={() => routerActions.pushRoute("/subscriber")}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>

            <ListItemText primary="Subscriber" />
          </ListItem>
          <ListItem
            button
            onClick={() => routerActions.pushRoute("/marketplace")}
          >
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Marketplace" />
          </ListItem>
          <ListItem button onClick={sessionActions.signOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  routerActions: bindActionCreators(routerActions, dispatch),
  sessionActions: bindActionCreators(sessionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TepuiDrawer);
