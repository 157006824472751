import { IPlan } from "@tepui/core-sdk";
import { IEthBalanceEvent, IEthPlan, IEthToken } from "@tepui/eth-sdk";
import TepuiRegistry from "@tepui/registry-sdk";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { retrievePlanMap } from "../../utils/contractHelpers";
import { PlanStateMap, Status, TepuiState } from "../reducers/TepuiState";
import { PlanActionType, TepuiAction, TepuiThunk } from "./actionTypes";
import { dispatchProfile, dispatchProfiles } from "./profileActions";

interface PlanAction extends TepuiAction {
  type: PlanActionType;
  planIds?: string[];
  planStateMap?: PlanStateMap;
  plan?: IPlan;
  address?: string;
  contract?: IEthPlan;
  events?: IEthBalanceEvent[];
}

const loadPlanSubmit = (planIds: string[]): PlanAction => ({
  type: "LOAD_PLAN_SUBMIT",
  planIds,
});

const loadPlanSuccess = (planStateMap: PlanStateMap): PlanAction => ({
  type: "LOAD_PLAN_SUCCESS",
  planStateMap,
});

const loadPlanError = (planIds: string[], message: string): PlanAction => ({
  type: "LOAD_PLAN_ERROR",
  planIds,
  message,
});

const loadContractSubmit = (address: string): PlanAction => ({
  type: "LOAD_CONTRACT_SUBMIT",
  address,
});

const loadContractSuccess = (contract: IEthPlan): PlanAction => ({
  type: "LOAD_CONTRACT_SUCCESS",
  contract,
});

const loadContractError = (address: string, message: string): PlanAction => ({
  type: "LOAD_CONTRACT_ERROR",
  address,
  message,
});

const loadAvailablePlansSuccess = (planStateMap: PlanStateMap): PlanAction => ({
  type: "LOAD_AVAILABLEPLANS_SUCCESS",
  planStateMap,
});

const loadOwnedPlansSuccess = (planStateMap: PlanStateMap): PlanAction => ({
  type: "LOAD_OWNEDPLANS_SUCCESS",
  planStateMap,
});

const planSubmit = (plan: IPlan): PlanAction => ({ type: "PLAN_SUBMIT", plan });

const savePlanSuccess = (plan: IPlan, message: string): PlanAction => ({
  type: "SAVE_PLAN_SUCCESS",
  plan,
  message,
});

const savePlanError = (plan: IPlan, message: string): PlanAction => ({
  type: "SAVE_PLAN_ERROR",
  plan,
  message,
});

const deployPlanContractSuccess = (
  plan: IPlan,
  contract: IEthPlan,
  message: string
): PlanAction => ({
  type: "DEPLOY_PLANCONTRACT_SUCCESS",
  plan,
  contract,
  message,
});

const deployPlanContractError = (plan: IPlan, message: string): PlanAction => ({
  type: "DEPLOY_PLANCONTRACT_ERROR",
  plan,
  message,
});

const contractSubmit = (contract: IEthPlan): PlanAction => ({
  type: "CONTRACT_SUBMIT",
  contract,
});

const savePlanContractSuccess = (
  contract: IEthPlan,
  message: string
): PlanAction => ({
  type: "SAVE_PLANCONTRACT_SUCCESS",
  contract,
  message,
});

const savePlanContractError = (
  contract: IEthPlan,
  message: string
): PlanAction => ({
  type: "SAVE_PLANCONTRACT_ERROR",
  contract,
  message,
});

const withdrawPlanSuccess = (
  contract: IEthPlan,
  events: IEthBalanceEvent[],
  message: string
): PlanAction => ({
  type: "WITHDRAW_PLAN_SUCCESS",
  contract,
  events,
  message,
});

const withdrawPlanError = (
  contract: IEthPlan,
  message: string
): PlanAction => ({
  type: "WITHDRAW_PLAN_ERROR",
  contract,
  message,
});

const displayAddress = (addr: string) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

const getPlanIds = async (plan: IPlan) => {
  const connectedPlanIds = plan.connectedPlans.map((x) => x.plan);
  const operatorPlanIds = plan.operators
    .filter((x) => x.operatorType === "plan")
    .map((x) => x.id);
  return [...new Set([...connectedPlanIds, ...operatorPlanIds])];
};

type StateMap = { [id: string]: { status: Status } };

const isLoaded = (stateMap: StateMap | null, id: string): boolean => {
  if (!stateMap) return false;
  const state = stateMap[id];
  if (!state) return false;
  const { status } = state;
  const exists: Status[] = ["loading", "busy", "ready"];
  return exists.includes(status);
};

const dispatchPlan = async (
  state: TepuiState,
  dispatch: ThunkDispatch<TepuiState, unknown, AnyAction>,
  id: string,
  onlyOwned = false
) => {
  const { registry, ethereum, session, planStateMap } = state;
  if (isLoaded(planStateMap, id)) return;
  const idToken = onlyOwned ? session?.getIdToken() : undefined;
  const sub: string = idToken?.payload.sub;
  const { tepuiRegistry } = registry!;
  const { tepuiEthereumClient } = ethereum!;
  dispatch(loadPlanSubmit([id]));
  let plan: IPlan | undefined;
  try {
    plan = await tepuiRegistry!.retrievePlan(id);
  } catch (error) {
    const message = `Loading plan ${id} failed: ${error.message}`;
    dispatch(loadPlanError([id], message));
    return;
  }
  if (plan === undefined || (onlyOwned && plan.ownerId !== sub)) {
    const message = `Plan ${id} not found`;
    dispatch(loadPlanError([id], message));
    return;
  }
  const { ownerId } = plan;
  dispatchProfile(state, dispatch, ownerId);
  const plans = [plan];
  try {
    const planStateMap = await retrievePlanMap(tepuiEthereumClient!, plans);
    dispatch(loadPlanSuccess(planStateMap));
    return planStateMap;
  } catch (error) {
    const message = `Loading plan ${id} failed: ${error.message}`;
    dispatch(loadPlanError([id], message));
  }
};

const dispatchContract = async (
  state: TepuiState,
  dispatch: ThunkDispatch<TepuiState, unknown, AnyAction>,
  address: string
) => {
  const { ethereum, contractStateMap } = state;
  if (isLoaded(contractStateMap, address)) return;
  const { tepuiEthereumClient } = ethereum!;
  dispatch(loadContractSubmit(address));
  try {
    const contract = await tepuiEthereumClient!.retrieveContract(address);
    dispatch(loadContractSuccess(contract));
    return contract;
  } catch (error) {
    const message = `Loading contract ${address} failed: ${error.message}`;
    dispatch(loadContractError(address, message));
  }
};

const dispatchContracts = async (
  state: TepuiState,
  dispatch: ThunkDispatch<TepuiState, unknown, AnyAction>,
  planStateMap: PlanStateMap
) => {
  const addresses = Object.values(planStateMap).flatMap((x) => x.addresses);
  const promises = addresses.map((x) => dispatchContract(state, dispatch, x));
  await Promise.all(promises);
};

const loadAvailablePlans = (): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry ?? {};
    const { tepuiEthereumClient } = state.ethereum ?? {};
    const plans = await tepuiRegistry!.retrievePlans();
    const planStateMap = await retrievePlanMap(tepuiEthereumClient!, plans);
    dispatch(loadAvailablePlansSuccess(planStateMap));
    const profilesPromise = dispatchProfiles(state, dispatch, planStateMap);
    const contractPromise = dispatchContracts(state, dispatch, planStateMap);
    await Promise.all([profilesPromise, contractPromise]);
  };
};

const loadPlan = (id: string): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const planStateMap = await dispatchPlan(state, dispatch, id, true);
    if (!planStateMap) return;
    await dispatchContracts(state, dispatch, planStateMap);
  };
};

const loadContract = (address: string): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const contract = await dispatchContract(state, dispatch, address);
    if (!contract) return;
    const { id } = contract;
    await dispatchPlan(state, dispatch, id);
  };
};

const loadOwnedPlans = (): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { registry, ethereum } = state;
    const { tepuiRegistry } = registry!;
    const { tepuiEthereumClient } = ethereum!;
    const plans = await tepuiRegistry!.retrieveOwnedPlans();
    const planStateMap = await retrievePlanMap(tepuiEthereumClient!, plans);
    dispatch(loadOwnedPlansSuccess(planStateMap));
    const profilesPromise = dispatchProfiles(state, dispatch, planStateMap);
    const contractPromise = dispatchContracts(state, dispatch, planStateMap);
    await Promise.all([profilesPromise, contractPromise]);
  };
};

const savePlan = (plan: IPlan): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry!;
    const isNew = !plan.id;
    dispatch(planSubmit(plan));
    try {
      if (isNew) {
        const { id } = await tepuiRegistry!.createPlan(plan);
        plan = { ...plan, id };
      } else {
        await tepuiRegistry!.updatePlan(plan);
      }
    } catch (error) {
      const message = `Saving of plan ${plan.name} failed: ${error.message}`;
      dispatch(savePlanError(plan, message));
      return;
    }
    const message = `Plan ${plan.name} was saved successfully`;
    dispatch(savePlanSuccess(plan, message));
  };
};

const addTransactionToPlan = (
  plan: IPlan,
  tepuiRegistry: TepuiRegistry
) => async (transaction: string) => {
  const transactions = plan.transactions
    ? [...plan.transactions, transaction]
    : [transaction];
  // TODO: new API to push transaction instead of updating plan
  plan = { ...plan, transactions };
  await tepuiRegistry.updatePlan(plan);
};

const deployPlanContract = (plan: IPlan): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry!;
    const { tepuiEthereumClient, tokenMap } = state.ethereum!;
    const entry = Object.entries(tokenMap!).find(
      (x) => x[1].symbol === plan.token
    );
    if (!entry) {
      const message = `Deployment of plan ${plan.name} failed: token not found for [${plan.token}]`;
      dispatch(deployPlanContractError(plan, message));
      return;
    }
    const [tokenAddress, token] = entry;
    const contractToken: IEthToken = {
      address: tokenAddress,
      decimals: token.decimals,
    };
    const planIds = await getPlanIds(plan);
    const planTransactionMap = await tepuiRegistry!.getPlanTransactionMap(
      planIds
    );
    const addressMap = await tepuiEthereumClient!.getAddressMap(
      planTransactionMap
    );
    const contractPlan = tepuiEthereumClient!.contractFromPlan(
      plan,
      contractToken,
      addressMap
    );
    dispatch(planSubmit(plan));
    let contract: IEthPlan;
    try {
      const callback = addTransactionToPlan(plan, tepuiRegistry!);
      const address = await tepuiEthereumClient!.createPlan(
        contractPlan,
        callback
      );
      contract = { ...contractPlan, address };
    } catch (error) {
      const message = `Deployment of plan ${plan.name} failed: ${error.message}`;
      dispatch(deployPlanContractError(plan, message));
      return;
    }
    const prettyAddress = displayAddress(contract.address!);
    const message = `Plan ${plan.name} was deployed successfully to ${prettyAddress}`;
    dispatch(deployPlanContractSuccess(plan, contract, message));
  };
};

const savePlanContract = (contract: IEthPlan): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry!;
    const { tepuiEthereumClient, tokenMap } = state.ethereum!;
    const plan = state.planStateMap![contract.id].plan;
    const token = tokenMap![contract.token];
    const contractToken: IEthToken = {
      address: contract.token,
      decimals: token.decimals,
    };
    const planIds = await getPlanIds(plan);
    const planTransactionMap = await tepuiRegistry!.getPlanTransactionMap(
      planIds
    );
    const addressMap = await tepuiEthereumClient!.getAddressMap(
      planTransactionMap
    );
    const contractPlan = tepuiEthereumClient!.contractFromPlan(
      plan,
      contractToken,
      addressMap,
      contract.address
    );
    dispatch(contractSubmit(contract));
    const prettyAddress = displayAddress(contract.address!);
    try {
      await tepuiEthereumClient!.updatePlan(contractPlan);
    } catch (error) {
      const message = `Saving of contract ${plan.name} (${prettyAddress}) failed: ${error.message}`;
      dispatch(savePlanContractError(contract, message));
      return;
    }
    const message = `Contract ${plan.name} (${prettyAddress}) was saved successfully`;
    dispatch(savePlanContractSuccess(contractPlan, message));
  };
};

const withdrawPlan = (contract: IEthPlan): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiEthereumClient } = state.ethereum!;
    const plan = state.planStateMap![contract.id].plan;
    const { address } = contract;
    dispatch(contractSubmit(contract));
    let events: IEthBalanceEvent[];
    try {
      events = await tepuiEthereumClient!.withdrawPlan(address!);
    } catch (error) {
      const message = `Withdrawal from plan ${plan.name} failed: ${error.message}`;
      dispatch(withdrawPlanError(contract, message));
      return;
    }
    const message = `Plan ${plan.name} withdrawn successfully`;
    dispatch(withdrawPlanSuccess(contract, events, message));
  };
};

export type { PlanAction };
export { loadPlan, loadContract, loadAvailablePlans, loadOwnedPlans, savePlan };
export { deployPlanContract, dispatchContracts, isLoaded };
export { savePlanContract, withdrawPlan };
