import TepuiEthereumClient, { IToken } from "@tepui/eth-sdk";

export const displayAmount = (displayAmount: string, token: IToken) => {
  const number = parseFloat(displayAmount);
  const formatter = new Intl.NumberFormat("default", {
    minimumFractionDigits: 2,
    maximumFractionDigits: token.decimals,
  });
  return `${token.symbol} ${formatter.format(number)}`;
};

export const displayToken = (
  amount: string,
  token: IToken,
  tepuiEthereumClient: TepuiEthereumClient
) => {
  const result = tepuiEthereumClient.toDisplayAmount(amount, token);
  return displayAmount(result, token);
};
