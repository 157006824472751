import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { addTimeSpans, IPlan, toFeature } from "@tepui/core-sdk";
import { IEthSubscription } from "@tepui/eth-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IEthereum, TepuiState } from "../../redux/reducers/TepuiState";
import FiatAmount from "../common/FiatAmount";
import TokenAmount from "../common/TokenAmount";

const dateFormatter = Intl.DateTimeFormat("default", {
  day: "numeric",
  month: "short",
  year: "numeric",
  timeZone: "UTC",
});

interface IProps {
  subscription: IEthSubscription;
  plan: IPlan;
  ethereum: IEthereum | null;
}

const SubscriptionPayment = ({ ethereum, subscription, plan }: IProps) => {
  const [expiration, setExpiration] = useState("");
  const [payment, setPayment] = useState("");

  useEffect(() => {
    const init = async () => {
      const { tepuiEthereumClient } = ethereum ?? {};
      if (!tepuiEthereumClient) return;
      const { startDate } = subscription;
      if (!startDate) return;
      const timeSpans = await tepuiEthereumClient.getConsumedTimeSpans(
        subscription
      );
      if (!timeSpans) return;
      const expirationDate = addTimeSpans(startDate, timeSpans);
      const expiration = dateFormatter.format(expirationDate);
      setExpiration(expiration);

      const timeSpan = toFeature(subscription);
      if (!timeSpan) return;
      const { quantity, feature } = timeSpan;
      try {
        const tokenAmount = await tepuiEthereumClient.price(
          subscription.address,
          subscription.subscriber,
          feature,
          quantity.toString()
        );
        setPayment(tokenAmount);
      } catch {
        // Returned values aren't valid, did it run Out of Gas?
      }
    };
    init();
  });
  if (!expiration) return null;
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Typography variant="caption">Next Payment</Typography>
      </Grid>
      <Grid item>
        <Typography style={{ color: "#7397d1" }}>{expiration}</Typography>
      </Grid>
      <Grid item>
        <TokenAmount
          variant="h6"
          style={{ color: "#f07365" }}
          tokenAmount={payment}
          tokenSymbol={plan.token}
        />
      </Grid>
      <Grid item>
        <FiatAmount
          variant="caption"
          style={{ color: "#7397d1" }}
          tokenAmount={payment}
          tokenSymbol={plan.token}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
});

export default connect(mapStateToProps)(SubscriptionPayment);
