import React, { ReactNode } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

interface IProps {
  header?: string;
  children: ReactNode;
  width: number;
  height?: number;
}

const DashboardContainer = ({ header, height, width, children }: IProps) => (
  <Grid item>
    <Paper>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
        style={{ width: width, height: height || 300 }}
      >
        {header && (
          <Grid item>
            <Typography variant="h5">{header}</Typography>
          </Grid>
        )}
        <Grid
          item
          style={{ width: "inherit", height: height ? height - 50 : 250 }}
        >
          {children}
        </Grid>
      </Grid>
    </Paper>
  </Grid>
);

export default DashboardContainer;
