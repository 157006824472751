import { EthereumAction } from "../actions/ethereumActions";
import { PlanAction } from "../actions/planActions";
import { SubscriptionAction } from "../actions/subscriptionActions";
import { SubscribedMap } from "./TepuiState";

const subscribedSubscriptionsReducer = (
  state: SubscribedMap | null = null,
  action: SubscriptionAction | PlanAction | EthereumAction
): SubscribedMap | null => {
  switch (action.type) {
    case "LOAD_SUBSCRIPTIONS_SUCCESS":
      return action.subscribedMap!;
    case "SUBSCRIBE_SUCCESS": {
      const { address, subscriber } = action.subscription!;
      const prev = state![subscriber];
      if (!prev) return state;
      if (prev.includes(address)) return state;
      const addresses = [...prev, address];
      return { ...state!, [subscriber]: addresses };
    }
    case "CANCEL_SUBSCRIPTION_SUCCESS": {
      const { address, subscriber } = action.subscription!;
      const prev = state![subscriber];
      if (!prev) return state;
      const addresses = prev.filter((x) => x !== address);
      return { ...state!, [subscriber]: addresses };
    }
    case "LOAD_ETHEREUM_SUCCESS": {
      return null;
    }
    default:
      return state;
  }
};

export default subscribedSubscriptionsReducer;
