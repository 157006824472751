import Avatar from "@material-ui/core/Avatar";
import Tooltip from "@material-ui/core/Tooltip";
import makeBlockie from "ethereum-blockies-base64";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as clipboardActions from "../../redux/actions/clipboardActions";
import { IEthereum, TepuiState } from "../../redux/reducers/TepuiState";

const displayAddress = (addr: string) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

const blockieMapFromArray = (addresses: string[]) => {
  const initialBlockieMap: BlockieMap = {};
  return addresses.reduce((map, x) => {
    if (!map[x]) map[x] = makeBlockie(x);
    return map;
  }, initialBlockieMap);
};

type BlockieMap = { [address: string]: string };

interface IProps {
  accountAddress?: string;
  blockieMap?: BlockieMap;
  className?: string;
  ethereum: IEthereum | null;
  clipboardActions: typeof clipboardActions;
}

const AddressBlockie = ({
  accountAddress,
  blockieMap,
  className,
  ethereum,
  clipboardActions,
}: IProps) => {
  const address = accountAddress ?? ethereum?.address;
  if (!address) return null;
  const blockie = blockieMap?.[address] ?? makeBlockie(address);
  return (
    <Tooltip title={displayAddress(address)}>
      <Avatar
        src={blockie}
        className={className}
        onClick={(e) => {
          e.stopPropagation();
          clipboardActions.copyToClipboard(address);
        }}
      />
    </Tooltip>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clipboardActions: bindActionCreators(clipboardActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressBlockie);
export { blockieMapFromArray };
export type { BlockieMap };
