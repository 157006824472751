import TepuiRegistry from "@tepui/registry-sdk";
import { Auth } from "aws-amplify";
import { IRegistry } from "../reducers/TepuiState";
import { RegistryActionType, TepuiAction, TepuiThunk } from "./actionTypes";

interface RegistryAction extends TepuiAction {
  type: RegistryActionType;
  registry?: IRegistry | null;
}

const loadRegistrySubmit = (): RegistryAction => ({
  type: "LOAD_REGISTRY_SUBMIT",
});

const loadRegistrySuccess = (registry: IRegistry): RegistryAction => ({
  type: "LOAD_REGISTRY_SUCCESS",
  registry,
});

const loadRegistryError = (message: string): RegistryAction => ({
  type: "LOAD_REGISTRY_ERROR",
  message,
});

const authTokenResolver = async () => {
  const session = await Auth.currentSession()
    .then((x) => x)
    .catch(() => {});
  if (!session) return;
  const accessToken = session.getAccessToken();
  return accessToken.getJwtToken();
};

const loadRegistry = (): TepuiThunk => {
  return async (dispatch) => {
    const token = await authTokenResolver();
    if (!token) return;
    dispatch(loadRegistrySubmit());
    const tepuiRegistry = new TepuiRegistry(authTokenResolver);
    let addresses: string[];
    try {
      addresses = await tepuiRegistry.retrieveUserAddresses();
    } catch (error) {
      const message = `Unable to retrieve addresses from registry: ${error.message}`;
      dispatch(loadRegistryError(message));
      return;
    }
    const registry: IRegistry = { tepuiRegistry, addresses };
    dispatch(loadRegistrySuccess(registry));
  };
};

export type { RegistryAction };
export { loadRegistry };
