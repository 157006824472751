import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { IFeature, IPlan } from "@tepui/core-sdk";
import { TokenMetadataMap } from "eth-contract-metadata";
import React from "react";
import FeatureAccordion, { newPriceTier } from "./FeatureAccordion";
import { IErrors, IPriceTierErrors } from "./planEditTypes";

export const newFeature = (): IFeature => ({
  name: "",
  description: "",
  code: "",
  allocations: [],
  priceTiers: [newPriceTier()],
});

export const newFeatureError = (f: IFeature) => ({
  priceTiers: f.priceTiers.map(() => ({})),
});

interface IProps {
  plan: IPlan;
  errors: IErrors;
  setError: (name: string, errors: IPriceTierErrors[]) => void;
  setStateAndValidate: <T extends any>(name: keyof IPlan, value: T) => void;
  tokenMap?: TokenMetadataMap;
}

const FeatureAndPricingSection = ({
  plan,
  errors,
  setError,
  setStateAndValidate,
  tokenMap,
}: IProps) => {
  const handleFeaturesAdd = () => {
    const feature = newFeature();
    const featuresErrors = [...errors.features, newFeatureError(feature)];
    setError("features", featuresErrors);
    const features = [...plan.features, feature];
    setStateAndValidate("features", features);
  };

  return (
    <Grid container direction="column">
      <Grid container spacing={1}>
        {plan.features.map((feature, index) => (
          <Grid item key={`${plan.id}|${index}`} sm={6} md={3}>
            <FeatureAccordion
              feature={feature}
              index={index}
              plan={plan}
              errors={errors}
              setError={setError}
              setStateAndValidate={setStateAndValidate}
              tokenMap={tokenMap}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Button color="primary" onClick={handleFeaturesAdd}>
          Add Feature
        </Button>
      </Grid>
    </Grid>
  );
};

export default FeatureAndPricingSection;
