import React from "react";
import Typography from "@material-ui/core/Typography";

interface IProps {
  title: string;
}

const PageTitle = ({ title }: IProps) => (
  <Typography variant="h4" style={{ margin: "20px 0px" }}>
    {title}
  </Typography>
);

export default PageTitle;
