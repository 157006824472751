import { IToast, Severity } from "./TepuiState";
import { ToastAction } from "../actions/toastActions";
import { TepuiAction } from "../actions/actionTypes";

const getSeverity = (action: TepuiAction): Severity => {
  if (action.severity) return action.severity;
  const index = action.type.lastIndexOf("_");
  const suffix = action.type.substr(index + 1);
  switch (suffix) {
    case "SUCCESS":
      return "success";
    case "ERROR":
      return "error";
    case "WARNING":
      return "warning";
    default:
      return "info";
  }
};

const isHideToast = (action: TepuiAction): action is ToastAction => {
  return action.type === "HIDE_TOAST";
};

const log = (severity: Severity, message: string) : void => {
  switch (severity) {
    case "error":
      console.error(message);
      return;
    case "warning":
      console.warn(message);
      return;
  }
}

const toastReducer = (
  state: IToast[] = [],
  action: TepuiAction
): IToast[] => {
  if (isHideToast(action)) return state?.filter((x) => x.key !== action.key);
  const { message } = action;
  if (!message) return state;
  const severity = getSeverity(action);
  log(severity, message);
  const key = Date.now();
  const toast: IToast = { message, severity, key };
  return state ? [...state, toast] : [toast];
};

export default toastReducer;
