import { RegistryAction } from "../actions/registryActions";
import { IRegistry } from "./TepuiState";
import { EthereumAction } from "../actions/ethereumActions";

const registryReducer = (
  state: IRegistry | null = null,
  action: RegistryAction | EthereumAction
): IRegistry | null => {
  switch (action.type) {
    case "LOAD_REGISTRY_SUCCESS":
      return action.registry!;
    case "VERIFY_ADDRESS_SUCCESS":
      if (state!.addresses!.includes(action.address!)) return state;
      const addresses = [...state?.addresses!, action.address!];
      return { ...state!, addresses };
    default:
      return state;
  }
};

export default registryReducer;
