import { Badge } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React, { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as routerActions from "../../redux/actions/routerActions";
import * as subscriptionActions from "../../redux/actions/subscriptionActions";
import {
  ContractStateMap,
  IContractState,
  IEthereum,
  IPlanState,
  ISubscriptionState,
  PlanStateMap,
  SubscribedMap,
  SubscriptionStateMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import AddressBlockie from "../common/AddressBlockie";
import PageTitle from "../common/PageTitle";
import SummarySkeleton from "../common/SummarySkeleton";
import NoResultsFound from "../NoResultsFound";
import SubscriptionSummary from "./SubscriptionSummary";

interface IHeaderProps {
  subscriberAddress: string;
  actionable: boolean;
  count: number;
}

const SubscriberAddressHeader = ({
  subscriberAddress,
  actionable,
  count,
}: IHeaderProps) => (
  <Badge
    badgeContent={count}
    color={actionable ? "primary" : "error"}
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    overlap="circle"
  >
    <AddressBlockie accountAddress={subscriberAddress} />
  </Badge>
);

interface IClickableSummaryProps {
  subscriptionState?: ISubscriptionState;
  contractState?: IContractState;
  planState?: IPlanState;
  routerActions: typeof routerActions;
}

const ClickableSubscriptionSummary = ({
  subscriptionState,
  contractState,
  planState,
  routerActions,
}: IClickableSummaryProps) => {
  const { subscription } = subscriptionState ?? {};
  return (
    <Button
      style={{
        display: "block",
        width: "100%",
        textAlign: "inherit",
      }}
      disabled={subscription === undefined}
      onClick={() =>
        routerActions.pushRoute(
          `/subscriber/${subscription!.subscriber}/${subscription!.address}`
        )
      }
    >
      <SubscriptionSummary
        subscriptionState={subscriptionState}
        contractState={contractState}
        planState={planState}
      />
    </Button>
  );
};

interface IProps {
  ethereum: IEthereum | null;
  subscribedMap: SubscribedMap | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  subscriptionStateMap: SubscriptionStateMap | null;
  subscriptionActions: typeof subscriptionActions;
  routerActions: typeof routerActions;
}

const SubscriberContent = ({
  ethereum,
  subscribedMap,
  planStateMap,
  contractStateMap,
  subscriptionStateMap,
  subscriptionActions,
  routerActions,
}: IProps) => {
  const { address, status } = ethereum ?? {};

  useEffect(() => {
    if (subscribedMap) return;
    if (status !== "ready") return;
    const init = async () => {
      subscriptionActions.loadSubscribedSubscriptions();
    };
    init();
  }, [status, subscribedMap, subscriptionActions]);

  if (status !== "ready") return <h2>Connect to Ethereum to retrieve data</h2>;

  if (!subscribedMap)
    return (
      <Grid container spacing={3}>
        {Array.from(new Array(6)).map((_, i) => (
          <Grid item key={i}>
            <SummarySkeleton />
          </Grid>
        ))}
      </Grid>
    );
  const subscriptionMapList = Object.entries(subscribedMap);
  const subscribedCount = subscriptionMapList.reduce(
    (prev, [_, x]) => prev + x.length,
    0
  );
  if (subscribedCount === 0) return <NoResultsFound />;
  const anotherAddressExists = subscriptionMapList.some(
    ([subscriber, addresses]) => subscriber !== address && addresses.length > 0
  );
  return (
    <Grid container spacing={1} direction="column">
      {subscriptionMapList.map(([subscriber, addresses]) => {
        if (addresses.length === 0) return null;
        return (
          <Fragment key={subscriber}>
            {anotherAddressExists && (
              <Grid item>
                <SubscriberAddressHeader
                  subscriberAddress={subscriber}
                  actionable={subscriber === address}
                  count={addresses.length}
                />
              </Grid>
            )}
            <Grid item container spacing={1}>
              {addresses.map((x) => {
                const subscriptionState = subscriptionStateMap?.[x][subscriber];
                const contractState = contractStateMap?.[x];
                const { id } = contractState?.contract ?? {};
                const planState = id ? planStateMap?.[id] : undefined;
                return (
                  <Grid key={x} item sm={12} md={6}>
                    <ClickableSubscriptionSummary
                      subscriptionState={subscriptionState}
                      contractState={contractState}
                      planState={planState}
                      routerActions={routerActions}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
};

const SubscriberPage = (props: IProps) => {
  return (
    <Container maxWidth="xl" component="main">
      <PageTitle title="Subscriptions" />
      <SubscriberContent {...props} />
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  subscribedMap: state.subscribedMap,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
  subscriptionStateMap: state.subscriptionStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  routerActions: bindActionCreators(routerActions, dispatch),
  subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriberPage);
