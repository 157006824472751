import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import availablePlans from "./availablePlansReducer";
import config from "./configReducer";
import contractStateMap from "./contractStateMapReducer";
import ethereum from "./ethereumReducer";
import ownedPlans from "./ownedPlansReducer";
import planStateMap from "./planStateMapReducer";
import profileStateMap from "./profileStateMapReducer";
import registry from "./registryReducer";
import session from "./sessionReducer";
import subscribedMap from "./subscribedMapReducer";
import subscriptionStateMap from "./subscriptionStateMapReducer";
import { TepuiState } from "./TepuiState";
import toasts from "./toastReducer";

const createRootReducer = (history: History) =>
  combineReducers<TepuiState>({
    router: connectRouter(history),
    toasts,
    session,
    registry,
    ethereum,
    config,
    planStateMap,
    profileStateMap,
    contractStateMap,
    subscriptionStateMap,
    availablePlans,
    ownedPlans,
    subscribedMap,
  });

export default createRootReducer;
