import { BalanceEventType, IEthBalanceEvent, IEthPlan } from "@tepui/eth-sdk";
import { bnAdd, bnSub } from "../../utils/bigNumberHelpers";
import { EthereumAction } from "../actions/ethereumActions";
import { PlanAction } from "../actions/planActions";
import { SubscriptionAction } from "../actions/subscriptionActions";
import { updateMap } from "./map";
import { ContractStateMap, Status } from "./TepuiState";

type EventHandler = (
  prev: IEthPlan,
  account: string,
  amount: string
) => IEthPlan;

const allocate: EventHandler = (prev, account, amount) => {
  const { merchant } = prev;
  if (account !== merchant) return prev;
  const consumed = bnAdd(prev.consumed, amount);
  return { ...prev, consumed };
};

const consume: EventHandler = (prev, account, amount) => {
  const { merchant } = prev;
  if (account !== merchant) return prev;
  const consumed = bnSub(prev.consumed, amount);
  return { ...prev, consumed };
};

const fund: EventHandler = (prev, account, amount) => {
  const { merchant } = prev;
  const balance = bnAdd(prev.balance, amount);
  const result = { ...prev, balance };
  if (account !== merchant) return result;
  const consumed = bnAdd(prev.consumed, amount);
  return { ...result, consumed };
};

const withdraw: EventHandler = (prev, account, amount) => {
  const { merchant } = prev;
  const balance = bnSub(prev.balance, amount);
  const result = { ...prev, balance };
  if (account !== merchant) return result;
  const consumed = bnSub(prev.consumed, amount);
  return { ...result, consumed };
};

const handler: { [type in BalanceEventType]: EventHandler } = {
  Allocate: allocate,
  Consume: consume,
  Fund: fund,
  Withdraw: withdraw,
};

const processEvents = (
  state: ContractStateMap,
  events: IEthBalanceEvent[]
): ContractStateMap => {
  return events.reduce((state, x) => {
    const { contract: address, account, type, amount } = x;
    const prev = state[address]?.contract;
    if (!prev) return state;
    const contract = handler[type](prev, account, amount);
    return updateMap(state, address, { contract, status: "ready" });
  }, state);
};

const adjustEvents = (events: IEthBalanceEvent[], contract: string) => {
  const withdraws: IEthBalanceEvent[] = events!
    .filter((x) => x.type === "Fund" && x.contract !== contract)
    .map((x) => ({ ...x, contract, type: "Withdraw" }));
  const paid = withdraws.reduce((prev, x) => bnAdd(prev, x.amount), "0");
  return events!
    .map((x) =>
      x.type === "Consume" && x.contract === contract
        ? { ...x, amount: bnSub(x.amount, paid) }
        : x
    )
    .concat(withdraws);
};

const contractStateMapReducer = (
  state: ContractStateMap | null = null,
  action: PlanAction | SubscriptionAction | EthereumAction
): ContractStateMap | null => {
  switch (action.type) {
    case "LOAD_CONTRACT_SUBMIT":
    case "LOAD_CONTRACT_ERROR": {
      const { address } = action;
      const status: Status =
        action.type === "LOAD_CONTRACT_SUBMIT" ? "loading" : "failed";
      return updateMap(state ?? {}, address!, { status });
    }
    case "LOAD_CONTRACT_SUCCESS": {
      const { contract } = action;
      return updateMap(state!, contract!.address!, {
        contract,
        status: "ready",
      });
    }
    case "CONTRACT_SUBMIT":
    case "WITHDRAW_PLAN_ERROR":
    case "DEPLOY_PLANCONTRACT_SUCCESS":
    case "SAVE_PLANCONTRACT_ERROR":
    case "SAVE_PLANCONTRACT_SUCCESS": {
      const status: Status =
        action.type === "CONTRACT_SUBMIT" ? "busy" : "ready";
      const { contract } = action;
      return updateMap(state!, contract!.address!, {
        contract,
        status,
      });
    }
    case "WITHDRAW_PLAN_SUCCESS": {
      const { events } = action;
      const { address: contract } = action.contract!;
      if (!contract) return state;
      return processEvents(state!, events!);
    }
    case "SUBSCRIPTION_SUBMIT":
    case "SUBSCRIBE_ERROR":
    case "CONSUME_SUBSCRIPTION_ERROR":
    case "CANCEL_SUBSCRIPTION_ERROR": {
      const status: Status =
        action.type === "SUBSCRIPTION_SUBMIT" ? "busy" : "ready";
      const { subscription } = action;
      const { address } = subscription!;
      return updateMap(state!, address, { status });
    }
    case "SUBSCRIBE_SUCCESS":
    case "CONSUME_SUBSCRIPTION_SUCCESS": {
      const { events, subscription } = action;
      const { address } = subscription!;
      const adjustedEvents = adjustEvents(events!, address);
      return processEvents(state!, adjustedEvents);
    }
    case "CANCEL_SUBSCRIPTION_SUCCESS": {
      const { events } = action;
      return processEvents(state!, events!);
    }
    case "LOAD_ETHEREUM_SUCCESS": {
      return null;
    }
    default:
      return state;
  }
};

export default contractStateMapReducer;
