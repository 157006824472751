import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import web3Utils from "web3-utils";
import * as tokenUtils from "../../utils/token";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { TepuiState, IEthereum } from "../../redux/reducers/TepuiState";
import { IEthPlan, EventData, IToken } from "@tepui/eth-sdk";
import { IPlan } from "@tepui/core-sdk";
import { bnAdd } from "../../utils/bigNumberHelpers";

const displayDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString() + " " + date.toLocaleTimeString();
};

interface IProps {
  maxHeight: number;
  subscriber: string;
  contract: IEthPlan;
  plan: IPlan;
  ethereum: IEthereum | null;
}

const ReceiptTable = ({
  maxHeight,
  subscriber,
  contract,
  plan,
  ethereum,
}: IProps) => {
  type BlockTimestampMap = { [address: string]: number };
  type FeatureMap = { [hash: string]: string };

  const [consumptions, setConsumptions] = useState([] as EventData[]);
  const [blockTimestamps, setBlockTimestamps] = useState(
    {} as BlockTimestampMap
  );
  const [featureMap, setFeatureMap] = useState({} as FeatureMap);
  const [total, setTotal] = useState("0");
  const { tepuiEthereumClient, tokenMap } = ethereum ?? {};

  useEffect(() => {
    const featureMap = plan.features.reduce((map, x) => {
      const codeHash = web3Utils.soliditySha3Raw(x.code);
      return { ...map, [codeHash!]: x.name };
    }, {} as FeatureMap);
    setFeatureMap(featureMap);
  }, [plan.features]);

  useEffect(() => {
    if (!tepuiEthereumClient) return;
    if (!contract) return;
    let mounted = true;
    const init = async () => {
      const consumptions = await tepuiEthereumClient.getPastEvents(
        contract.address!,
        "Consume",
        subscriber
      );
      const blockTimestamps = await tepuiEthereumClient.getBlockTimestamps(
        consumptions
      );
      const total = consumptions.reduce(
        (prev, x) => bnAdd(prev, x.returnValues.amount),
        "0"
      );
      if (!mounted) return;
      setConsumptions(consumptions);
      setBlockTimestamps(blockTimestamps);
      setTotal(total);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [tepuiEthereumClient, subscriber, contract]);

  if (!contract || !tepuiEthereumClient) return null;
  const defaultToken: IToken = { name: "???", symbol: "???", decimals: 6 };
  const token = tokenMap?.[contract.token] ?? defaultToken;
  return (
    <TableContainer style={{ maxHeight }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date and Time</TableCell>
            <TableCell>Feature</TableCell>
            <TableCell align="right">Quantity</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consumptions.map((x) => (
            <TableRow key={x.transactionHash}>
              <TableCell>{displayDate(blockTimestamps[x.blockHash])}</TableCell>
              <TableCell>{featureMap[x.returnValues.feature]}</TableCell>
              <TableCell align="right">{x.returnValues.quantity}</TableCell>
              <TableCell align="right">
                {tokenUtils.displayToken(
                  x.returnValues.amount,
                  token,
                  tepuiEthereumClient
                )}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell align="right">
              <Typography variant="h6">Total</Typography>
            </TableCell>
            <TableCell align="right">
              <Typography variant="h6">
                {tokenUtils.displayToken(total, token, tepuiEthereumClient)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
});

export default connect(mapStateToProps)(ReceiptTable);
