import { FormHelperText, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { IConnectedPlan, IPlan, PlanConnectionType } from "@tepui/core-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { IRegistry, TepuiState } from "../../redux/reducers/TepuiState";
import { IErrors } from "./planEditTypes";

const uuidRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;

const displayUuid = (id: string) => {
  if (!id || !isUuid(id)) return "";
  return `${id.substring(0, 6)}...${id.substring(id.length - 4)}`;
};

const isUuid = (id: string) => !id || uuidRegex.test(id);

const fieldValidators: { [index: string]: (value: string) => boolean } = {
  planId: isUuid,
};

interface IProps {
  connectedPlan: IConnectedPlan;
  index: number;
  plan: IPlan;
  errors: IErrors;
  setError: (name: string, errors: boolean[]) => void;
  setStateAndValidate: <T extends any>(name: keyof IPlan, value: T) => void;
  registry: IRegistry | null;
}

const ConnectedPlanAccordion = ({
  connectedPlan,
  index,
  plan,
  errors,
  setError,
  setStateAndValidate,
  registry,
}: IProps) => {
  const [expanded, setExpanded] = useState(false);
  const [name, setName] = useState("");

  const getPlanName = async (id: string): Promise<boolean> => {
    if (!id) return false;
    const { tepuiRegistry } = registry ?? {};
    if (!tepuiRegistry) return true;
    let planName: string | undefined;
    try {
      const plan = await tepuiRegistry.retrievePlan(id);
      planName = plan?.name;
    } catch (error) {}
    const error = !Boolean(planName);
    if (!planName) planName = "Invalid Plan!";
    setName(planName);
    return error;
  };

  useEffect(() => {
    getPlanName(connectedPlan.plan);
  });

  const setOptionalAddOnError = (
    optionalAddOnIndex: number,
    value: boolean
  ) => {
    const connectedPlans = [...errors.connectedPlans];
    connectedPlans[optionalAddOnIndex] = value;
    setError("connectedPlans", connectedPlans);
  };

  const handleAddOnChange = (index: number) => {
    const x = async (
      event: React.ChangeEvent<{ name?: string; value: unknown }>
    ) => {
      const { name } = event.target;
      const value = event.target.value as string;
      const connectedPlans = [...plan.connectedPlans];
      connectedPlans[index] =
        name === "connectionType"
          ? {
              ...connectedPlans[index],
              connectionType: value as PlanConnectionType,
            }
          : { ...connectedPlans[index], plan: value };
      const fieldValidator = fieldValidators[name!];
      if (fieldValidator) {
        let connectedPlanError = value ? !fieldValidator(value) : false;
        if (!connectedPlanError && value && name === "planId") {
          connectedPlanError = await getPlanName(value);
        }
        setOptionalAddOnError(index, connectedPlanError);
      }
      setStateAndValidate("connectedPlans", connectedPlans);
    };
    return x;
  };
  const handleAddOnRemove = (index: number) => () => {
    const connectedPlanErrors = errors.connectedPlans.filter(
      (_, i) => i !== index
    );
    setError("connectedPlans", connectedPlanErrors);
    const connectedPlans = plan.connectedPlans.filter((_, i) => i !== index);
    setStateAndValidate("connectedPlans", connectedPlans);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        setExpanded((x) => !x);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={8}>
            <Typography style={name ? undefined : { opacity: 0.38 }}>
              {name || "✎ New Connection"}
            </Typography>
            <FormHelperText>{displayUuid(connectedPlan.plan)}</FormHelperText>
          </Grid>
          <Grid item xs={4}>
            <Button color="secondary" onClick={handleAddOnRemove(index)}>
              Remove
            </Button>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column">
          <Select
            name="connectionType"
            value={connectedPlan.connectionType}
            onChange={handleAddOnChange(index)}
          >
            <MenuItem value={"addon"}>Add On</MenuItem>
            <MenuItem value={"bundle"}>Bundle</MenuItem>
            <MenuItem value={"wholesale"}>Wholesale</MenuItem>
          </Select>
          <TextField
            required
            fullWidth
            margin="dense"
            name="planId"
            placeholder="ffffffff-ffff-ffff-ffff-ffffffffffff"
            value={connectedPlan.plan}
            onChange={handleAddOnChange(index)}
            label={"Plan Id"}
            helperText="The plan identifier"
            error={errors.connectedPlans[index]}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  registry: state.registry,
});

export default connect(mapStateToProps)(ConnectedPlanAccordion);
