import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import ProviderButton from "./ProviderButton";
import TepuiMenu from "./TepuiMenu";
import UserAvatar from "./UserAvatar";
import VerifyButton from "./VerifyButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const TepuiBar = () => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" elevation={0} className={classes.appBar}>
      <Toolbar style={{ flexWrap: "wrap" }}>
        <div style={{ flexGrow: 1 }}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Avatar
                style={{ backgroundColor: "#e7af6f", padding: 5 }}
                src={process.env.PUBLIC_URL + "/logo192.png"}
              />
            </Grid>
            <Grid item>
              <ProviderButton />
            </Grid>
            <Grid item>{/* intentionally blank */}</Grid>
          </Grid>
        </div>
        <VerifyButton />
        <UserAvatar style={{ padding: 8 }} />
        <TepuiMenu />
      </Toolbar>
    </AppBar>
  );
};

export default TepuiBar;
