import React from "react";
import { ResponsiveBar, IndexByFunc } from "@nivo/bar";
import { LegendProps } from "@nivo/legends";

const legendProps: LegendProps = {
  anchor: "bottom-right",
  direction: "column",
  effects: [
    {
      on: "hover",
      style: {
        itemOpacity: 1,
      },
    },
  ],
  itemDirection: "left-to-right",
  itemHeight: 20,
  itemOpacity: 0.85,
  itemsSpacing: 2,
  itemWidth: 100,
  justify: false,
  symbolSize: 20,
  translateX: 112,
  translateY: 0,
};

const legends: Array<{ dataFrom: "indexes" | "keys" } & LegendProps> = [
  { ...legendProps, dataFrom: "keys" },
];

interface ILegend {
  x: string;
  y: string;
}

interface IProps {
  data: object[];
  keys: string[];
  indexBy: string | IndexByFunc;
  legend: ILegend;
}

const BarChart = ({ data, keys, indexBy, legend }: IProps) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={{ top: 20, right: keys.length > 1 ? 90 : 20, bottom: 40, left: 60 }}
    padding={0.3}
    colors={{ scheme: "nivo" }}
    borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legend.x,
      legendPosition: "middle",
      legendOffset: 32,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legend.y,
      legendPosition: "middle",
      legendOffset: -45,
    }}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    legends={keys.length > 1 ? legends : undefined}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);

export default BarChart;
