import { IProfile } from "@tepui/core-sdk";
import gravatar from "gravatar";
import { ProfileAction } from "../actions/profileActions";
import { SessionAction } from "../actions/sessionActions";
import { updateMap } from "./map";
import { ProfileStateMap, Status } from "./TepuiState";

type IAttributes = { [key: string]: string };

const retrievePicture = ({ picture, email }: IAttributes): string => {
  if (!picture) return gravatar.url(email, { protocol: "https", d: "retro" });
  let json;
  try {
    json = JSON.parse(picture);
  } catch {}
  return json?.data?.url ?? picture;
};

const retrieveProfile = (attributes: IAttributes): IProfile => {
  const { name, given_name, family_name, email } = attributes;
  const picture = retrievePicture(attributes);
  return {
    name: name || given_name || email,
    firstName: given_name,
    lastName: family_name,
    email,
    picture,
  };
};

const profileStateMapReducer = (
  state: ProfileStateMap | null = null,
  action: ProfileAction | SessionAction
): ProfileStateMap | null => {
  switch (action.type) {
    case "LOAD_PROFILE_SUBMIT":
    case "LOAD_PROFILE_ERROR": {
      const { sub } = action;
      const status: Status =
        action.type === "LOAD_PROFILE_SUBMIT" ? "loading" : "failed";
      return updateMap(state ?? {}, sub, { status });
    }
    case "LOAD_PROFILE_SUCCESS": {
      const { sub, profile } = action;
      return updateMap(state!, sub, { profile, status: "ready" });
    }
    case "SESSION_LOAD_SUCCESS": {
      const { session } = action;
      const { payload } = session!.getIdToken();
      const { sub } = payload;
      const profile = retrieveProfile(payload);
      return updateMap(state ?? {}, sub, { profile, status: "ready" });
    }
    default:
      return state;
  }
};

export default profileStateMapReducer;
