import React from "react";
import { connect } from "react-redux";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import { IEthereum, TepuiState } from "../../redux/reducers/TepuiState";
import { IToken } from "@tepui/eth-sdk";

const formatter = new Intl.NumberFormat();
const displayToken = (str: string, token: IToken) => {
  const number = parseFloat(str);
  return `${token.symbol} ${formatter.format(number)}`;
};

interface IProps extends TypographyProps {
  tokenAmount: string;
  tokenSymbol?: string;
  tokenAddress?: string;
  ethereum: IEthereum | null;
}

const TokenAmount = ({
  variant,
  style,
  tokenAmount,
  tokenSymbol,
  tokenAddress,
  ethereum,
}: IProps) => {
  const { tokenMap, tepuiEthereumClient } = ethereum ?? {};
  if (!tokenMap || !tepuiEthereumClient) return null;

  const token = tokenAddress
    ? tokenMap[tokenAddress]
    : Object.values(tokenMap).find((x) => x.symbol === tokenSymbol);
  if (!token) return null;
  const amount = tepuiEthereumClient.toDisplayAmount(tokenAmount, token);
  return (
    <Typography variant={variant} style={style}>
      {displayToken(amount, token)}
    </Typography>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
});

export default connect(mapStateToProps)(TokenAmount);
