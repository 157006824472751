import { IConfig } from "./TepuiState";
import { ConfigAction } from "../actions/configActions";

const configReducer = (
  state : IConfig | null = null,
  action: ConfigAction
): IConfig | null => {
  switch (action.type) {
    case "LOAD_CONFIG_SUCCESS":
      return action.config!;
    default:
      return state;
  }
};

export default configReducer;
