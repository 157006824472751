import React from "react";
import { ResponsiveLine, Serie } from "@nivo/line";

interface IProps {
  data: Serie[];
}

const TinyLineChart = ({ data }: IProps) => (
  <ResponsiveLine
    data={data}
    margin={{
      top: 5,
      right: 15,
      bottom: 20,
      left: 15,
    }}
    colors={{ scheme: "nivo" }}
    enableGridX={false}
    enableGridY={false}
    axisLeft={null}
    axisBottom={{ orient: "top" }}
    enableSlices="x"
  />
);

export default TinyLineChart;
