import { TepuiThunk, TepuiAction, ToastActionType } from "./actionTypes";
import { Severity } from "../reducers/TepuiState";

const options = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

const dateTimeFormatter = Intl.DateTimeFormat("default", options);

interface ToastAction extends TepuiAction {
  type: ToastActionType;
  key?: number;
}

const hideToast = (key: number): TepuiThunk => {
  return async (dispatch) => {
    const action: ToastAction = { type: "HIDE_TOAST", key };
    dispatch(action);
  };
};

const testToast = (): TepuiThunk => {
  return async (dispatch) => {
    const randomNumber = (x: number) => Math.floor(Math.random() * x);
    const randomArrayValue = (x: Severity[]) => x[randomNumber(x.length)];
    const severities: Severity[] = ["success", "error", "warning", "info"];
    const message = `Toast sent on ${dateTimeFormatter.format(Date.now())}`;
    const testToast: ToastAction = {
      type: "TEST_TOAST",
      severity: randomArrayValue(severities),
      message,
    };
    dispatch(testToast);
  };
};

export type { ToastAction };
export { hideToast, testToast };
