import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as toastActions from "../../redux/actions/toastActions";
import { IToast, TepuiState } from "../../redux/reducers/TepuiState";

interface IProps {
  toasts: IToast[];
  toastActions: typeof toastActions;
}
const Toasts = ({ toasts, toastActions }: IProps) => (
  <>
    {toasts.map((toast) => (
      <Snackbar
        key={toast.key}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={true}
        autoHideDuration={6000}
        onClose={() => toastActions.hideToast(toast.key)}
      >
        <Alert
          severity={toast.severity}
          onClose={() => toastActions.hideToast(toast.key)}
          variant="filled"
        >
          {toast.message}
        </Alert>
      </Snackbar>
    ))}
  </>
);

const mapStateToProps = (state: TepuiState) => ({
  toasts: state.toasts,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toastActions: bindActionCreators(toastActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toasts);
