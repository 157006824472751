import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { TepuiState, IEthereum } from "../../redux/reducers/TepuiState";
import { IToken, IEthPlan, IEthSubscription } from "@tepui/eth-sdk";
import { TepuiThunk } from "../../redux/actions/actionTypes";
import { IPlan, cycleFeatures } from "@tepui/core-sdk";

interface IProps {
  subscription?: IEthSubscription;
  contract?: IEthPlan;
  plan?: IPlan;
  show: boolean;
  onHide: () => void;
  consumeSubscription: (
    subscription: IEthSubscription,
    feature: string,
    quantity: string,
    bucket?: string
  ) => TepuiThunk;
  ethereum: IEthereum | null;
}

const ConsumeModal = ({
  subscription,
  contract,
  plan,
  show,
  onHide,
  consumeSubscription,
  ethereum,
}: IProps) => {
  const tokenMap = ethereum?.tokenMap;
  const defaultToken: IToken = { name: "???", symbol: "???", decimals: 6 };
  const token = (contract && tokenMap?.[contract.token]) || defaultToken;
  const [bucket, setBucket] = useState(undefined as string | undefined);
  const [consumption, setConsumption] = useState({
    feature: "",
    quantity: "",
  });
  const [amount, setAmount] = useState("0");

  const validQuantity = (quantity: string) => /^\d+$/.test(quantity);

  useEffect(() => {
    if (!subscription) return setBucket(undefined);
    const { tepuiEthereumClient } = ethereum ?? {};
    if (!tepuiEthereumClient) return setBucket(undefined);
    let mounted = true;

    const setBucketForToday = async () => {
      const today = new Date();
      const period = await tepuiEthereumClient.getSubscriptionPeriodForDate(
        subscription,
        today
      );
      if (!mounted) return;
      if (!period) return;
      const { feature, startDate } = period;
      const isoDate = new Date(startDate).toISOString().split("T")[0];
      const bucket = `${feature}|${isoDate}`;
      setBucket(bucket);
    };
    setBucketForToday();
    return () => {
      mounted = false;
    };
  }, [ethereum, subscription]);

  useEffect(() => {
    if (!validQuantity(consumption.quantity)) return setAmount("0");
    if (!subscription) return setAmount("0");
    const { address, subscriber } = subscription;
    const { tepuiEthereumClient } = ethereum ?? {};
    if (!tepuiEthereumClient) return setAmount("0");
    const { feature, quantity } = consumption;
    const featureBucket = cycleFeatures.includes(feature) ? undefined : bucket;
    let mounted = true;

    const calculatePrice = async () => {
      const tokenAmount = await tepuiEthereumClient.price(
        address!,
        subscriber,
        feature,
        quantity,
        featureBucket
      );
      const amount = tepuiEthereumClient.toDisplayAmount(tokenAmount, token);
      if (!mounted) return;
      return setAmount(amount);
    };
    calculatePrice();
    return () => {
      mounted = false;
    };
  }, [ethereum, subscription, token, consumption, bucket]);

  const handleSave = async () => {
    const { feature, quantity } = consumption;
    const featureBucket = cycleFeatures.includes(feature) ? undefined : bucket;
    consumeSubscription(subscription!, feature, quantity, featureBucket);
    onHide();
  };

  const handleChange = async (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const { name, value } = event.target;
    setConsumption((x) => ({ ...x, [name!]: value }));
  };

  const featureDescription = () => {
    const feature = plan?.features.find((x) => x.code === consumption.feature);
    return feature?.description ?? "";
  };

  if (!contract) return null;
  return (
    <Dialog open={show} onClose={onHide}>
      <DialogTitle>Consume Feature</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="feature-label">Choose Feature</InputLabel>
          <Select
            margin="dense"
            labelId="feature-label"
            name="feature"
            value={consumption.feature}
            onChange={handleChange}
            label="Feature to Consume"
            fullWidth
          >
            {plan?.features.map((feature) => (
              <MenuItem key={feature.code} value={feature.code}>
                {feature.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{featureDescription()}</FormHelperText>
        </FormControl>
        <TextField
          margin="dense"
          name="quantity"
          value={consumption.quantity}
          onChange={handleChange}
          label="Quantity to Consume"
          fullWidth
          helperText={`Amount: ${token && token.symbol} ${amount}`}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onHide()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          disabled={!validQuantity(consumption.quantity)}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
});

export default connect(mapStateToProps)(ConsumeModal);
