import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Skeleton from "@material-ui/lab/Skeleton";
import { IPlan } from "@tepui/core-sdk";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import web3Utils from "web3-utils";
import * as planActions from "../../redux/actions/planActions";
import * as routerActions from "../../redux/actions/routerActions";
import {
  IEthereum,
  PlanStateMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import PageTitle from "../common/PageTitle";
import SelectToken from "../common/SelectToken";
import PlanCard from "../subscriber/PlanCard";
import ConnectedPlanSection from "./ConnectedPlanSection";
import FeatureAndPricingSection, {
  newFeature,
  newFeatureError,
} from "./FeatureAndPricingSection";
import OperatorsSection from "./OperatorsSection";
import { clone, IErrors } from "./planEditTypes";

type Partial<T> = { [P in keyof T]?: T[P] };
const initPlan = (plan?: Partial<IPlan>, sub?: string): IPlan => {
  if (!plan) plan = {};
  if (!plan.name) plan.name = "";
  if (!plan.description) plan.description = "";
  if (!plan.ownerId) plan.ownerId = sub;
  if (!plan.imageUrl) plan.imageUrl = "";
  if (!plan.features) plan.features = [newFeature()];
  if (!plan.connectedPlans) plan.connectedPlans = [];
  if (!plan.operators) plan.operators = [];
  return plan as IPlan;
};

const initErrors = (plan: IPlan): IErrors => ({
  merchant: false,
  imageUrl: false,
  features: plan.features.map(newFeatureError),
  connectedPlans: plan.connectedPlans.map((_) => false),
  operators: plan.operators.map((_) => false),
});

const isUrl = (url: string) =>
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/.test(
    url
  );
const isAddress = (address: string) => !address || web3Utils.isAddress(address);

const fieldValidators: {
  [key in keyof Partial<IPlan>]: (value: string) => boolean;
} = {
  imageUrl: isUrl,
  merchant: isAddress,
};

interface IParams {
  planId: string;
}

interface IProps {
  session: CognitoUserSession | null;
  ethereum: IEthereum | null;
  planStateMap: PlanStateMap | null;
  planActions: typeof planActions;
  routerActions: typeof routerActions;
}

const PlanEditPage = (props: IProps) => {
  const { planId } = useParams<IParams>();
  const { session, ethereum, planStateMap } = props;
  const { planActions, routerActions } = props;
  const { tokenMap } = ethereum ?? {};
  const { payload } = session?.getIdToken() ?? {};
  const { sub } = payload ?? {};
  const planState = planStateMap?.[planId];
  const status = planState?.status ?? "ready";
  const [plan, setPlan] = useState(initPlan(undefined, sub));
  const [errors, setErrors] = useState(initErrors(plan));

  useEffect(() => {
    if (planId === "0") return;
    if (planState) return;
    if (ethereum?.status !== "ready") return;
    const init = async () => {
      planActions.loadPlan(planId);
    };
    init();
  }, [planId, planState, ethereum, planActions]);

  useEffect(() => {
    const { plan, status } = planState ?? {};
    if (!plan || status !== "ready") return;
    setPlan(clone(plan));
    setErrors(initErrors(plan));
  }, [planState]);

  if (ethereum?.status !== "ready")
    return <h2>Connect to Ethereum to retrieve data</h2>;

  const isNewPlan = () => !plan.id;

  const requiredPlanFields = {
    // true: required on plan create and update, false: only required on create
    name: true,
    merchant: true,
  };

  const requiredFeatureFields = {
    code: true,
    name: true,
    description: true,
  };

  const requiredTierFields = {
    price: true,
  };

  const objectIsMissingRequiredFields = <T extends { [index: string]: any }>(
    requiredFields: { [name in keyof Partial<T>]: boolean },
    o: T
  ) => {
    const missingRequiredFields = Object.entries(requiredFields).filter(
      ([n, v]) => (v || isNewPlan()) && o[n] === ""
    );
    return missingRequiredFields.length > 0;
  };

  const objectHasErrors = <T extends any>(value: T): boolean => {
    if (typeof value === "boolean") return value;
    const o = value as {};
    return Object.values(o).reduce(
      (result: boolean, x) => result || objectHasErrors(x),
      false
    );
  };

  const formHasErrors = () => {
    const fieldsHaveErrors = objectHasErrors(errors);
    if (fieldsHaveErrors) return true;
    const planMissingFields = objectIsMissingRequiredFields(
      requiredPlanFields,
      plan
    );
    if (planMissingFields) return true;
    const featureMissingFields = plan.features.reduce(
      (prev, f) =>
        prev || objectIsMissingRequiredFields(requiredFeatureFields, f),
      false
    );
    if (featureMissingFields) return true;
    const tierMissingFields = plan.features
      .flatMap((f) => f.priceTiers)
      .reduce(
        (prev, t) =>
          prev || objectIsMissingRequiredFields(requiredTierFields, t),
        false
      );
    return tierMissingFields;
  };

  const setError = <T extends any>(name: string, value: T) =>
    setErrors({ ...errors, [name]: value });

  const setStateAndValidate = <T extends any>(name: keyof IPlan, value: T) => {
    setPlan((x) => ({ ...x, [name]: value }));
    const fieldValidator = fieldValidators[name];
    if (!fieldValidator) return;
    if (!value) {
      setError(name, false); // don't validate empty fields
      return;
    }
    const valid = fieldValidator(value as string);
    setError(name, !valid);
  };

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = event.target;
    setStateAndValidate(name as keyof Partial<IPlan>, value as string);
  };

  const handleCancel = async () => {
    const route = isNewPlan() ? "/merchant" : `/merchant/${plan.id}`;
    routerActions.pushRoute(route);
  };

  const handleSave = async () => {
    planActions.savePlan(plan);
    routerActions.pushRoute(`/merchant/${plan.id || ""}`);
  };

  return (
    <Container>
      {status === "loading" ? (
        <Box height={80} display="flex" alignContent="center">
          <Skeleton width={200} />
        </Box>
      ) : (
        <PageTitle title={`${isNewPlan() ? "Add" : "Edit"} Plan`} />
      )}
      <Grid container justify="center" alignItems="center" spacing={4}>
        <Grid item sm>
          <TextField
            autoFocus
            required
            name="name"
            value={plan.name || ""}
            onChange={handleChange}
            label="Name"
            fullWidth
          />
          <TextField
            name="description"
            value={plan.description}
            onChange={handleChange}
            label="Description"
            fullWidth
            multiline
          />
          <TextField
            name="imageUrl"
            value={plan.imageUrl}
            onChange={handleChange}
            label="Image URL"
            fullWidth
            error={errors.imageUrl}
          />
          <TextField
            required
            name="merchant"
            value={plan.merchant || ""}
            onChange={handleChange}
            label="Merchant Address"
            helperText="The Ethereum address that will receive funds from this plan"
            fullWidth
            error={errors.merchant}
          />
          <SelectToken
            token={plan.token}
            tokenMap={tokenMap}
            disabled={!isNewPlan()}
            onChange={handleChange}
          />
        </Grid>
        <Grid item>
          <PlanCard planState={{ status: "ready", plan, addresses: [] }} />
        </Grid>
      </Grid>
      <h2>Features and Pricing</h2>
      <FeatureAndPricingSection
        plan={plan}
        errors={errors}
        setError={setError}
        setStateAndValidate={setStateAndValidate}
        tokenMap={tokenMap}
      />
      <h2>Connected Plans</h2>
      <ConnectedPlanSection
        plan={plan}
        errors={errors}
        setError={setError}
        setStateAndValidate={setStateAndValidate}
      />
      <h2>Operators</h2>
      <OperatorsSection
        plan={plan}
        errors={errors}
        setError={setError}
        setStateAndValidate={setStateAndValidate}
      />
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={formHasErrors()}>
          Submit
        </Button>
      </DialogActions>
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  session: state.session,
  ethereum: state.ethereum,
  planStateMap: state.planStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  routerActions: bindActionCreators(routerActions, dispatch),
  planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanEditPage);
