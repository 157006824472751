import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Skeleton from "@material-ui/lab/Skeleton";
import { EventData, EventType, IEthPlan, IToken } from "@tepui/eth-sdk";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import web3Utils from "web3-utils";
import * as planActions from "../../redux/actions/planActions";
import {
  ContractStateMap,
  IEthereum,
  PlanStateMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { bnAdd } from "../../utils/bigNumberHelpers";
import AddressChip from "../common/AddressChip";
import BarChart from "../common/BarChart";
import DashboardContainer from "../common/DashboardContainer";
import GridAmount from "../common/GridAmount";
import LineChart from "../common/LineChart";
import PageTitle from "../common/PageTitle";
import PieChart from "../common/PieChart";
import PageNotFound from "../PageNotFound";
import ConsumptionTable from "./ConsumptionTable";
import PlanActions from "./PlanActions";
import SubscriptionTable from "./SubscriptionTable";

const days = (value: number) => value * 60 * 60 * 24;

const displayMonthAndYear = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString("default", { month: "short", year: "numeric" });
};

const displayDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString("default", { day: "numeric" });
};

const contractSum = (
  contracts: IEthPlan[] | undefined,
  fn: (contract: IEthPlan) => string
) => {
  if (!contracts) return "0";
  const values = contracts.map(fn);
  return values.reduce((prev, x) => bnAdd(prev, x), "0");
};

interface IParams {
  planId: string;
}

interface IProps {
  ethereum: IEthereum | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  ownedPlans: string[] | null;
  planActions: typeof planActions;
}

type BlockTimestamps = { [index: string]: number };
type FeatureMap = { [hash: string]: string };

const PlanPage = (props: IProps) => {
  const { planStateMap, contractStateMap } = props;
  const { ethereum, planActions } = props;
  const unknownToken: IToken = { name: "???", symbol: "???", decimals: 6 };
  const [token, setToken] = useState(unknownToken);
  const [events, setEvents] = useState([] as EventData[]);
  const [featureMap, setFeatureMap] = useState({} as FeatureMap);
  const [blockTimestamps, setBlockTimestamps] = useState({} as BlockTimestamps);

  const { planId } = useParams<IParams>();
  const planState = planStateMap?.[planId];

  const { tepuiEthereumClient, tokenMap } = ethereum ?? {};

  useEffect(() => {
    if (planState) return;
    if (ethereum?.status !== "ready") return;
    const init = async () => {
      planActions.loadPlan(planId);
    };
    init();
  }, [planId, planState, ethereum, planActions]);

  const flatMapResolve = async <T extends unknown>(
    addresses: string[],
    f: (address: string) => Promise<T[]>
  ) => {
    const promises = addresses.map(f);
    const events = await Promise.all(promises);
    return events.flat();
  };

  useEffect(() => {
    if (!tepuiEthereumClient || !tokenMap) return;
    const { plan, addresses } = planState ?? {};
    if (!plan || !addresses) return;
    const featureMap = plan.features.reduce((map, x) => {
      const codeHash = web3Utils.soliditySha3Raw(x.code);
      return { ...map, [codeHash!]: x.name };
    }, {});
    const token = Object.values(tokenMap).find((x) => x.symbol === plan.token);
    if (token) setToken(token);
    if (!addresses) return;
    let mounted = true;
    const init = async () => {
      const getPastEvents = (x: string) =>
        tepuiEthereumClient.getPastEvents(x, "allEvents");
      const events = await flatMapResolve(addresses, getPastEvents);
      const blockTimestamps = await tepuiEthereumClient.getBlockTimestamps(
        events
      );
      if (!mounted) return;
      setEvents(events);
      setFeatureMap(featureMap);
      setBlockTimestamps(blockTimestamps);
    };
    init();
    return () => {
      mounted = false;
    };
  }, [tepuiEthereumClient, tokenMap, planState]);

  if (ethereum?.status !== "ready")
    return <h2>Connect to Ethereum to retrieve data</h2>;
  const { plan, addresses, status } = planState ?? {};
  if (status === "failed") return <PageNotFound />;
  const contracts =
    addresses && contractStateMap
      ? addresses.reduce((prev, x) => {
          const { contract } = contractStateMap[x] ?? {};
          if (contract) prev.push(contract);
          return prev;
        }, [] as IEthPlan[])
      : undefined;
  const now = new Date().getSeconds();
  const lastYear = now - days(365);
  const lastMonth = now - days(30);
  const consumptions = events.filter((x) => x.event === "Consume");
  const subscriptions = events.filter((x) => x.event === "Subscribe");

  interface IEventTypeSummary {
    id: string;
    label: string;
    value: number;
    [key: string]: string | number;
  }

  const eventCount = (dayFilter: number) =>
    events.reduce((prev, x) => {
      const timestamp = blockTimestamps[x.blockHash];
      if (timestamp < dayFilter) return prev;
      const event = prev.find((y) => y.id === x.event);
      if (!event) {
        const item: IEventTypeSummary = {
          id: x.event,
          label: x.event,
          value: 1,
        };
        prev.push(item);
        return prev;
      }
      event.value++;
      return prev;
    }, [] as IEventTypeSummary[]);

  const totalConsumption = (dayFilter: number) =>
    consumptions.reduce((prev, x) => {
      const timestamp = blockTimestamps[x.blockHash];
      if (!timestamp) return prev;
      if (timestamp < dayFilter) return prev;
      const feature = featureMap[x.returnValues.feature];
      const displayAmount = tepuiEthereumClient!.toDisplayAmount(
        x.returnValues.amount,
        token
      );
      const amount = Number(displayAmount);
      const item = prev.find((y) => y.id === feature);
      if (!item)
        return [...prev, { id: feature, label: feature, value: amount }];
      item.value += amount;
      return prev;
    }, [] as IEventTypeSummary[]);

  interface TrendItem {
    x: string;
    y: number;
  }

  const subscriptionTrend = (
    dayFilter: number,
    display: (timestamp: number) => string
  ) =>
    subscriptions.reduce((prev, x) => {
      const timestamp = blockTimestamps[x.blockHash];
      if (timestamp < dayFilter) return prev;
      const displayTimestamp = display(timestamp);
      const prevDisplayTimestamp = prev.find((x) => x.x === displayTimestamp);
      if (!prevDisplayTimestamp)
        return [...prev, { x: displayTimestamp, y: 1 }];
      prevDisplayTimestamp.y++;
      return prev;
    }, [] as TrendItem[]);

  interface IConsumptionTrendItem {
    displayTimestamp: string;
    [feature: string]: string | number;
  }

  const consumptionTrend = (
    dayFilter: number,
    display: (timestamp: number) => string
  ) =>
    consumptions.reduce((prev, x) => {
      const timestamp = blockTimestamps[x.blockHash];
      if (timestamp < dayFilter) return prev;
      const displayTimestamp = display(timestamp);
      const feature = featureMap[x.returnValues.feature];
      const displayAmount = tepuiEthereumClient!.toDisplayAmount(
        x.returnValues.amount,
        token
      );
      const amount = Number(displayAmount);
      const prevDisplayTimestamp = prev.find(
        (x) => x.displayTimestamp === displayTimestamp
      );
      if (!prevDisplayTimestamp) {
        const item: IConsumptionTrendItem = {
          displayTimestamp,
          [feature]: amount,
        };
        prev.push(item);
        return prev;
      }
      const prevAmount = (prevDisplayTimestamp[feature] as number) ?? 0;
      prevDisplayTimestamp[feature] = prevAmount + amount;
      return prev;
    }, [] as IConsumptionTrendItem[]);

  type EventTypeMap = { [key in EventType]?: number };

  const eventTypeSign: EventTypeMap = {
    Fund: 1,
    Cancel: -1,
    Withdraw: -1,
  };

  interface IHoldingTrendItem {
    displayTimestamp: string;
    [eventType: string]: number | string;
  }

  const holdingsTrend = (
    dayFilter: number,
    display: (timestamp: number) => string
  ) =>
    events.reduce((prev, x) => {
      const timestamp = blockTimestamps[x.blockHash];
      if (timestamp < dayFilter) return prev;
      const event = x.event as EventType;
      const sign = eventTypeSign[event];
      if (!sign) return prev;
      const displayTimestamp = display(timestamp);
      const displayAmount = tepuiEthereumClient!.toDisplayAmount(
        x.returnValues.amount,
        token
      );
      const amount = sign * Number(displayAmount);
      const prevDisplayTimestamp = prev.find(
        (x) => x.displayTimestamp === displayTimestamp
      );
      if (!prevDisplayTimestamp) {
        const item: IHoldingTrendItem = {
          displayTimestamp,
          [event]: amount,
        };
        prev.push(item);
        return prev;
      }
      const prevAmount = (prevDisplayTimestamp[event] as number) ?? 0;
      prevDisplayTimestamp[event] = prevAmount + amount;
      return prev;
    }, [] as IHoldingTrendItem[]);

  const eventsLastMonth = eventCount(lastMonth);
  const consumptionLastMonth = totalConsumption(lastMonth);
  const subscriptionTrendLastMonth = subscriptionTrend(lastMonth, displayDate);
  const consumptionTrendLastMonth = consumptionTrend(lastMonth, displayDate);
  const holdingsTrendLastYear = holdingsTrend(lastYear, displayMonthAndYear);
  const subscriptionTrendLastYear = subscriptionTrend(
    lastYear,
    displayMonthAndYear
  );
  const consumptionTrendLastYear = consumptionTrend(
    lastYear,
    displayMonthAndYear
  );
  return (
    <Container>
      <Grid container alignItems="center">
        <Grid item>
          {planState?.status === "ready" ? (
            <PageTitle title={plan!.name} />
          ) : (
            <Skeleton width={250} height={40} />
          )}
        </Grid>
        <Grid item>
          <PlanActions planState={planState} />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="space-evenly">
        {contracts && contracts.length > 1 && (
          <DashboardContainer width={200} header="Totals">
            <GridAmount
              header="Consumed"
              tokenAmount={contractSum(contracts, (x) => x.consumed)}
              tokenSymbol={plan!.token}
            />
            <GridAmount
              header="Balance"
              tokenAmount={contractSum(contracts, (x) => x.balance)}
              tokenSymbol={plan!.token}
            />
          </DashboardContainer>
        )}
        {contracts &&
          contracts.map((x) => (
            <DashboardContainer key={x.address} width={200}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <AddressChip address={x.address!} actionable />
                </Grid>
                <Grid item>
                  <Tooltip title="Save">
                    <IconButton onClick={() => planActions.savePlanContract(x)}>
                      <PlaylistAddCheckIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Withdraw">
                    <IconButton onClick={() => planActions.withdrawPlan(x)}>
                      <AccountBalanceIcon color="primary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <GridAmount
                header="Consumed"
                tokenAmount={x.consumed}
                tokenAddress={x.token}
              />
              <GridAmount
                header="Balance"
                tokenAmount={x.balance}
                tokenAddress={x.token}
              />
            </DashboardContainer>
          ))}
        {eventsLastMonth.length > 0 && (
          <DashboardContainer header="Events (30 Days)" width={400}>
            <PieChart data={eventsLastMonth} />
          </DashboardContainer>
        )}
        {consumptionLastMonth.length > 0 && (
          <DashboardContainer header="Consumption (30 Days)" width={400}>
            <PieChart data={consumptionLastMonth} />
          </DashboardContainer>
        )}
        {subscriptionTrendLastMonth.length > 0 && (
          <DashboardContainer header="Subscriptions (30 days)" width={500}>
            <LineChart
              data={[{ id: "Count", data: subscriptionTrendLastMonth }]}
              legend={{ x: "Day", y: "Subscriptions" }}
            />
          </DashboardContainer>
        )}
        {consumptionTrendLastMonth.length > 0 && (
          <DashboardContainer header="Consumption (30 days)" width={550}>
            {plan && (
              <BarChart
                data={consumptionTrendLastMonth}
                keys={plan.features.map((x) => x.name)}
                indexBy="displayTimestamp"
                legend={{ x: "Day", y: token.name }}
              />
            )}
          </DashboardContainer>
        )}
        {holdingsTrendLastYear.length > 1 && (
          <DashboardContainer
            header="Contract Holdings (Last Year)"
            width={1200}
          >
            <BarChart
              data={holdingsTrendLastYear}
              keys={Object.keys(eventTypeSign)}
              indexBy="displayTimestamp"
              legend={{ x: "Month", y: "Holdings" }}
            />
          </DashboardContainer>
        )}
        {subscriptionTrendLastYear.length > 1 && (
          <DashboardContainer header="Subscriptions (Last Year)" width={1200}>
            <LineChart
              data={[
                {
                  id: "Count",
                  data: subscriptionTrendLastYear,
                },
              ]}
              legend={{ x: "Month", y: "Subscriptions" }}
            />
          </DashboardContainer>
        )}
        {consumptionTrendLastYear.length > 1 && (
          <DashboardContainer header="Consumption (Last Year)" width={1200}>
            {plan && (
              <BarChart
                data={consumptionTrendLastYear}
                keys={plan.features.map((x) => x.name)}
                indexBy="displayTimestamp"
                legend={{ x: "Month", y: token.name }}
              />
            )}
          </DashboardContainer>
        )}
        <DashboardContainer
          header="Subscriber Actions"
          width={1200}
          height={650}
        >
          <SubscriptionTable planState={planState} maxHeight={600} />
        </DashboardContainer>
        <DashboardContainer
          header="Consumption Detail"
          width={1200}
          height={650}
        >
          <ConsumptionTable planState={planState} maxHeight={600} />
        </DashboardContainer>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
  ownedPlans: state.ownedPlans,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanPage);
