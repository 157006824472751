import React, { ReactNode } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FiatAmount from "./FiatAmount";
import TokenAmount from "./TokenAmount";

interface IProps {
  header: ReactNode;
  tokenAmount: string;
  tokenSymbol?: string;
  tokenAddress?: string;
}

const GridAmount = ({
  header,
  tokenAmount,
  tokenSymbol,
  tokenAddress,
}: IProps) => (
  <Grid container direction="column" alignItems="center">
    <Grid item>
      <Typography variant="caption">{header}</Typography>
    </Grid>
    <Grid item>
      <TokenAmount
        variant="h6"
        style={{ color: "#f07365" }}
        tokenSymbol={tokenSymbol}
        tokenAmount={tokenAmount}
        tokenAddress={tokenAddress}
      />
    </Grid>
    <Grid item>
      <FiatAmount
        variant="caption"
        style={{ color: "#7397d1" }}
        tokenSymbol={tokenSymbol}
        tokenAmount={tokenAmount}
        tokenAddress={tokenAddress}
      />
    </Grid>
  </Grid>
);

export default GridAmount;
