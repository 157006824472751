import { Avatar } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import Collapse from "@material-ui/core/Collapse";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShareIcon from "@material-ui/icons/Share";
import StarIcon from "@material-ui/icons/Star";
import Skeleton from "@material-ui/lab/Skeleton";
import clsx from "clsx";
import Markdown from "markdown-to-jsx";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as clipboardActions from "../../redux/actions/clipboardActions";
import {
  IContractState,
  IPlanState,
  ProfileStateMap,
  Status,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { reduceStatus } from "../../utils/stateHelpers";
import AddressChip from "../common/AddressChip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 250,
    },
    media: {
      height: 140,
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  })
);

const MarkdownSkeleton = () => (
  <Box
    width={180}
    height={100}
    display="flex"
    flexDirection="column"
    justifyContent="center"
  >
    <Skeleton width="100%" />
    <Skeleton width="80%" />
    <Skeleton width="90%" />
    <Skeleton width="25%" />
    <Skeleton width="80%" />
  </Box>
);

interface IProps {
  planState?: IPlanState;
  contractState?: IContractState;
  signUp?: (target: {
    planState: IPlanState;
    contractState: IContractState;
  }) => void;
  profileStateMap: ProfileStateMap | null;
  clipboardActions: typeof clipboardActions;
}

const PlanCardActions = ({
  planState,
  contractState,
  signUp,
  clipboardActions,
}: IProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const handleExpandClick = () => setExpanded(!expanded);
  const status = reduceStatus(planState, contractState);
  const color =
    status === "ready" ? undefined : status === "failed" ? "error" : "disabled";
  const { plan } = planState ?? {};
  const { contract } = contractState ?? {};
  const { address } = contract ?? {};
  const url = address && `${window.location.origin}/marketplace/${address}`;
  return (
    <>
      <CardActions disableSpacing>
        <IconButton disabled={status !== "ready"}>
          <StarIcon color={color ?? "primary"} />
        </IconButton>
        <IconButton
          disabled={status !== "ready"}
          onClick={() => clipboardActions.copyToClipboard(url!)}
        >
          <ShareIcon color={color ?? "secondary"} />
        </IconButton>
        <Button
          size="small"
          color="primary"
          disabled={!signUp || status !== "ready"}
          onClick={() =>
            planState && contractState && signUp!({ planState, contractState })
          }
        >
          Sign Up
        </Button>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {plan ? (
            plan.description && <Markdown>{plan.description}</Markdown>
          ) : (
            <MarkdownSkeleton />
          )}
        </CardContent>
      </Collapse>
    </>
  );
};

type State = { status: Status };

const getText = (state?: State, text?: string): string => {
  const status = state?.status ?? "loading";
  switch (status) {
    case "busy":
    case "ready":
      return text ?? "<Unknown>";
    case "loading":
      return "Loading...";
    case "failed":
      return "<Failed>";
  }
};

const PlanCard = (props: IProps) => {
  const { planState, contractState, profileStateMap } = props;
  const classes = useStyles();
  const { plan } = planState ?? {};
  const { ownerId } = plan ?? {};
  const profileState = ownerId ? profileStateMap?.[ownerId] : undefined;
  const status = reduceStatus(planState, contractState, profileState);
  const { contract } = contractState ?? {};
  const title = getText(planState, plan?.name);
  const { profile } = profileState ?? {};
  const subheader = getText(profileState, profile?.name);

  return (
    <Grid item>
      <Card className={classes.root}>
        <CardHeader
          avatar={<Avatar src={profile?.picture} />}
          title={title}
          subheader={subheader}
        />
        <CardActionArea>
          {plan ? (
            plan.imageUrl && (
              <CardMedia className={classes.media} image={plan.imageUrl} />
            )
          ) : (
            <Skeleton variant="rect" width={250} height={140} />
          )}
          <CardContent>
            <AddressChip address={contract?.address} actionable></AddressChip>
          </CardContent>
        </CardActionArea>
        <PlanCardActions {...props} />
        {contractState && ["loading", "busy"].includes(status) && (
          <LinearProgress />
        )}
      </Card>
    </Grid>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  profileStateMap: state.profileStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clipboardActions: bindActionCreators(clipboardActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanCard);
