import { IPlan } from "@tepui/core-sdk";
import { IEthBalanceEvent, IEthPlan, IEthSubscription } from "@tepui/eth-sdk";
import { retrievePlanMap } from "../../utils/contractHelpers";
import * as tokenUtils from "../../utils/token";
import { PlanStateMap, SubscribedMap } from "../reducers/TepuiState";
import { SubscriptionActionType, TepuiAction, TepuiThunk } from "./actionTypes";
import { dispatchContracts } from "./planActions";

interface SubscriptionAction extends TepuiAction {
  type: SubscriptionActionType;
  subscribedMap?: SubscribedMap;
  subscription?: IEthSubscription;
  subscriptions?: IEthSubscription[];
  contract?: IEthPlan;
  planStateMap?: PlanStateMap;
  plan?: IPlan;
  events?: IEthBalanceEvent[];
  address?: string;
  subscriber?: string;
}

const loadSubscriptionSubmit = (
  address: string,
  subscriber: string
): SubscriptionAction => ({
  type: "LOAD_SUBSCRIPTION_SUBMIT",
  address,
  subscriber,
});

const loadSubscriptionSuccess = (
  subscription: IEthSubscription
): SubscriptionAction => ({
  type: "LOAD_SUBSCRIPTION_SUCCESS",
  subscription,
});

const loadSubscriptionError = (
  address: string,
  subscriber: string,
  message: string
): SubscriptionAction => ({
  type: "LOAD_SUBSCRIPTION_ERROR",
  address,
  subscriber,
  message,
});

const loadSubscribedSubscriptionsSuccess = (
  subscribedMap: SubscribedMap,
  planStateMap: PlanStateMap,
  subscriptions: IEthSubscription[]
): SubscriptionAction => ({
  type: "LOAD_SUBSCRIPTIONS_SUCCESS",
  subscribedMap,
  planStateMap,
  subscriptions,
});

const subscriptionSubmit = (
  subscription: IEthSubscription
): SubscriptionAction => ({
  type: "SUBSCRIPTION_SUBMIT",
  subscription,
});

const subscribeSuccess = (
  subscription: IEthSubscription,
  contract: IEthPlan,
  plan: IPlan,
  events: IEthBalanceEvent[],
  message: string
): SubscriptionAction => ({
  type: "SUBSCRIBE_SUCCESS",
  subscription,
  contract,
  plan,
  events,
  message,
});

const subscribeError = (
  subscription: IEthSubscription,
  message: string
): SubscriptionAction => ({
  type: "SUBSCRIBE_ERROR",
  subscription,
  message,
});

const consumeSubscriptionSuccess = (
  subscription: IEthSubscription,
  events: IEthBalanceEvent[],
  message?: string
): SubscriptionAction => ({
  type: "CONSUME_SUBSCRIPTION_SUCCESS",
  subscription,
  events,
  message,
});

const consumeSubscriptionError = (
  subscription: IEthSubscription,
  message: string
): SubscriptionAction => ({
  type: "CONSUME_SUBSCRIPTION_ERROR",
  subscription,
  message,
});

const cancelSubscriptionSuccess = (
  subscription: IEthSubscription,
  events: IEthBalanceEvent[],
  message: string
): SubscriptionAction => ({
  type: "CANCEL_SUBSCRIPTION_SUCCESS",
  subscription,
  events,
  message,
});

const cancelSubscriptionError = (
  subscription: IEthSubscription,
  message: string
): SubscriptionAction => ({
  type: "CANCEL_SUBSCRIPTION_ERROR",
  subscription,
  message,
});

type PlanMap = { [address: string]: string[] };

const mapReduce = async <T, U>(
  f: (x: T) => Promise<U | undefined>,
  values: T[]
) => {
  return await values.reduce(async (prevPromise, x) => {
    const result = await f(x);
    const prev = await prevPromise;
    if (result) prev.push(result);
    return prev;
  }, Promise.resolve([] as U[]));
};

const loadSubscription = (address: string, subscriber: string): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiEthereumClient } = state.ethereum!;
    dispatch(loadSubscriptionSubmit(address, subscriber));
    let subscription: IEthSubscription | undefined;
    try {
      subscription = await tepuiEthereumClient!.retrieveSubscription(
        address,
        subscriber
      );
    } catch (error) {
      const message = `Loading subscription ${subscriber} in contract ${address} failed: ${error.message}`;
      dispatch(loadSubscriptionError(address, subscriber, message));
      return;
    }
    if (subscription === undefined) {
      const message = `Subscription ${subscriber} in contract ${address} not found`;
      dispatch(loadSubscriptionError(address, subscriber, message));
      return;
    }
    dispatch(loadSubscriptionSuccess(subscription));
  };
};

const loadSubscribedSubscriptions = (): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry, addresses } = state.registry!;
    const { tepuiEthereumClient } = state.ethereum!;
    const initialPlanMap: PlanMap = {};
    const addressPlanMap = await addresses!.reduce(async (prevPromise, x) => {
      const planIds = await tepuiRegistry!.retrieveSubscribedPlans(x);
      const prev = await prevPromise;
      return { ...prev, [x]: planIds };
    }, Promise.resolve(initialPlanMap));
    const subscribedPlanIds = Object.values(addressPlanMap).flat();
    const uniquePlanIds = [...new Set(subscribedPlanIds)];
    const plans = await mapReduce(
      (x) => tepuiRegistry.retrievePlan(x),
      uniquePlanIds
    );
    const planStateMap = await retrievePlanMap(tepuiEthereumClient!, plans);
    const subscriptions = await Object.entries(addressPlanMap).reduce(
      async (prevPromise, x) => {
        const [subscriber, planIds] = x;
        const addresses = planIds.reduce((prev, x) => {
          const { addresses } = planStateMap[x] ?? {};
          if (!addresses) return prev;
          return prev.concat(addresses);
        }, [] as string[]);
        const subscriptions = await mapReduce(
          (x) => tepuiEthereumClient!.retrieveSubscription(x, subscriber),
          addresses
        );
        const prev = await prevPromise;
        return prev.concat(subscriptions);
      },
      Promise.resolve([] as IEthSubscription[])
    );
    const initialSubscribedMap = addresses!.reduce(
      (prev, x) => ({ ...prev, [x]: [] }),
      {} as SubscribedMap
    );
    const subscribedMap = subscriptions.reduce((prev, x) => {
      const { address, subscriber } = x;
      const addresses = prev[x.subscriber] ?? [];
      addresses.push(address);
      return { ...prev, [subscriber]: addresses };
    }, initialSubscribedMap);
    dispatch(
      loadSubscribedSubscriptionsSuccess(
        subscribedMap,
        planStateMap,
        subscriptions
      )
    );
    await dispatchContracts(state, dispatch, planStateMap);
  };
};

const consumeSubscription = (
  subscription: IEthSubscription,
  feature: string,
  quantity: string,
  bucket?: string
): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { planStateMap, contractStateMap } = state!;
    const { tepuiEthereumClient, tokenMap } = state.ethereum!;
    const { address, subscriber } = subscription;
    const contract = contractStateMap![address].contract;
    const plan = planStateMap![contract.id].plan;
    dispatch(subscriptionSubmit(subscription));
    let events: IEthBalanceEvent[];
    try {
      events = await tepuiEthereumClient!.consumePlan(
        address,
        feature,
        quantity,
        subscriber,
        bucket
      );
    } catch (error) {
      const message = `Consumption of ${plan.name} failed: ${error.message}`;
      dispatch(consumeSubscriptionError(subscription, message));
      return;
    }
    const token = tokenMap![contract.token];
    const amount = tepuiEthereumClient!.sumAmounts(
      events,
      address,
      subscriber,
      "Consume"
    );
    const displayAmount = tokenUtils.displayToken(
      amount,
      token,
      tepuiEthereumClient!
    );
    const message = `Subscription ${plan.name} consumed ${displayAmount}`;
    dispatch(consumeSubscriptionSuccess(subscription, events, message));
  };
};

const subscribe = (
  subscription: IEthSubscription,
  fundAmount: string
): TepuiThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tepuiRegistry } = state.registry!;
    const { tepuiEthereumClient, tokenMap } = state.ethereum!;
    const { planStateMap, contractStateMap } = state;
    const { address, subscriber } = subscription;
    const contract = contractStateMap![address].contract;
    const plan = planStateMap![contract.id].plan;
    const token = tokenMap![contract.token];
    const tokenDisplayAmount = tokenUtils.displayToken(
      fundAmount,
      token,
      tepuiEthereumClient!
    );
    dispatch(subscriptionSubmit(subscription));
    const callback = () =>
      tepuiRegistry!.createSubscription(
        contract.id,
        tepuiEthereumClient!.defaultAccount!
      );
    let events: IEthBalanceEvent[];
    try {
      if (tepuiEthereumClient!.defaultAccount! === subscription.subscriber)
        await tepuiEthereumClient!.approveToken(contract, fundAmount);
      events = await tepuiEthereumClient!.subscribe(
        subscription,
        contract,
        fundAmount,
        callback
      );
    } catch (error) {
      const message = `Subscription to ${plan.name} failed: ${error.message}`;
      dispatch(subscribeError(subscription, message));
      return;
    }
    const amount = tepuiEthereumClient!.sumAmounts(
      events,
      address,
      subscriber,
      "Consume"
    );
    const consumed = Number(amount) > 0;
    const consumeMessage = () => {
      if (!consumed) return "";
      const displayAmount = tokenUtils.displayToken(
        amount,
        token,
        tepuiEthereumClient!
      );
      return ` (consumed ${displayAmount})`;
    };
    const message = `Subscribed to ${
      plan.name
    }${consumeMessage()} and funded with ${tokenDisplayAmount}`;
    dispatch(subscribeSuccess(subscription, contract, plan, events, message));
  };
};

const cancelSubscription = (
  subscription: IEthSubscription
): TepuiThunk<Promise<boolean>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { planStateMap, contractStateMap } = state!;
    const { tepuiEthereumClient } = state.ethereum!;
    const { address, subscriber } = subscription;
    const contract = contractStateMap![address].contract;
    const plan = planStateMap![contract.id].plan;
    dispatch(subscriptionSubmit(subscription));
    let events: IEthBalanceEvent[];
    try {
      events = await tepuiEthereumClient!.cancelSubscription(
        address,
        subscriber
      );
    } catch (error) {
      const message = `Cancellation of ${plan.name} failed: ${error.message}`;
      dispatch(cancelSubscriptionError(subscription, message));
      return false;
    }
    const message = `Subscription ${plan.name} successfully cancelled`;
    dispatch(cancelSubscriptionSuccess(subscription, events, message));
    return true;
  };
};

export type { SubscriptionAction };
export { loadSubscription, loadSubscribedSubscriptions };
export { consumeSubscription, subscribe, cancelSubscription };
