import Container from "@material-ui/core/Container";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import * as planActions from "../../redux/actions/planActions";
import * as routerActions from "../../redux/actions/routerActions";
import {
  ContractStateMap,
  IEthereum,
  PlanStateMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { reduceStatus } from "../../utils/stateHelpers";
import FundModal from "../common/FundModal";
import PageNotFound from "../PageNotFound";

interface IParams {
  contractAddress: string;
}

interface IProps {
  ethereum: IEthereum | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  planActions: typeof planActions;
  routerActions: typeof routerActions;
}

const FundPage = ({
  ethereum,
  planStateMap,
  contractStateMap,
  planActions,
  routerActions,
}: IProps) => {
  const { contractAddress } = useParams<IParams>();
  const contractState = contractStateMap?.[contractAddress];
  const { contract } = contractState ?? {};
  const planState = contract ? planStateMap?.[contract.id] : undefined;
  const status = reduceStatus(contractState, planState);

  useEffect(() => {
    if (planState) return;
    if (ethereum?.status !== "ready") return;
    const init = async () => {
      planActions.loadContract(contractAddress);
    };
    init();
  }, [planState, contractAddress, ethereum, planActions]);

  if (ethereum?.status !== "ready")
    return <h2>Connect to Ethereum to retrieve data</h2>;
  if (status === "failed") return <PageNotFound />;

  return (
    <Container>
      <FundModal
        show={true}
        onHide={() => {
          routerActions.pushRoute("/marketplace");
        }}
        planState={planState}
        contractState={contractState}
        address={contractAddress}
      />
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  planActions: bindActionCreators(planActions, dispatch),
  routerActions: bindActionCreators(routerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FundPage);
