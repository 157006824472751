type ObjectMap<T> = { [key: string]: T };
type Partial<T> = { [P in keyof T]?: T[P] };

const setMap = <T>(map: ObjectMap<T>, key: string, value: T) => {
  return { ...map, [key]: value };
};

const updateMap = <T>(
  map: ObjectMap<T>,
  key: string,
  prop: Partial<T>
): ObjectMap<T> => {
  const oldValue = map[key];
  const newValue = { ...oldValue, ...prop };
  return { ...map, [key]: newValue };
};

const deleteMap = <T>(map: ObjectMap<T>, key: string): ObjectMap<T> => {
  const { [key]: _, ...result } = map;
  return result;
};

export { setMap, updateMap, deleteMap };
