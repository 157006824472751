import { CognitoUserSession } from "amazon-cognito-identity-js";
import { Auth, Hub } from "aws-amplify";
import Cookies from "js-cookie";
import { SessionActionType, TepuiAction, TepuiThunk } from "./actionTypes";
import * as registryActions from "./registryActions";
import * as routerActions from "./routerActions";

const pathNameCookie = "pathname";

const configureAuth: TepuiThunk = (dispatch) => {
  Auth.configure({
    identityPoolId: "us-east-1:449b4089-406f-4e9f-916b-1c8784896582",
    region: "us-east-1",
    userPoolId: "us-east-1_MIDVlLJph",
    userPoolWebClientId: "2h9brd6re7dfil6k4p0sdlcbhj",
    mandatorySignIn: true,
    oauth: {
      domain: "auth.tepui.io",
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
  });

  Hub.listen("auth", async ({ payload }) => {
    if (payload.event !== "signIn") return;
    const session = await Auth.currentSession();
    const idToken = session.getIdToken();
    const { given_name, name, email } = idToken.payload;
    const message = `Welcome ${given_name || name || email}`;
    dispatch(sessionSignInSuccess(session, message));
    dispatch(registryActions.loadRegistry());
    const pathname = Cookies.get(pathNameCookie);
    Cookies.remove(pathNameCookie);
    if (!pathname) return;
    dispatch(routerActions.pushRoute(pathname));
  });
};

interface SessionAction extends TepuiAction {
  type: SessionActionType;
  session?: CognitoUserSession;
}

const sessionLoadSuccess = (session: CognitoUserSession): SessionAction => ({
  type: "SESSION_LOAD_SUCCESS",
  session,
});

const sessionSignInSuccess = (
  session: CognitoUserSession,
  message: string
): SessionAction => ({
  type: "SESSION_SIGNIN_SUCCESS",
  session,
  message,
});

const sessionSignOutSuccess = (message: string): SessionAction => ({
  type: "SESSION_SIGNOUT_SUCCESS",
  message,
});

const loadSession = (): TepuiThunk => {
  return async (dispatch) => {
    try {
      const session = await Auth.currentSession();
      dispatch(sessionLoadSuccess(session));
    } catch {
      // ignore since authentication may be in progress
    }
    dispatch(registryActions.loadRegistry());
  };
};

const signIn = (): TepuiThunk => {
  return async () => {
    const { pathname } = window.location;
    if (pathname !== "/")
      Cookies.set(pathNameCookie, pathname, { expires: 1 / 24 });
    Auth.federatedSignIn();
  };
};

const signOut = (): TepuiThunk => {
  return async (dispatch) => {
    await Auth.signOut();
    dispatch(sessionSignOutSuccess(`You have signed out`));
  };
};

export type { SessionAction };
export { configureAuth, loadSession, signIn, signOut };
