import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import * as planActions from "../../redux/actions/planActions";
import * as subscriptionActions from "../../redux/actions/subscriptionActions";
import {
  ContractStateMap,
  IEthereum,
  IRegistry,
  PlanStateMap,
  SubscriptionStateMap,
  TepuiState,
} from "../../redux/reducers/TepuiState";
import { reduceStatus } from "../../utils/stateHelpers";
import AddressChip from "../common/AddressChip";
import GridAmount from "../common/GridAmount";
import PageTitle from "../common/PageTitle";
import SubscriptionActions from "../common/SubscriptionActions";
import SummaryItemSkeleton from "../common/SummaryItemSkeleton";
import TableSkeleton from "../common/TableSkeleton";
import PageNotFound from "../PageNotFound";
import ReceiptTable from "./ReceiptTable";
import SubscriptionDates from "./SubscriptionDates";
import SubscriptionPayment from "./SubscriptionPayment";

interface IParams {
  subscriberAddress: string;
  contractAddress: string;
}

interface IProps {
  registry: IRegistry | null;
  planStateMap: PlanStateMap | null;
  contractStateMap: ContractStateMap | null;
  subscriptionStateMap: SubscriptionStateMap | null;
  ethereum: IEthereum | null;
  planActions: typeof planActions;
  subscriptionActions: typeof subscriptionActions;
}

const SubscriptionPage = ({
  registry,
  planStateMap,
  contractStateMap,
  subscriptionStateMap,
  ethereum,
  planActions,
  subscriptionActions,
}: IProps) => {
  const { subscriberAddress, contractAddress } = useParams<IParams>();
  const { addresses } = registry ?? {};
  const { address } = ethereum ?? {};
  const contractState = contractStateMap?.[contractAddress];
  const { contract } = contractState ?? {};
  const planState = contract ? planStateMap?.[contract.id] : undefined;
  const { plan } = planState ?? {};
  const subscriptionState =
    subscriptionStateMap?.[contractAddress]?.[subscriberAddress];
  const { subscription } = subscriptionState ?? {};
  const status = reduceStatus(subscriptionState, contractState, planState);

  useEffect(() => {
    if (plan) return;
    if (ethereum?.status !== "ready") return;
    const init = async () => {
      planActions.loadContract(contractAddress);
    };
    init();
  }, [plan, contractAddress, ethereum, planActions]);

  useEffect(() => {
    if (subscription) return;
    if (ethereum?.status !== "ready") return;
    const init = async () => {
      subscriptionActions.loadSubscription(contractAddress, subscriberAddress);
    };
    init();
  }, [
    subscription,
    contractAddress,
    subscriberAddress,
    ethereum,
    subscriptionActions,
  ]);

  if (!addresses) return null;
  if (!addresses.includes(subscriberAddress)) return <PageNotFound />;
  if (ethereum?.status !== "ready")
    return <h2>Connect to Ethereum to retrieve data</h2>;
  if (status === "failed") return <PageNotFound />;

  const allowCancel = false;

  return (
    <Container>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          {planState?.status === "ready" ? (
            <PageTitle title={plan!.name} />
          ) : (
            <Skeleton width={250} height={40} />
          )}
        </Grid>
        <Grid item>
          <AddressChip address={contractAddress} actionable />
        </Grid>
        {address === subscriberAddress && (
          <Grid item>
            <SubscriptionActions
              {...{
                subscriptionState,
                contractState,
                planState,
                allowCancel,
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <Paper style={{ padding: 5 }}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-evenly"
            >
              <Grid item>
                {subscription && contract ? (
                  <GridAmount
                    header="Balance"
                    tokenAmount={subscription.balance!}
                    tokenAddress={contract.token}
                  />
                ) : (
                  <SummaryItemSkeleton />
                )}
              </Grid>
              <Grid item>
                {subscription && plan ? (
                  <SubscriptionPayment {...{ subscription, plan }} />
                ) : (
                  <SummaryItemSkeleton />
                )}
              </Grid>
              <Grid item>
                {subscription && plan ? (
                  <SubscriptionDates {...{ subscription, plan }} />
                ) : (
                  <SummaryItemSkeleton />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item>
                <Typography variant="h5">Receipt</Typography>
              </Grid>
              <Grid item style={{ width: "inherit" }}>
                {contract && plan ? (
                  <ReceiptTable
                    subscriber={subscriberAddress}
                    contract={contract}
                    plan={plan}
                    maxHeight={600}
                  />
                ) : (
                  <TableSkeleton />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state: TepuiState) => ({
  ethereum: state.ethereum,
  registry: state.registry,
  planStateMap: state.planStateMap,
  contractStateMap: state.contractStateMap,
  subscriptionStateMap: state.subscriptionStateMap,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  planActions: bindActionCreators(planActions, dispatch),
  subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
