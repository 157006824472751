import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import DeleteIcon from "@material-ui/icons/Delete";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import * as subscriptionActions from "../../redux/actions/subscriptionActions";
import {
  IContractState,
  IPlanState,
  ISubscriptionState,
} from "../../redux/reducers/TepuiState";
import { reduceStatus } from "../../utils/stateHelpers";
import ConsumeModal from "./ConsumeModal";
import FundModal from "./FundModal";

interface IProps {
  subscriptionState?: ISubscriptionState;
  contractState?: IContractState;
  planState?: IPlanState;
  allowCancel: boolean;
  subscriptionActions: typeof subscriptionActions;
}

const SubscriptionActions = ({
  subscriptionState,
  contractState,
  planState,
  allowCancel,
  subscriptionActions,
}: IProps) => {
  const [fundModalShow, setFundModalShow] = useState(false);
  const [consumeModalShow, setConsumeModalShow] = useState(false);
  const status = reduceStatus(subscriptionState, contractState, planState);
  const { subscription } = subscriptionState ?? {};
  const { contract } = contractState ?? {};
  const { plan } = planState ?? {};
  const color =
    status === "ready" ? undefined : status === "failed" ? "error" : "disabled";
  return (
    <>
      <Tooltip title="Consume">
        <span>
          <IconButton
            disabled={status !== "ready"}
            onClick={() => setConsumeModalShow(true)}
          >
            <PlayCircleFilledWhiteIcon color={color ?? "primary"} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Fund">
        <span>
          <IconButton
            disabled={status !== "ready"}
            onClick={() => setFundModalShow(true)}
          >
            <AccountBalanceWalletIcon color={color ?? "secondary"} />
          </IconButton>
        </span>
      </Tooltip>
      {allowCancel && (
        <Tooltip title="Cancel">
          <span>
            <IconButton
              disabled={status !== "ready"}
              onClick={() => {
                subscriptionActions.cancelSubscription(subscription!);
              }}
            >
              <DeleteIcon color={color ?? "primary"} />
            </IconButton>
          </span>
        </Tooltip>
      )}
      <FundModal
        show={fundModalShow}
        onHide={() => setFundModalShow(false)}
        subscriptionState={subscriptionState}
        planState={planState}
        contractState={contractState}
      />
      <ConsumeModal
        show={consumeModalShow}
        onHide={() => setConsumeModalShow(false)}
        subscription={subscription}
        contract={contract}
        plan={plan}
        consumeSubscription={subscriptionActions.consumeSubscription}
      />
    </>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  subscriptionActions: bindActionCreators(subscriptionActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionActions);
