import React, { MouseEventHandler } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import * as clipboardActions from "../../redux/actions/clipboardActions";

const displayAddress = (addr?: string) => {
  if (!addr) return "0x0000...0000";
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
};

interface IProps {
  address?: string;
  actionable?: boolean;
  clipboardActions: typeof clipboardActions;
}

const AddressChip = ({ address, actionable, clipboardActions }: IProps) => {
  const handleClick: MouseEventHandler = (event) => {
    if (address) clipboardActions.copyToClipboard(address);
    event.stopPropagation();
  };
  const variant = actionable ? "default" : "outlined";
  const color = actionable ? "primary" : "default";
  return (
    <Chip
      icon={<FileCopyIcon />}
      label={displayAddress(address)}
      clickable={Boolean(address)}
      onClick={handleClick}
      size="small"
      variant={variant}
      color={color}
    />
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clipboardActions: bindActionCreators(clipboardActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddressChip);
