import ButtonBase from "@material-ui/core/ButtonBase";
import { emphasize, fade, makeStyles } from "@material-ui/core/styles";
import { capitalize } from "@material-ui/core/utils";
import clsx from "clsx";
import * as React from "react";

const useStyles = makeStyles((theme) => {
  const backgroundColor =
    theme.palette.type === "light"
      ? theme.palette.grey[300]
      : theme.palette.grey[700];

  return {
    /* Styles applied to the root element. */
    root: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.pxToRem(13),
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      height: 32,
      color: theme.palette.getContrastText(backgroundColor),
      backgroundColor,
      borderRadius: 32 / 2,
      whiteSpace: "nowrap",
      transition: theme.transitions.create(["background-color", "box-shadow"]),
      // label will inherit this from root, then `clickable` class overrides this for both
      cursor: "default",
      // We disable the focus ring for mouse, touch and keyboard users.
      outline: 0,
      textDecoration: "none",
      border: "none", // Remove `button` border
      padding: 0, // Remove `button` padding
      verticalAlign: "middle",
      boxSizing: "border-box",
      "&$disabled": {
        opacity: 0.5,
        pointerEvents: "none",
      },
      "& $avatar": {
        marginLeft: 5,
        marginRight: -6,
        width: 24,
        height: 24,
        color:
          theme.palette.type === "light"
            ? theme.palette.grey[700]
            : theme.palette.grey[300],
        fontSize: theme.typography.pxToRem(12),
      },
      "& $avatarColorPrimary": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.dark,
      },
      "& $avatarColorSecondary": {
        color: theme.palette.secondary.contrastText,
        backgroundColor: theme.palette.secondary.dark,
      },
      "& $avatarRight": {
        marginLeft: -6,
        marginRight: 5,
      },
      "& $avatarSmall": {
        marginLeft: 4,
        marginRight: -4,
        width: 18,
        height: 18,
        fontSize: theme.typography.pxToRem(10),
      },
      "& $avatarSmallRight": {
        marginLeft: -4,
        marginRight: 4,
      },
    },
    /* Styles applied to the root element if `size="small"`. */
    sizeSmall: {
      height: 24,
    },
    /* Styles applied to the root element if `color="primary"`. */
    colorPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    /* Styles applied to the root element if `color="secondary"`. */
    colorSecondary: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    /* Pseudo-class applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Styles applied to the root element if `onClick` is defined or `clickable={true}`. */
    clickable: {
      userSelect: "none",
      WebkitTapHighlightColor: "transparent",
      cursor: "pointer",
      "&:hover, &:focus": {
        backgroundColor: emphasize(backgroundColor, 0.08),
      },
      "&:active": {
        boxShadow: theme.shadows[1],
      },
    },
    /* Styles applied to the root element if `onClick` and `color="primary"` is defined or `clickable={true}`. */
    clickableColorPrimary: {
      "&:hover, &:focus": {
        backgroundColor: emphasize(theme.palette.primary.main, 0.08),
      },
    },
    /* Styles applied to the root element if `onClick` and `color="secondary"` is defined or `clickable={true}`. */
    clickableColorSecondary: {
      "&:hover, &:focus": {
        backgroundColor: emphasize(theme.palette.secondary.main, 0.08),
      },
    },
    /* Styles applied to the root element if `variant="outlined"`. */
    outlined: {
      backgroundColor: "transparent",
      border: `1px solid ${
        theme.palette.type === "light"
          ? "rgba(0, 0, 0, 0.23)"
          : "rgba(255, 255, 255, 0.23)"
      }`,
      "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
        backgroundColor: fade(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
      },
      "& $avatar": {
        marginLeft: 4,
      },
      "& $avatarSmall": {
        marginLeft: 2,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
    outlinedPrimary: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
        backgroundColor: fade(
          theme.palette.primary.main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
    outlinedSecondary: {
      color: theme.palette.secondary.main,
      border: `1px solid ${theme.palette.secondary.main}`,
      "$clickable&:hover, $clickable&:focus, $deletable&:focus": {
        backgroundColor: fade(
          theme.palette.secondary.main,
          theme.palette.action.hoverOpacity
        ),
      },
    },
    /* Styles applied to the `avatar` element. */
    avatar: {},
    /* Styles applied to the `avatar` element if `position="right"`. */
    avatarRight: {},
    /* Styles applied to the `avatar` element if `size="small"`. */
    avatarSmall: {},
    /* Styles applied to the `avatar` element if `size="small" && position="right"`. */
    avatarSmallRight: {},
    /* Styles applied to the `avatar` element if `color="primary"`. */
    avatarColorPrimary: {},
    /* Styles applied to the `avatar` element if `color="secondary"`. */
    avatarColorSecondary: {},
    /* Styles applied to the label `span` element. */
    label: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      paddingLeft: 12,
      paddingRight: 12,
      whiteSpace: "nowrap",
    },
    /* Styles applied to the label `span` element if `size="small"`. */
    labelSmall: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  };
});

interface IProps {
  avatarLeft?: React.ReactElement;
  avatarRight?: React.ReactElement;
  clickable?: boolean;
  color?: "primary" | "secondary" | "default";
  disabled?: boolean;
  label?: React.ReactNode;
  onClick?: React.MouseEventHandler<any>;
  size?: "small" | "medium";
  variant?: "default" | "outlined";
}

const ProviderChip = (props: IProps) => {
  const {
    avatarLeft: avatarLeftProp,
    avatarRight: avatarRightProp,
    clickable: clickableProp,
    color = "default",
    disabled = false,
    label,
    onClick,
    size = "medium",
    variant = "default",
    ...other
  } = props;

  const classes: { [index: string]: string } = useStyles();

  const clickable = clickableProp !== false && onClick ? true : clickableProp;
  const small = size === "small";

  const Component = clickable ? ButtonBase : "div";
  const moreProps = Component === ButtonBase ? { component: "div" } : {};

  const cloneAvatar = (
    avatarProp: React.ReactElement | undefined,
    position: "left" | "right"
  ) => {
    if (!avatarProp || !React.isValidElement(avatarProp)) return null;
    const avatarProps = avatarProp.props as { className: string };
    const right = position === "right";
    const custom = {
      [classes.avatarRight]: right,
      [classes.avatarSmall]: small,
      [classes.avatarSmallRight]: small && right,
      [classes[`avatarColor${capitalize(color)}`]]: color !== "default",
    };
    const className = clsx(classes.avatar, avatarProps.className, custom);
    const props = { className };
    return React.cloneElement(avatarProp, props);
  };

  const avatarLeft = cloneAvatar(avatarLeftProp, "left");
  const avatarRight = cloneAvatar(avatarRightProp, "right");

  return (
    <Component
      role={clickable ? "button" : undefined}
      className={clsx(classes.root, {
        [classes.disabled]: disabled,
        [classes.sizeSmall]: small,
        [classes[`color${capitalize(color)}`]]: color !== "default",
        [classes.clickable]: clickable,
        [classes[`clickableColor${capitalize(color)}`]]:
          clickable && color !== "default",
        [classes.outlined]: variant === "outlined",
        [classes.outlinedPrimary]:
          variant === "outlined" && color === "primary",
        [classes.outlinedSecondary]:
          variant === "outlined" && color === "secondary",
      })}
      aria-disabled={disabled ? true : undefined}
      tabIndex={clickable ? 0 : undefined}
      onClick={onClick}
      {...moreProps}
      {...other}
    >
      {avatarLeft}
      <span
        className={clsx(classes.label, {
          [classes.labelSmall]: small,
        })}
      >
        {label}
      </span>
      {avatarRight}
    </Component>
  );
};

export default ProviderChip;
