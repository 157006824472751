import copy from "copy-to-clipboard";
import { TepuiAction, ClipboardActionType, TepuiThunk } from "./actionTypes";

interface ClipboardAction extends TepuiAction {
  type: ClipboardActionType;
}

const wrap = (value: string, max: number) => {
  if (value.length <= max) return value;
  return `${value.substring(0, max)}...`;
};

interface ClipboardAction extends TepuiAction {
  type: ClipboardActionType;
}

const clipboardError = (): ClipboardAction => ({
  type: "CLIPBOARD_COPY_ERROR",
  message: "Error while copying to clipboard",
});

const clipboardSuccess = (message: string): ClipboardAction => ({
  type: "CLIPBOARD_COPY_SUCCESS",
  message,
});

const copyToClipboard = (text: string): TepuiThunk => {
  return async (dispatch) => {
    const result = copy(text);
    if (!result) {
      dispatch(clipboardError());
      return;
    }
    dispatch(
      clipboardSuccess(`${wrap(text, 10)} successfully copied to clipboard`)
    );
  };
};

export type { ClipboardAction };
export { copyToClipboard };
